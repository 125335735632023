import React from 'react';

export const PropertyThreeSixty = ({ details }) => {
  const { threeSixtyView } = details;
  return (
    <div>
      {threeSixtyView ? (
        <section>
          <iframe
            className='w-100'
            title='theSilk'
            src={threeSixtyView}
            style={{
              border: '0px #ffffff none',
              name: 'theSilkDistrict',
              scrolling: 'no',
              frameborder: '0',
              marginheight: '0px',
              marginwidth: '0px',
              height: '450px',
            }}
            allowFullScreen
          />
        </section>
      ) : null}
    </div>
  );
};
