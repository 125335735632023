import React from 'react';
import Navbar from '../../components/global-components/navbar';
import RegisterPremium from '../../components/auth-components/stripe/stripe';
import Footer from '../../components/global-components/footer';
import { useRedirect } from '../../hooks/useRedirect';
import { USER_TYPES } from '../../utils/userTypes';

const RegisterServiceWorker = () => {
  useRedirect();
  return (
    <div>
      <Navbar />
      <div
        className='signup-page-area pb-5 pt-lg-5'
        style={{ background: 'white', marginTop: 10 }}
      >
        <div className='container'>
          <div className='row justify-content-center '>
            <div className='col-xl-8 col-lg-8 col-md-10'>
              <div className='p-4'>
                <RegisterPremium userType={USER_TYPES.serviceProvider} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterServiceWorker;
