import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ReqModal } from './requestModal';

export const MblRequestItem = (props) => {
  const { request } = props;
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <>
      <section className='rounded-xl shadow-sm border p-4 mb-4 d-lg-none'>
        <Row className='mt-3'>
          <Col xs={6}>
            <div>
              <span className='text-yellow text-capitalize font-weight-bold'>
                Name
              </span>
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <span className='text-capitalize'>{request.name}</span>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={6}>
            <div>
              <span className='text-yellow text-capitalize font-weight-bold'>
                Email
              </span>
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <span className='text-capitalize'>{request.email}</span>
            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col xs={6}>
            <div>
              <span className='text-yellow text-capitalize font-weight-bold'>
                Message
              </span>
            </div>
          </Col>
          <Col xs={6}>
            <div className='d-flex align-items-center'>
              <span className='text-capitalize'>{request.message}</span>
            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col xs={6}>
            <div>
              <span className='text-yellow text-capitalize font-weight-bold'>
                Action
              </span>
            </div>
          </Col>
          <Col xs={6}>
            <div className='d-flex align-items-center'>
              <span className='text-capitalize'>
                <button
                  className='btn btn-primary'
                  onClick={() => setShowReqModal(true)}
                >
                  Reply
                </button>
              </span>
            </div>
          </Col>
        </Row>
        {showReqModal ? (
          <ReqModal
            showReqModal={showReqModal}
            setShowReqModal={setShowReqModal}
            propertyTitle={request.property.title}
            requestId={request._id}
            requestorName={request.name}
          />
        ) : null}
      </section>
    </>
  );
};
