import React from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Ads = () => {
  const { user } = useSelector(state => state);
  let data = sectiondata.ads;

  if (user?.isAuthenticated) {
    return null;
  }

  return (
    <div className='call-to-action-area '>
      <div className='call-to-action  d-flex flex-column text-center py-5'>
        <div className='py-5 pos-r'>
          <h2 className='title text-white mb-3'>Become a Real Estate Agent</h2>
          <p className='text-white font-weight-lighter mb-4'>
            We only work with the best companies around the globe
          </p>
          <div>
            <Link className='btn bg-white' to='/contact'>
              <h2 className='d-flex align-items-center h-100 text-primary h6 font-weight-normal mb-0'>
                {data.btntxt}
              </h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
