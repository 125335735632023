import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import '@reach/combobox/styles.css';

export const PlacesAutoComp = ({
  setLat,
  setLng,
  setPostCode,
  setAddress,
  setCity,
  placeholderValue,
  defaultValue,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue,
    requestOptions: {
      componentRestrictions: {
        country: 'uk',
      },
      types: ['(regions)'],
    },
  });
  const handleInput = e => {
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect = async address => {
    console.log(address);
    setValue(address, false);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      let zipCode = await getZipCode(results[0], false);
      const details = await getDetails({ placeId: results[0].place_id });
      setLat(lat);
      setLng(lng);
      setAddress(address);
      setPostCode(zipCode);
      let div = document.createElement('div');
      div.innerHTML = details.adr_address;
      let selected = div.getElementsByClassName('locality');
      console.log({ zipCode, details, address, results, selected });

      setCity(selected[0].innerHTML);
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  return (
    <Combobox onSelect={handleSelect} aria-labelledby='demo'>
      <ComboboxInput
        value={value}
        placeholder={placeholderValue}
        onChange={handleInput}
        disabled={!ready}
        className='w-100  p-2'
        style={{ height: '40px', border: '1px solid #ccc' }}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
