import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';
import notifications from '../../notifications';

export const ReplyModal = ({
  setShowReqModal,
  showReqModal,
  propertyTitle,
  requestId,
}) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const queryClient = useQueryClient();

  const responseToRequestHandler = data => {
    return axios
      .post(`/property/request/${requestId}`, data)
      .then(res => res.data)
      .catch(err => {
        throw err.response.data;
      });
  };
  const mutation = useMutation(responseToRequestHandler, {
    onSuccess: () => {
      reset();
      setShowReqModal(false);
      notifications.success('Success', 'Replied Successfully');
      queryClient.invalidateQueries('propertiesByCityCount');
      queryClient.invalidateQueries([`requests-${requestId}`, requestId]);
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  const onSubmit = data => {
    const newData = {
      response: data.response,
      propertyTitle,
    };
    mutation.mutate(newData);
  };

  return (
    <>
      <Modal
        isOpen={showReqModal}
        centered
        className='m-0 m-md-auto'
        contentClassName='border-0 d-flex flex-column'
        toggle={() => setShowReqModal(!showReqModal)}
      >
        <ModalHeader
          className='position-sticky fixed-top'
          toggle={() => setShowReqModal(!showReqModal)}
        >
          Response To Request
        </ModalHeader>
        <ModalBody className='flex-fill d-flex flex-column'>
          <form
            className='contact-form-wrap contact-form-bg my-auto'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='rld-single-input mt-3'>
              <textarea
                rows={5}
                type='text'
                name='response'
                placeholder='Reply To Request...'
                ref={register({ required: true })}
              />
              {errors.response && (
                <small className='font-weight-bold text-danger'>
                  This field is required
                </small>
              )}
            </div>
            <div className='btn-wrap'>
              <button
                type='submit'
                disabled={mutation.isLoading}
                className={`btn btn-secondary ${
                  mutation.isLoading === true ? 'button-disabled' : null
                }`}
              >
                {mutation.isLoading === true ? (
                  <div
                    class='spinner-border spinner-border-sm text-light mr-2'
                    role='status'
                  >
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : null}
                Send Response
              </button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button class='btn yellow-btn' onClick={() => setShowReqModal(false)}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
