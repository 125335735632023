import React from 'react';
import Navbar from '../../components/global-components/navbar';
import PageHeader from '../../components/global-components/page-header';
import { ResetPasswordd } from '../../components/auth-components/resetPass';
import Footer from '../../components/global-components/footer';
import sectiondata from '../../data/sections.json';
import { useRedirect } from '../../hooks/useRedirect';

const ResetPassword = () => {
    useRedirect();
    const pageHeader = sectiondata.pageHeaders;
    return <div>
        <Navbar />
        <PageHeader headertitle="Reset Password" imageUrl={pageHeader.resetpass} />
        <ResetPasswordd />
        <Footer />
    </div>
}

export default ResetPassword;

