import React from 'react';
import Toggle from 'react-toggle';

export const MapToggle = ({ setMap, map }) => {
  return (
    <div>
      <label className='d-flex align-items-center pt-2 pr-0 justify-content-end ml-auto'>
        <span
          style={{
            color: 'black',
          }}
          className='text-capitalize mr-3 text-black'
        >
          Map
        </span>
        <Toggle checked={map} onChange={() => setMap(!map)} />
      </label>
    </div>
  );
};
