import React, { useState } from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';
import { truncateString } from '../../../utils/truncateString';

export const PropertyDescription = ({ details }) => {
  const { description } = details;
  const [showList, setShowList] = useState(false);
  return (
    <div>
      <div className='my-2 '>
        {showList ? (
          <>
            <div
              style={{
                fontSize: 14,
              }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <div
              className='text-secondary cursor-pointer font-weight-bold  border-top pt-2 mt-2 text-center'
              onClick={() => setShowList(!showList)}
            >
              {showList ? (
                <div className='d-flex align-items-center justify-content-center'>
                  <SvgBox rotate='-90' size='10px'>
                    <ArrowIcon />
                  </SvgBox>
                  <span className='ml-2 text-underline'>
                    Hide full description
                  </span>
                </div>
              ) : (
                <div className='d-flex align-items-center justify-content-center'>
                  <SvgBox rotate='90' size='10px'>
                    <ArrowIcon />
                  </SvgBox>
                  <span className='ml-2 text-underline'>
                    See full description
                  </span>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <div
                style={{
                  fontSize: 14,
                }}
                dangerouslySetInnerHTML={{
                  __html: truncateString(description, 300),
                }}
              />

              {description.length > 300 && (
                <div
                  className='text-secondary cursor-pointer font-weight-bold  border-top pt-2 mt-2 text-center'
                  onClick={() => setShowList(!showList)}
                >
                  {showList ? (
                    <div className='d-flex align-items-center justify-content-center'>
                      <SvgBox rotate='-90' size='10px'>
                        <ArrowIcon />
                      </SvgBox>
                      <span className='ml-2 text-underline'>
                        Hide full description
                      </span>
                    </div>
                  ) : (
                    <div className='d-flex align-items-center justify-content-center'>
                      <SvgBox rotate='90' size='10px'>
                        <ArrowIcon />
                      </SvgBox>
                      <span className='ml-2 text-underline'>
                        See full description
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
