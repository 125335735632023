import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import notifications from '../notifications';
import BgImage from '../../images/bg-image-1.jpg';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrowRight.svg';

export const ForgotPasswordd = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post('/profile/reset/check', data)
      .then((res) => {
        setLoading(false);
        notifications.success('Success', 'Email Sent Successfully');
        history.push(`/forgot-password/resend/${res.data.id}`);
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later!');
      });
  };

  const inlineStyle = {
    backgroundImage: `url(${BgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backgroundBlendMode: 'overlay',
  };

  return (
    <>
      <div className='d-flex justify-content-center align-items-center mobile-vh-100'>
        <div className='row h-100 w-100 align-items-center bg-white'>
          <div className='col-12 col-lg-6 my-5 my-lg-0'>
            <div className='container-xl px-0'>
              <form
                className='col-md-8 col-xl-7 mx-auto'
                onSubmit={handleSubmit(onSubmit)}
              >
                <Link to='/login' className='d-flex align-items-center mb-4'>
                  <SvgBox rotate='180' size='10px'>
                    <ArrowIcon />
                  </SvgBox>
                  <span className='font-weight-bold ml-2'>
                    Login • Moveappy
                  </span>
                </Link>
                <SvgBox size='40px' className='text-primary mb-4'>
                  <Logo />
                </SvgBox>
                <h2 className='font-weight-bolder'>Forgot Password</h2>
                <p>See your growth and get consulting support!</p>
                <div className='rld-single-input mt-3'>
                  <input
                    type='email'
                    name='email'
                    placeholder='Enter Email'
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div className='mt-4'>
                  <button
                    type='submit'
                    disabled={loading}
                    className={`btn btn-secondary btn-block ${
                      loading === true ? 'button-disabled' : null
                    }`}
                  >
                    {/* {loading === true ? (
                                        <div className="spinner-btn mt-2"></div>
                                    ) : <>Send Email</>} */}
                    {loading === true ? (
                      <div
                        class='spinner-border spinner-border-sm text-light mr-2'
                        role='status'
                      >
                        <span class='sr-only'>Loading...</span>
                      </div>
                    ) : null}
                    Send Email
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className='col-12 col-lg-6 px-0 order-first order-lg-last form-hero d-none d-sm-block'
            style={inlineStyle}
          >
            <div className='col-lg-8 d-flex flex-column text-center justify-content-center align-items-center h-100 mx-auto'>
              <h2 className='mb-4 text-white'>Forgot Password</h2>
              <p className='my-0 text-white-50'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor
                accusantium amet esse. Saepe sequi aliquam id blanditiis
                repellat perspiciatis, quos maiores inventore impedit cumque
                eius vitae aut tempora aspernatur fugit!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
