import React from 'react';
import { Spinner } from 'reactstrap';
import { useAgencyListing } from '../../../hooks/useAgencyListing';
import { Link, useParams } from 'react-router-dom';

const OfficeCard = ({ image, title }) => {
  return (
    <div>
      <div
        className='croydon-card m-3 bg-image-cover d-flex flex-column'
        style={{
          height: '250px',

          backgroundImage: `url(${image})`,
        }}
      >
        <div className='mt-auto p-3 bg-black-o-4 d-flex flex-column justify-content-end'>
          <div className='d-flex flex-wrap'>
            <div>
              <div
                className='h4 text-uppercase text-white title-font font-weight-bold'
                style={{ letterSpacing: '2px' }}
              >
                {title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CroydonOffices = ({ id }) => {
  const { isLoading, data } = useAgencyListing({
    id,
  });

  const { id: slug } = useParams();

  if (isLoading) {
    return <Spinner />;
  }

  const forSale = data?.find((data) => data.category.value === 'sale');
  const serviced = data?.find((data) => data.category.value === 'serviced');

  const coWorking = data?.find(
    (data) => data.category.value === 'co-working space'
  );
  const rent = data?.find((data) => data.category.value === 'rent');
  return (
    <div>
      <div className='row row-cols-2 row-cols-sm-2 row-cols-lg-4 justify-content-between'>
        {forSale && (
          <Link
            className='col-md-6 p-0'
            to={`/partner/${slug}/listings?tab=for-sale`}
          >
            <OfficeCard title={'For Sale'} image={forSale.images[0]} />
          </Link>
        )}
        {rent && (
          <Link
            className='col-md-6 p-0'
            to={`/partner/${slug}/listings?tab=to-let`}
          >
            <OfficeCard title={'For Rent'} image={rent.images[0]} />
          </Link>
        )}
        {serviced && (
          <Link
            className='col-md-6 p-0'
            to={`/partner/${slug}/listings?tab=serviced`}
          >
            <OfficeCard title={'Serviced'} image={serviced.images[0]} />
          </Link>
        )}
        {coWorking && (
          <Link
            className='col-md-6 p-0'
            to={`/partner/${slug}/listings?tab=co-working`}
          >
            <OfficeCard title={'Co-Working'} image={coWorking.images[0]} />
          </Link>
        )}
      </div>
      <div className='text-right mt-5 pb-3'>
        <Link
          to={`/partner/${slug}/listings`}
          className='btn-3 btn-text py-3 px-4 font-weight-bold title-font text-uppercase'
        >
          View all
        </Link>
      </div>
    </div>
  );
};
