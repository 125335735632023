import React from 'react';
import { numberWithCommas } from '../../../utils/numberWithCommas';

export const PropertyInfo = ({ details }) => {
  const { bedrooms, bathrooms, landArea, unit, parking, outdoorSpace, type } =
    details;

  const publicUrl = process.env.PUBLIC_URL + '/';

  const listBoxArr = [
    {
      title: 'Bed',
      value: bedrooms,
      url: 'assets/svg/ma-listing-bedrooms.svg',
    },
    {
      title: 'Bath',
      value: bathrooms,
      url: 'assets/svg/ma-listing-bathrooms.svg',
    },
    // {
    //   title: 'Size',
    //   value: `${numberWithCommas(landArea)} ${unit.value.replace(/\s/g, '')}`,
    //   url: 'assets/svg/ma-listing-size.svg',
    // },
    {
      title: 'Parking',
      value: parking?.value,
      url: 'assets/svg/ma-listing-parking.svg',
    },
    {
      title: 'Outdoor Space',
      value: outdoorSpace?.value,
      url: 'assets/svg/ma-listing-garden.svg',
    },
  ];
  return (
    <div>
      <div className='mb-2 d-none d-md-block'>
        <ul className='nav flex-column flex-md-row '>
          {listBoxArr.map((data) => (
            <>
              {type?.value === 'commercial property' &&
              data.title === 'Bed' ? null : (
                <>
                  <li className='d-flex pr-3'>
                    <div className='d-flex align-items-center h-100'>
                      <div>
                        <img
                          src={publicUrl + data.url}
                          alt='icon'
                          width={30}
                          height={30}
                        />
                      </div>

                      <div className='mx-1 text-muted text-uppercase font-weight-bold text-capitalize'>
                        {data.value}
                      </div>
                      <div className='text-muted text-uppercase font-weight-bold'>
                        {data.title}
                      </div>
                    </div>
                  </li>
                </>
              )}
            </>
          ))}
        </ul>
      </div>
      <div className='mb-5 d-md-none'>
        <ul className='nav flex-column flex-md-row'>
          {listBoxArr.map((data) => (
            <>
              {type?.value === 'commercial property' &&
              data.title === 'Bed' ? null : (
                <>
                  <li className='d-flex align-items-end border-bottom py-2'>
                    <div className='d-flex align-items-center text-black-50'>
                      <div className='mx-1 text-capitalize'>{data.value}</div>
                      <div className=''>{data.title}</div>
                    </div>
                    <div className='ml-auto'>
                      <img
                        src={publicUrl + data.url}
                        alt='icon'
                        width={30}
                        height={30}
                      />
                    </div>
                  </li>
                </>
              )}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};
