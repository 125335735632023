import React from 'react';
import Select from 'react-select';
import { Input, Modal } from 'reactstrap';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as CloseIcon } from '../../../images/icons/btn-close.svg';
import { ReactComponent as SearchIcon } from '../../../images/icons/search.svg';
import { Controller, useFormContext } from 'react-hook-form';
import { useOptionsDropdown } from '../../../hooks/useOptionGroup';
import {
  formatGroupLabel,
  getOptionsByValue,
  GetTypeOptions,
} from '../../../utils/optionFormatter';
import { SelectThreeStyle } from '../../../styles/react-select-styles/SelectThreeStyle';

const daysOptions = [
  { value: 1, label: 'Last 24 hours' },
  { value: 3, label: 'Last 3 days' },
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
];
const DDACompliantOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const AdvancedSearch = ({ toggle, isOpen, isCroydon = false }) => {
  const { control, handleSubmit } = useFormContext();

  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  return (
    <Modal
      className='m-0 m-md-auto'
      contentClassName='border-0'
      size='lg'
      centered
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className='d-flex flex-column '>
        <div className='d-flex justify-content-between align-items-center px-4 py-2 bg-light rounded-top position-sticky fixed-top'>
          <h6 className='mb-2 text-uppercase'>Advanced Search</h6>
          <button type='button' className='btn p-0' onClick={toggle}>
            <SvgBox>
              <CloseIcon />
            </SvgBox>
          </button>
        </div>
        <form className='p-4 my-auto'>
          <div className='row'>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Category</label>

                <Controller
                  name='category'
                  control={control}
                  isClearable={true}
                  isLoading={isLoading}
                  options={getOptionsByValue('category', options)}
                  as={
                    <Select
                      styles={SelectThreeStyle}
                      placeholder='Rent / Sale'
                    />
                  }
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Type</label>
                <Controller
                  control={control}
                  isLoading={isLoading}
                  name='type'
                  placeholder='Select Type'
                  isClearable={true}
                  formatGroupLabel={formatGroupLabel}
                  options={GetTypeOptions(options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Minimum Price</label>

                <Controller
                  placeholder='Enter Minimum Price'
                  control={control}
                  name='minPrice'
                  as={<Input type='number' className='p-4 border-blue' />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Maximum Price</label>

                <Controller
                  placeholder='Enter Maximum Price'
                  control={control}
                  name='maxPrice'
                  as={<Input type='number' className='p-4 border-blue' />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Bedrooms</label>

                <Controller
                  placeholder='Enter Bedrooms'
                  control={control}
                  name='bedrooms'
                  as={<Input type='number' className='p-4 border-blue' />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Bathrooms</label>

                <Controller
                  placeholder='Enter Bathrooms'
                  control={control}
                  name='bathrooms'
                  as={<Input type='number' className='p-4 border-blue' />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Receptions</label>

                <Controller
                  placeholder='Enter Receptions'
                  control={control}
                  name='receptions'
                  as={<Input type='number' className='p-4 border-blue' />}
                />
              </div>
            </div>

            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Tenure</label>
                <Controller
                  control={control}
                  name='tenure'
                  placeholder='Select Tenure'
                  isClearable={true}
                  isLoading={isLoading}
                  options={getOptionsByValue('tenure', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Unit</label>
                <Controller
                  control={control}
                  isLoading={isLoading}
                  name='unit'
                  placeholder='Select Unit'
                  isClearable={true}
                  options={getOptionsByValue('unit', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>

            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Parking</label>
                <Controller
                  control={control}
                  name='parking'
                  isLoading={isLoading}
                  placeholder='Select Parking'
                  isClearable={true}
                  options={getOptionsByValue('parking', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>

            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Accessibility</label>
                <Controller
                  control={control}
                  isLoading={isLoading}
                  name='accessibility'
                  placeholder='Select Accessibility'
                  isClearable={true}
                  options={getOptionsByValue('accessibility', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>

            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Outdoor Space</label>
                <Controller
                  control={control}
                  isLoading={isLoading}
                  name='outdoorSpace'
                  placeholder='Select Outdoor Space'
                  isClearable={true}
                  options={getOptionsByValue('outdoor space', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>

            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Days</label>
                <Controller
                  control={control}
                  name='days'
                  placeholder='Select Days..'
                  isClearable={true}
                  options={daysOptions}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>

            <div className='col-sm-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>
                  distance from west croydon
                </label>
                <Controller
                  control={control}
                  name='distancefromwestcroydon'
                  placeholder='Distance From west Croydon..'
                  isClearable={true}
                  options={getOptionsByValue(
                    'distance from west croydon',
                    options
                  )}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>
                  distance from east croydon
                </label>
                <Controller
                  control={control}
                  name='distancefromeastcroydon'
                  placeholder='Distance From East Croydon..'
                  isClearable={true}
                  options={getOptionsByValue(
                    'distance from east croydon',
                    options
                  )}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>DDA Compliant</label>
                <Controller
                  control={control}
                  name='DDACompliant'
                  placeholder='Select DDACompliant..'
                  isClearable={true}
                  options={DDACompliantOptions}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Available Term</label>
                <Controller
                  control={control}
                  name='availableTerm'
                  placeholder='Select Available Term..'
                  isClearable={true}
                  options={getOptionsByValue('available term', options)}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 mb-4'>
              <div className='rld-single-select'>
                <label className='text-uppercase h6 mb-2'>Feature</label>
                <Controller
                  control={control}
                  name='features'
                  placeholder='Select Feature..'
                  isClearable={true}
                  options={getOptionsByValue('features', options)?.filter(
                    feature => !feature.value.includes('stockappy')
                  )}
                  as={<Select styles={SelectThreeStyle} />}
                />
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <button
              onClick={() => {
                handleSubmit();
                toggle();
              }}
              className='btn-5 btn-text  border btn-block py-3 px-4 font-weight-bold title-font text-uppercase'
            >
              <div className='d-flex  justify-content-center '>
                <SvgBox className='mr-3' size='16px'>
                  <SearchIcon />
                </SvgBox>
                <span className='font-weight-bold ' style={{ fontSize: 13 }}>
                  Search
                </span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdvancedSearch;
