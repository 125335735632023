import React, { useState } from 'react';
import { ReplyModal } from './replyModal';

export const DeskItem = (props) => {
  const { msg, index } = props;
  const [showReqModal, setShowReqModal] = useState(false);

  console.log(props);
  return (
    <>
      <tr>
        <td className='py-3'>{index + 1}</td>
        <td className='py-3'>{msg.senderEmail}</td>
        <td className='text-left py-3'>{msg.message}</td>
        {/* <td className='text-center'>
          <button
            className='btn btn-secondary py-1'
            onClick={() => setShowReqModal(true)}
          >
            Reply
          </button>
        </td> */}
      </tr>
      {showReqModal ? (
        <ReplyModal
          showReqModal={showReqModal}
          setShowReqModal={setShowReqModal}
          senderEmail={msg.senderEmail}
          requestId={msg._id}
          propertyTitle={`Response To  Croydon Commercial `}
        />
      ) : null}
    </>
  );
};
