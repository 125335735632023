import React from 'react';
import {
  Col,
  Input,
  Row,
  InputGroup,
  Label,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import { getOptionsByValue } from '../../../../utils/optionFormatter';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const Details = ({ editorState, setEditorState }) => {
  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const { errors, register, control, watch } = useFormContext();

  const type = watch('type')?.value;

  const category = watch('category')?.value;

  const priceType = watch('priceType')?.value;

  return (
    <Col className='p-0'>
      <Row className='align-items-center mb-4'>
        <Col className='mb-4' md={12}>
          <label className='text-uppercase mb-2'>Price Type</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('price', options)}
            rules={{ required: true }}
            placeholder='Select Price Type'
            isLoading={isLoading}
            name='priceType'
            defaultValue={''}
            control={control}
          />
          <ErrorMessage
            errors={errors}
            name='priceType'
            message='This field is required.'
            render={({ message }) => <p className='text-danger'>{message}</p>}
          />
        </Col>
        <Col>
          <label className='text-uppercase mb-2'>MINIMUM NO. OF PEOPLE</label>
          <div className=' mb-0'>
            <Controller
              control={control}
              name='numberOfMinimumPeople'
              placeholder='Minimum'
              min={1}
              type='number'
              className='rounded-0 custom-input-padding'
              as={Input}
            />
            <ErrorMessage
              errors={errors}
              name='numberOfMinimumPeople'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-danger '>{message}</p>
              )}
            />
          </div>
        </Col>
        <Col>
          <label className='text-uppercase mb-2'>MAXIMUM NO. OF PEOPLE</label>
          <div className=' mb-0'>
            <Controller
              control={control}
              name='numberOfMaximumPeople'
              placeholder='Minimum'
              min={1}
              type='number'
              className='rounded-0 custom-input-padding'
              as={Input}
            />
            <ErrorMessage
              errors={errors}
              name='numberOfMaximumPeople'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-danger '>{message}</p>
              )}
            />
          </div>
        </Col>
      </Row>

      <Row className='align-items-center'>
        {category === 'to rent' &&
        priceType !== 'price on application (poa)' ? (
          <Col className='mb-4' md={6}>
            <label className='text-uppercase mb-2'>Weekly Rent</label>
            <div className=' mb-0'>
              <Input
                name='priceWeek'
                placeholder='£'
                min={1}
                type='number'
                innerRef={register({ required: true })}
                className='rounded-0 custom-input-padding'
              />
              <ErrorMessage
                errors={errors}
                name='priceWeek'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-danger'>{message}</p>
                )}
              />
            </div>
          </Col>
        ) : null}

        {priceType === 'price on application (poa)' ? null : (
          <Col className='mb-4'>
            <label className='text-uppercase mb-2'>
              {category === 'to rent' ? 'Monthly Rent' : 'Price'}
            </label>
            <div className=' mb-0'>
              <Controller
                control={control}
                rules={{ required: true }}
                name='price'
                placeholder='£'
                min={1}
                type='number'
                className='rounded-0 custom-input-padding'
                as={Input}
              />
              <ErrorMessage
                errors={errors}
                name='price'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-danger '>{message}</p>
                )}
              />
            </div>
          </Col>
        )}
        <Col className='mb-4 ' md={12}>
          <label className='text-uppercase mb-2'>Staff Numbers</label>
          <div className=' mb-0'>
            <Controller
              control={control}
              placeholder='Staff Numbers'
              name='numberOfStaff'
              as={<Input min={1} type='number' className='rounded-0 ' />}
            />
          </div>
        </Col>
        {type === 'house' || type === 'flat' ? (
          <Col md={12}>
            <Row className='align-items-center'>
              <Col className='my-4'>
                <InputGroup>
                  <Controller
                    as={<Input min={1} type='number' />}
                    rules={{ required: true }}
                    name='bathrooms'
                    control={control}
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>Bathrooms</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                <ErrorMessage
                  errors={errors}
                  name='bathrooms'
                  message='This field is required.'
                  render={({ message }) => (
                    <p className='text-danger'>{message}</p>
                  )}
                />
              </Col>
              {type === 'commercial' ? (
                <Col className='my-4' md={4}>
                  <InputGroup>
                    <Controller
                      as={<Input min={1} type='number' />}
                      rules={{ required: true }}
                      name='receptions'
                      control={control}
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText>receptions</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    name='receptions'
                    message='This field is required.'
                    render={({ message }) => (
                      <p className='text-danger'>{message}</p>
                    )}
                  />
                </Col>
              ) : null}

              {type !== 'commercial' ? (
                <Col className='my-4'>
                  <InputGroup>
                    <Controller
                      as={<Input min={1} type='number' />}
                      rules={{ required: true }}
                      name='bedrooms'
                      control={control}
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText>bedrooms</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    name='bedrooms'
                    message='This field is required.'
                    render={({ message }) => (
                      <p className='text-danger'>{message}</p>
                    )}
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        ) : null}

        <Col md={12}>
          <Row className='align-items-center'>
            <Col className='mb-4' md={6}>
              <label className='text-uppercase mb-2'>Minimum Size</label>
              <Controller
                control={control}
                rules={{ required: true }}
                name='availableSpaceMinimum'
                placeholder='Minimum size'
                min={1}
                type='number'
                className='rounded-0 custom-input-padding'
                as={Input}
              />
              <ErrorMessage
                errors={errors}
                name='availableSpaceMinimum'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-danger '>{message}</p>
                )}
              />
            </Col>
            <Col className='mb-4' md={6}>
              <label className='text-uppercase mb-2'>Maximum Size</label>
              <Controller
                control={control}
                rules={{ required: true }}
                name='availableSpaceMaximum'
                placeholder='Maximum Size'
                min={1}
                type='number'
                className='rounded-0 custom-input-padding'
                as={Input}
              />
              <ErrorMessage
                errors={errors}
                name='availableSpaceMaximum'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-danger '>{message}</p>
                )}
              />
            </Col>
          </Row>
        </Col>

        {/* <Col className='mb-4 ' md={12}>
          <label className='text-uppercase mb-2'>Total Property Size</label>
          <div className=' mb-0'>
            <Controller
              rules={{ required: true }}
              control={control}
              placeholder='Area'
              name='landArea'
              as={<Input type='number' min={1} className='rounded-0 ' />}
            />
            <ErrorMessage
              errors={errors}
              name='landArea'
              message='This field is required.'
              render={({ message }) => <p className='text-danger'>{message}</p>}
            />
          </div>
        </Col> */}

        <Col md={12}>
          <Row className='align-items-center'>
            <Col className='mb-4' md={12}>
              <label className='text-uppercase mb-2'>Unit</label>
              <Controller
                as={Select}
                options={getOptionsByValue('unit', options)}
                rules={{ required: true }}
                placeholder='Select Unit'
                isLoading={isLoading}
                name='unit'
                control={control}
              />

              <ErrorMessage
                errors={errors}
                name='unit'
                message='This field is required.'
                render={({ message }) => (
                  <p className='text-danger'>{message}</p>
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col className='mt-3' md={12}>
          <FormGroup>
            <Label className=' text-uppercase '>
              Enter the description about property
            </Label>
            <Controller
              control={control}
              name='description'
              as={
                <Editor
                  placeholder='Enter Description'
                  toolbar={{
                    options: [
                      'blockType',
                      'inline',
                      'list',
                      'textAlign',
                      'link',
                    ],
                    fontSize: {
                      options: [16, 18, 24, 32, 36, 48],
                    },
                    link: {
                      inDropdown: true,

                      showOpenOptionOnHover: true,
                      defaultTargetOption: '_blank',
                      options: ['link', 'unlink'],
                    },
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    textAlign: {
                      options: ['left', 'center', 'right'],
                    },
                    list: {
                      options: ['unordered', 'ordered'],
                    },
                  }}
                  handlePastedText={() => false}
                  editorState={editorState}
                  toolbarClassName='toolbarClassName'
                  wrapperClassName='wrapperClassName'
                  editorClassName='editorClassName'
                  onEditorStateChange={setEditorState}
                />
              }
            />

            <ErrorMessage
              errors={errors}
              name='description'
              message='This field is required.'
              render={({ message }) => <p className='text-danger'>{message}</p>}
            />
          </FormGroup>
        </Col>
      </Row>
    </Col>
  );
};
