import React from 'react';
import { SignIn } from '../../components/auth-components/signIn';

import { useRedirect } from '../../hooks/useRedirect';

const Login = () => {
  useRedirect();
  return (
    <div>
      <SignIn />
    </div>
  );
};

export default Login;
