import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { MovingServiceCarousel } from './movingServiceCarousel';

export const ServiceAdsOnCityPage = ({ city }) => {
  const getAdsByCity = async city => {
    try {
      const res = await axios.get(`/service/ads/city/${city}`);
      return res.data.ads;
    } catch (err) {
      throw err.response.data;
    }
  };

  const { data, isLoading } = useQuery(
    'getAdsByCity',
    () => getAdsByCity(city),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div class='spinner-custom '>
        <div class='text-center'></div>
      </div>
    );
  }

  return (
    <div className='row'>
      <div className='col-md-12 d-flex flex-column w-100'>
        {data?.length > 0 ? <MovingServiceCarousel services={data} /> : null}
      </div>
    </div>
  );
};
