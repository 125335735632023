import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  CardImgOverlay,
  CardImg,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
// import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import notifications from '../notifications';
import { usePlaceHolder } from '../../utils/usePlaceholder';
import { PlacesAutoComp } from '../properties-components/placesAutoComplete';

export const PostAd = () => {
  const [activeTab, setActiveTab] = useState('2');
  const [address, setAddress] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [city, setCity] = useState(null);
  const [locError, setLocError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [label, setLabel] = useState(null);
  const [errorsFile, setErrorsFile] = useState('');
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm();
  const { placeholder, file, onChange } = usePlaceHolder();

  const addServiceProviderHandler = async data => {
    setLoading(true);
    const { title, url, label, description, radius } = data;
    const formdata = new FormData();
    formdata.append('imageUrl', file);
    // type !== null ? formdata.append('type', type) : <> </>;
    formdata.append('title', title);
    city !== null ? formdata.append('city', city) : <> </>;
    formdata.append('address', address);
    postCode !== null ? formdata.append('postCode', postCode) : <></>;
    formdata.append('lat', lat);
    formdata.append('lng', lng);
    formdata.append('radius', radius);
    formdata.append('description', description);
    formdata.append('url', url);
    formdata.append('label', label);
    formdata.append('linkLayout', activeTab);
    try {
      const res = await axios.post(`/service/ads`, formdata);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  };

  const mutation = useMutation(addServiceProviderHandler, {
    onSuccess: () => {
      reset();
      notifications.success('Success', 'Posted Successfully');
      setLoading(false);
      history.push('/');
      // queryClient.invalidateQueries('propertiesByCityCount');
      // queryClient.invalidateQueries([`requests-${requestId}`, requestId]);
    },
    onError: () => {
      setLoading(false);
      notifications.error('Error', 'Try Again Later!');
    },
  });

  const onSubmit = data => {
    if (!placeholder) {
      setErrorsFile(true);
      if (address === null || lat === null || lng === null) {
        setLocError(true);
      }
    } else {
      setErrorsFile(false);
      if (address === null || lat === null || lng === null) {
        setLocError(true);
      } else {
        setLocError(false);
        mutation.mutate(data);
      }
    }
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <section className='new-property-container pd-top-90 pb-5'>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className='text-center'>Post Your Ad</h2>
          <Row className='justify-content-between'>
            <Col lg={6} xl={5}>
              <div>
                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>URL</h6>
                  <div class='input-group-prepend'>
                    <span class='input-group-text'>https://</span>
                    <input
                      type='text'
                      className='box-shadow-primary'
                      name='url'
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.url && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Title</h6>
                  <input
                    type='text'
                    className='box-shadow-primary'
                    name='title'
                    onChange={e => setTitle(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.title && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Description</h6>
                  <textarea
                    className='box-shadow-primary'
                    name='description'
                    rows='4'
                    onChange={e => setDescription(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.description && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Label</h6>
                  <input
                    type='text'
                    className='box-shadow-primary'
                    name='label'
                    onChange={e => setLabel(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.label && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Radius</h6>
                  <input
                    type='number'
                    className='box-shadow-primary'
                    name='radius'
                    ref={register({ min: 1, required: true })}
                  />
                  {errors.radius && (
                    <small className='font-weight-bold text-danger'>
                      This field is required to be a positive value
                    </small>
                  )}
                </div>
                <div className='box-shadow-primary rounded-lg py-2 px-3 mt-3 border'>
                  <button
                    type='button'
                    className='px-0 py-1 d-flex justify-content-between align-items-center btn btn-link btn-block'
                  >
                    <div className='text-left font-weight-bold text-capitalize'>
                      Image
                    </div>
                    <span className='d-flex'>
                      {placeholder ? (
                        <img
                          alt=''
                          src={placeholder}
                          width={60}
                          height='100%'
                        />
                      ) : (
                        <i className='fa fa-picture-o fa-2x' />
                      )}
                    </span>
                    <input
                      type='file'
                      id='file'
                      accept='.jpeg, .jpg, .svg, .png'
                      name='file'
                      className='position-absolute w-100 btn'
                      style={{ opacity: 0, zIndex: 10 }}
                      onChange={onChange}
                    />
                  </button>
                </div>
                {errorsFile && (
                  <small className='font-weight-bold text-danger ml-2'>
                    Image is required
                  </small>
                )}
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div class='rld-single-input mb-4 mt-4'>
                <h6 class='title'>Address</h6>
                <PlacesAutoComp
                  setAddress={setAddress}
                  setLat={setLat}
                  setLng={setLng}
                  setPostCode={setPostCode}
                  setCity={setCity}
                  placeholderValue={''}
                />
                {locError ? (
                  <small className='font-weight-bold text-danger'>
                    Select your location again
                  </small>
                ) : null}
              </div>
              <div>
                <h6 class='title my-3'>Link Layout</h6>
                <Nav tabs className='border-0'>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../../images/image-2.svg').default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../../images/image-4.svg').default}
                      />
                    </NavLink>
                  </NavItem>
                </Nav>
                <h6 class='title mb-4'>Link Preview</h6>
                <TabContent
                  activeTab={activeTab}
                  className='p-4 border rounded-lg shadow-sm'
                >
                  <TabPane tabId='2'>
                    <Card className='flex-row' style={{ height: 200 }}>
                      <div className='h-100 w-100'>
                        <CardImg
                          className='w-100 h-100'
                          src={
                            !placeholder
                              ? require('../../images/placeholder.png').default
                              : placeholder
                          }
                          alt='Card image cap'
                        />
                      </div>
                      <CardBody className='h-100 scroll-box'>
                        <CardTitle tag='h5'>
                          {title !== null ? title : 'Card Title'}
                        </CardTitle>
                        <CardSubtitle tag='h6' className='mb-2 text-muted'>
                          {label !== null ? label : 'Label'}
                        </CardSubtitle>
                        <CardText>
                          {description !== null ? description : 'Description'}
                        </CardText>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId='1'>
                    <Card inverse>
                      <div style={{ height: 220 }}>
                        <CardImg
                          width='100%'
                          height='100%'
                          src={
                            !placeholder
                              ? require('../../images/placeholder.png').default
                              : placeholder
                          }
                          alt='Card image cap'
                        />
                        <CardImgOverlay className='d-flex flex-column justify-content-end'>
                          <CardTitle tag='h5'>
                            {title !== null ? title : 'Card Title'}
                          </CardTitle>
                          <CardSubtitle tag='h6' className='mb-2 text-muted'>
                            {label !== null ? label : 'Label'}
                          </CardSubtitle>
                          <CardText>
                            {description !== null ? description : 'Description'}
                          </CardText>
                        </CardImgOverlay>
                      </div>
                    </Card>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
          <div className='mt-5 mb-5 pb-5 mb-lg-0 pb-lg-0'>
            <Button
              block
              type='submit'
              disabled={loading}
              className={`${loading === true ? 'button-disabled' : null}`}
            >
              <h5 className='mb-0 text-white'>
                {loading === true ? (
                  <div
                    class='spinner-border spinner-border-sm text-light mr-2'
                    role='status'
                  >
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : null}
                Save <i className='fa fa-save ml-2'></i>
              </h5>
            </Button>
          </div>
        </form>
      </Container>
    </section>
  );
};
