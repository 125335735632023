import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MarkerComponent } from './mapComponents/propDetailNearPlaceMarker';

export const DashboardMap = ({ profile }) => {
  // setting markers
  const { lat, lng } = profile;
  const center = { lat, lng };
  const markers = [{ lat, lng, type: 'prop' }];

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDcGpYFY4FAxpuy06S1BLCHualvzCmrHWs' }}
        defaultCenter={center}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
      >
        {markers.map(mrk => (
          <MarkerComponent lat={mrk.lat} lng={mrk.lng} type={mrk.type} />
        ))}
      </GoogleMapReact>
    </div>
  );
};
