import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import notifications from '../notifications';
import { usePlaceHolder } from '../../utils/usePlaceholder';
import { Col, Row, Spinner } from 'reactstrap';

import { PlacesAutoComp } from '../properties-components/search/searchAutoComplete';

export const ProfileManagementSection = (props) => {
  const { profileData } = props;
  const { handleSubmit, errors, control, register, setValue } = useForm({
    defaultValues: profileData,
    shouldUnregister: false,
  });

  const [address, setAddress] = useState(profileData.address);

  const [loading, setLoading] = useState(false);

  const { placeholder, file, inputFile, onChange, onImageClick } =
    usePlaceHolder();

  const onSubmit = (data) => {
    setLoading(true);

    const { name, phoneNumber, website, market, description } = data;
    const formdata = new FormData();
    if (placeholder) {
      formdata.append('imageUrl', file);
    }
    formdata.append('address', address.address);

    formdata.append('name', name);
    formdata.append('phoneNumber', phoneNumber);
    formdata.append('description', description);

    formdata.append('website', website);

    formdata.append('market', market);
    axios
      .patch('/profile', formdata)
      .then((res) => {
        setLoading(false);
        notifications.success('Success', 'Updated Successfully');
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later!');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='my-4 d-flex flex-column align-items-center'>
        <h6> Logo</h6>
        <div
          className={`${
            placeholder ? '' : `bg-light`
          } mt-2 upload-circle shadow-sm rounded-circle`}
          type='button'
          // onClick={onImageClick}
        >
          <input
            type='file'
            id='file'
            accept='image/png/jpeg'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={onChange}
            name='file'
          />
          {placeholder ? (
            <span>
              <img
                src={'https://headsabovethecloud.co.uk/images/logo.png'}
                height='100px'
                width='100px'
                alt=''
                className='rounded-circle object-fit-contain'
              />
            </span>
          ) : (
            <span>
              <img
                height='100px'
                width='100px'
                alt=''
                src={'https://headsabovethecloud.co.uk/images/logo.png'}
                className='rounded-circle object-fit-contain'
              />
            </span>
          )}
        </div>
      </div>
      <Row className='mb-3'>
        <Col md={12}>
          <div className='rld-single-input'>
            <label className='text-muted'>Company Name</label>
            <Controller
              control={control}
              name='name'
              disabled
              rules={{ required: true }}
              as={<input placeholder='Enter Company Name...' />}
            />

            {errors.name && (
              <small className='font-weight-bold text-danger'>
                This field is required
              </small>
            )}
          </div>
        </Col>
        {/* <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Address</label>
            <PlacesAutoComp
              setAddress={setAddress}
              placeholderValue='Enter Address'
              defaultValue={profileData?.address}
            />
          </div>
        </Col> */}
      </Row>

      <Row className='mb-3'>
        <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Phone Number</label>
            <Controller
              control={control}
              name='phoneNumber'
              as={<input placeholder={'Enter Phone Number...'} />}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Company Website</label>
            <Controller
              name='website'
              control={control}
              as={<input placeholder={'Enter Company Website...'} />}
            />
          </div>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Twitter</label>
            <Controller
              control={control}
              name='twitter'
              as={<input placeholder={'Enter twitter handle...'} />}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className='rld-single-input mb-3'>
            <label className='text-muted'>Instagram</label>
            <Controller
              name='instagram'
              control={control}
              as={<input placeholder={'Enter Instagram Link...'} />}
            />
          </div>
        </Col>

        <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Youtube</label>
            <Controller
              control={control}
              name='youtube'
              as={<input placeholder={'Enter Youtube handle...'} />}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className='rld-single-input'>
            <label className='text-muted'>Facebook</label>
            <Controller
              name='facebook'
              control={control}
              as={<input placeholder={'Enter Facebook Link...'} />}
            />
          </div>
        </Col>

        <Col>
          <div className='rld-single-input mt-4'>
            <Controller
              control={control}
              name='description'
              as={<textarea rows={6} />}
            />
          </div>
        </Col>
      </Row>

      {/* <div>
        <div className='mt-4 text-center px-0 col-12 '>
          <button
            type='submit'
            disabled={loading}
            className={`btn btn-secondary btn-block`}
          >
            {loading && <Spinner className='mr-2' size='sm' />}
            Update Profile
          </button>
        </div>
      </div> */}
    </form>
  );
};
