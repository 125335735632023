import React, { useState } from 'react';
import { Container, Nav, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import moment from 'moment';

import { Intro } from './Intro/Intro';
import { Details } from './Details/details';
import { useForm, FormProvider } from 'react-hook-form';
import { FeaturesList } from './Features/features';
import { Save } from './Save';
import { ImagesGrid } from './Media/imagesGrid';
import { PropertySetting } from './Settings/PropertySetting';
import { Links } from './Media/Links';
import { convertToRaw } from 'draft-js';
import { DocumentUploads } from './Media/DocumentUploads';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { ScrollToTop } from '../../ScrollToTop';

export const PropertyForm = ({ defaultValues, type, mutation }) => {
  const [activeTab, setActiveTab] = useState('1');

  const [editorState, setEditorState] = useState(
    type === 'edit'
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(defaultValues.description)
          )
        )
      : EditorState.createEmpty()
  );

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      partnerId: defaultValues?.partnerId?.length
        ? {
            logo: defaultValues.partnerId[0].logo,
            label: defaultValues.partnerId[0].name,
            value: defaultValues.partnerId[0]._id,
          }
        : '',
      coverImage: defaultValues?.coverImage
        ? {
            label: defaultValues?.coverImage,
            value: defaultValues?.coverImage,
            logo: defaultValues?.coverImage,
          }
        : null,
      expiryDate: moment
        .utc(defaultValues?.expiryDate)
        .format('yyyy-MM-DD')
        .toString(),
    },
    shouldUnregister: false,
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onSubmit = (data) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    data.description = markup;
    data.numberOfStaff = data.numberOfStaff
      ? parseInt(data.numberOfStaff)
      : null;
    data.availableSpaceMaximum = data.availableSpaceMaximum
      ? parseInt(data.availableSpaceMaximum)
      : null;
    data.availableSpaceMinimum = data.availableSpaceMinimum
      ? parseInt(data.availableSpaceMinimum)
      : null;
    data.partnerId = data?.partnerId?.value || undefined;
    data.coverImage = data.coverImage?.value || undefined;

    mutation.mutate(data);
  };
  return (
    <section className='h-100'>
      <ScrollToTop />
      <Container>
        <div className='mt-5 col-md-8 mx-auto'>
          <Nav className='flex-nowrap flex-lg-wrap scroll-hide overflow-auto justify-content-start border-top border-bottom pt-3 pb-2'>
            <li className='col-4 col-md-3 col-lg px-0 mr-3'>
              <div
                className={`border-0 tab-title link-line-2 ${classnames({
                  active: activeTab === '1',
                })}`}
                onClick={() => {
                  toggle('1');
                }}
              >
                Main
              </div>
            </li>
            <li className='col-4 col-md-3 col-lg px-0 mr-3'>
              <div
                className={`border-0 tab-title link-line-2 ${classnames({
                  active: activeTab === '2',
                })}`}
                onClick={() => {
                  toggle('2');
                }}
              >
                Features
              </div>
            </li>
            <li className='col-4 col-md-3 col-lg px-0 mr-3'>
              <div
                className={`border-0 tab-title link-line-2 ${classnames({
                  active: activeTab === '3',
                })}`}
                onClick={() => {
                  toggle('3');
                }}
              >
                Links / Docs
              </div>
            </li>

            <li className='col-4 col-md-3 col-lg px-0'>
              <div
                className={`border-0 tab-title link-line-2 ${classnames({
                  active: activeTab === '4',
                })}`}
                onClick={() => {
                  toggle('4');
                }}
              >
                Images Grid
              </div>
            </li>
            <li className='col-4 col-md-3 col-lg px-0'>
              <div
                className={`border-0 tab-title link-line-2 ${classnames({
                  active: activeTab === '5',
                })}`}
                onClick={() => {
                  toggle('5');
                }}
              >
                Settings
              </div>
            </li>
          </Nav>
        </div>
        <div className='mb-5 col-md-8 mx-auto'>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className='py-4'>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId='1'>
                    <div>
                      <Intro />
                      <Details
                        editorState={editorState}
                        setEditorState={setEditorState}
                      />
                    </div>
                  </TabPane>

                  <TabPane tabId='2'>
                    <FeaturesList />
                  </TabPane>

                  <TabPane tabId='3'>
                    <DocumentUploads />
                    <Links />
                  </TabPane>

                  <TabPane tabId='4'>
                    <ImagesGrid />
                  </TabPane>
                  <TabPane tabId='5'>
                    <PropertySetting />
                  </TabPane>
                  <Save loading={mutation?.isLoading} type={type} />
                </TabContent>
              </div>
            </form>
          </FormProvider>
        </div>
      </Container>
    </section>
  );
};
