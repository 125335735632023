import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useFeaturedProperties } from '../../../hooks/useFeaturedProperties';
import { CroydonCard } from './CroydonCard';

export const CroydonFeaturedListing = ({
  id,
  skeletonClassName = 'row row-cols-1 row-cols-md-2 justify-content-between',
  className = 'd-flex flex-wrap justify-content-between',
  row,
  noHeight,
}) => {
  const { isLoading, data } = useFeaturedProperties({
    id,
    limit: 4,
  });

  if (isLoading) {
    return (
      <div className={`${skeletonClassName}`}>
        <div className='p-3'>
          <Skeleton height={200} />
        </div>
        <div className='p-3'>
          <Skeleton height={200} />
        </div>
        <div className='p-3'>
          <Skeleton height={200} />
        </div>
        <div className='p-3'>
          <Skeleton height={200} />
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: `${noHeight ? '' : '500px'}` }}>
      <div className={`${className}`}>
        {data?.map((item) => (
          <CroydonCard key={item._id} item={item} row={row} />
        ))}
      </div>
      {/* <div className='mt-3 pb-3'>
        <Link
          to='/croydon-listings'
          className='btn-3 btn-text py-3 px-4 font-weight-bold title-font text-uppercase'
        >
          View all
        </Link>
      </div> */}
    </div>
  );
};
