import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { FeaturedLoader } from '../home/searchCardFeaturedLoader';
import { ListCardLoader } from '../../shimmers/listLoader';

import { SvgBox } from '../../../images/icons/SvgBox';

import { ReactComponent as IconGrid } from '../../../images/icons/grid.svg';
import { ReactComponent as IconList } from '../../../images/icons/list.svg';
import Card from '../card';
import { Row, Col } from 'reactstrap';
import { ClusterMap } from '../../map/clusteringExample';
import { getParam, getSearchParams, setParams } from '../../../utils/urlHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { Searchbar } from './Searchbar';
import { Error } from '../../../utils/errorComponent';
import { MapToggle } from './MapToggle';
import { CroydonCard } from '../../../pages/Croydon/components/CroydonCard';

const SearchPropertiesSection = ({ isCroydon = false }) => {
  const [map, setMap] = useState(false);

  const [searchedProperties, setSearchedProperties] = useState([]);
  const [cardView, setCardView] = useState('grid');
  const [loading, setLoading] = useState(false);

  const queryData = getSearchParams();

  const [query, setQuery] = useState({
    text: getParam('text', 'single'),
    days: getParam('addedOn', 'single'),
    bedrooms: getParam('bedrooms', 'single'),
    receptions: getParam('receptions', 'single'),
    bathrooms: getParam('bathrooms', 'single'),
    type: getParam('type', 'single'),
    unit: getParam('unit', 'single'),
    parking: getParam('parking', 'single'),
    tenure: getParam('tenure', 'single'),
    accessibility: getParam('accessibility', 'single'),
    category: getParam('category', 'single'),
    maxPrice: getParam('min', 'single'),
    minPrice: getParam('max', 'single'),
    features: getParam('features', 'single'),
    DDACompliant:
      getParam('DDACompliant', 'single') === 'true'
        ? true
        : getParam('DDACompliant', 'single') === 'false'
        ? true
        : null,
    availableTerm: getParam('availableTerm', 'single'),
    distancefromwestcroydon: getParam('distancefromwestcroydon', 'single'),
    distancefromeastcroydon: getParam('distancefromeastcroydon', 'single'),
  });

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: query,
  });

  const getSearchProperties = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/property/search/filter', query);
      setSearchedProperties(res.data.properties);
      setLoading(false);
      return res.data.properties;
    } catch (err) {
      setLoading(false);
      throw err.response;
    }
  };

  const onHandleSubmit = (data) => {
    setLoading(true);
    const {
      text,
      category,
      bedrooms,
      receptions,
      bathrooms,
      days,
      maxPrice,
      minPrice,
      tenure,
      type,
      unit,
      outdoorSpace,
      parking,
      accessibility,
      features,
      DDACompliant,
      availableTerm,
      distancefromeastcroydon,
      distancefromwestcroydon,
    } = data;

    setParams('text', text, 'single', queryData);
    setParams('outdoorSpace', outdoorSpace?.value, 'single', queryData);
    setParams('type', type?.value, 'single', queryData);
    setParams('parking', parking?.value, 'single', queryData);
    setParams('tenure', tenure?.value, 'single', queryData);
    setParams('accessibility', accessibility?.value, 'single', queryData);
    setParams('unit', unit?.value, 'single', queryData);
    setParams('min', minPrice, 'single', queryData);
    setParams('max', maxPrice, 'single', queryData);
    setParams('addedOn', days?.value, 'single', queryData);
    setParams('category', category?.value, 'single', queryData);
    setParams('bedrooms', bedrooms, 'single', queryData);
    setParams('receptions', receptions, 'single', queryData);
    setParams('bathrooms', bathrooms, 'single', queryData);
    setParams('features', features?.value, 'single', queryData);
    setParams('DDACompliant', DDACompliant?.value, 'single', queryData);
    setParams('availableTerm', availableTerm?.value, 'single', queryData);
    setParams(
      'distancefromeastcroydon',
      distancefromeastcroydon?.value,
      'single',
      queryData
    );
    setParams(
      'distancefromwestcroydon',
      distancefromwestcroydon?.value,
      'single',
      queryData
    );

    const payload = {
      ...query,
      text,
      accessibility: accessibility?.value || null,
      unit: unit?.value || null,
      parking: parking?.value || null,
      outdoorSpace: outdoorSpace?.value || null,
      bedrooms,
      bathrooms,
      receptions,
      maxPrice,
      minPrice,
      DDACompliant: DDACompliant?.value,
      tenure: tenure?.value || null,
      availableTerm: availableTerm?.value || null,
      distancefromeastcroydon: distancefromeastcroydon?.value || null,
      distancefromwestcroydon: distancefromwestcroydon?.value || null,
      features: features?.value || null,
      type: type?.value || null,
      category: category?.value || null,
      days: days?.value || null,
    };

    axios
      .post('/property/search/filter', payload)
      .then((res) => {
        setSearchedProperties(res.data.properties);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const { isLoading, isError } = useQuery(
    `searchProperties`,
    getSearchProperties,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isError) {
    return <Error />;
  }
  return (
    <div>
      <FormProvider {...methods}>
        <div className='search-page-wrap flex-fill'>
          <div className='search-container'>
            <section className='px-3 px-md-5 px-lg-0'>
              {/* Desktop Start */}
              {!map && (
                <div
                  className={`pt-lg-5 ${
                    isCroydon ? 'container-1' : 'container'
                  } `}
                >
                  {searchedProperties?.length > 0 && !map ? (
                    <MapToggle setMap={setMap} map={map} />
                  ) : null}
                  <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
                    <Searchbar loading={loading} isCroydon={isCroydon} />
                  </form>
                </div>
              )}

              <div className='row'>
                <div className='col widget-sidebar-search-wrap pt-2 '>
                  <Col className={`${isCroydon ? 'container-1' : 'container'}`}>
                    <div className=' d-flex justify-content-between align-items-baseline'></div>
                    <div className='row '>
                      <div className='container px-0'>
                        <div className='w-100 mb-0 row mx-auto justify-content-between text-center text-md-left align-items-center mb-2 '>
                          {searchedProperties?.length > 0 && map ? (
                            <label className='d-flex align-items-center pt-2 px-3 justify-content-end ml-auto'>
                              <MapToggle setMap={setMap} map={map} />
                            </label>
                          ) : null}
                          {map ? (
                            <div className='col-sm-12'>
                              <form
                                className=' p-0'
                                onSubmit={methods.handleSubmit(onHandleSubmit)}
                              >
                                <Searchbar
                                  loading={loading}
                                  isCroydon={isCroydon}
                                />
                              </form>
                            </div>
                          ) : null}
                          {/* results heading col */}
                          {!isCroydon ? (
                            <div className='py-3 d-flex align-items-center justify-content-between w-100'>
                              <div className='pl-3'>
                                <h5 className='mb-0 font-weight-bolder text-body text-capitalize'>
                                  {/* Search Results */}
                                </h5>
                                <span className='text-body-50 py-2 d-flex'>
                                  <h5>
                                    {searchedProperties?.length > 0
                                      ? 'Results'
                                      : 'Result'}
                                  </h5>
                                </span>
                              </div>
                              <div>
                                <div className='row w-100 mx-0'>
                                  <div className='col-12 mt-3 mt-md-0 col-md-4 d-flex'>
                                    <div className='pl-3'>
                                      <button
                                        type='button'
                                        onClick={() => setCardView('grid')}
                                        className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                                        style={{ height: 40, width: 40 }}
                                      >
                                        <SvgBox
                                          className={
                                            cardView === 'grid' &&
                                            'text-primary'
                                          }
                                          size='40px'
                                        >
                                          <IconGrid />
                                        </SvgBox>
                                      </button>
                                    </div>
                                    <div className='pl-3'>
                                      <button
                                        type='button'
                                        onClick={() => setCardView('list')}
                                        className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                                        style={{ height: 40, width: 40 }}
                                      >
                                        <SvgBox
                                          className={
                                            cardView === 'list' &&
                                            'text-primary'
                                          }
                                          size='40px'
                                        >
                                          <IconList />
                                        </SvgBox>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <section
                    className={`${isCroydon ? 'container-1' : 'container'}`}
                  >
                    {isCroydon ? (
                      <div className='row'>
                        {searchedProperties.map((property) => (
                          <div
                            key={property._id}
                            className={`col-md-${map ? '6' : '3'} mb-4`}
                          >
                            <CroydonCard isSearch item={property} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='row'>
                        {cardView === 'list' ? (
                          <>
                            {loading || isLoading ? (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <ListCardLoader />
                                  </Col>
                                  <Col md={6}>
                                    <ListCardLoader />
                                  </Col>
                                </Row>
                              </Col>
                            ) : (
                              <>
                                {searchedProperties.length > 0 ? (
                                  <>
                                    {searchedProperties.map((item, i) => (
                                      <Card
                                        key={item._id}
                                        className={`col-12 mobile-card ${
                                          map
                                            ? 'col-xl-12'
                                            : 'col-lg-9 col-xl-6'
                                        }`}
                                        mobileClassName='mobile-items-show w-100 bg-gray'
                                        boxClassName='row mx-0'
                                        colOneClassName='col-6 col-md px-0'
                                        colTwoClassName='col-6 col-md-7 px-3 px-md-4 d-flex flex-column'
                                        imageClassName='card-item-img'
                                        userClassName='d-none'
                                        mobileUserClassName='d-none'
                                        item={item}
                                        index={i}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className='section-title w-100 text-center py-5 mt-5'>
                                      <div
                                        className='mx-auto d-flex flex-column'
                                        style={{ width: '70vmin' }}
                                      >
                                        <h5 className='title'>
                                          Results not found...
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {isLoading || loading ? (
                              <>
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                                <FeaturedLoader map={map} />
                              </>
                            ) : (
                              <>
                                {searchedProperties?.length > 0 ? (
                                  <>
                                    {searchedProperties.map((item, i) => (
                                      <Card
                                        key={item._id}
                                        className={`col-12 col-md-6  ${
                                          map
                                            ? 'col-xl-6 col-lg-6'
                                            : 'col-xl-3 col-lg-4'
                                        }`}
                                        item={item}
                                        index={i}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className='section-title w-100 text-center py-5 mt-5'>
                                      <div
                                        className='mx-auto d-flex flex-column'
                                        style={{ width: '70vmin' }}
                                      >
                                        <h5 className='title'>
                                          Results not found...
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {/* <div className='text-center'>
                      <div className='font-weight-normal h5'>
                        Showing {searchedProperties?.length} of{' '}
                        {searchedProperties?.length} Properties
                      </div>
                      <div className='mt-2 mb-3 px-0 col-10 col-md-5 mx-auto'>
                        <Progress
                          value={
                            (searchedProperties?.length /
                              searchedProperties?.length) *
                            100
                          }
                        />
                      </div>
                    </div> */}
                  </section>
                </div>
                <div
                  className={`col-12 col-lg-6 pl-xl-0 map-overlay ${
                    map ? 'd-block' : 'd-none'
                  }`}
                >
                  <div className='map-overlay__button text-right mb-4 mx-3'>
                    <button
                      type='button'
                      className='btn btn-info text-primary p-0 rounded-circle'
                      onClick={() => setMap(false)}
                    >
                      <h3 className='mb-0'>&times;</h3>
                    </button>
                  </div>
                  {searchedProperties?.length > 0 && map ? (
                    <div className='bg-light position-sticky top-50'>
                      <ClusterMap
                        markers={searchedProperties}
                        isCroydon={isCroydon}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* {ads.length > 0 ? (
                <div className='row d-flex justfiy-content-end pb-4'>
                  <div className='col-md-12'>
                    <Ads ads={ads} />
                  </div>
                </div>
              ) : null} */}
            </section>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default SearchPropertiesSection;
