import React from 'react';
import { Link } from 'react-router-dom';

const PublishWaitingMsg = () => {
  return (
    <div className='row mt-5 d-flex justify-content-center align-items-center'>
      <div className='col-md-6 mt-5'>
        <div className='section-title text-center mt-5'>
          <h2 className='title'>Almost there ...</h2>
          <p>
            Your Property will be uploaded on our portal after approved by
            admin.
          </p>
          <p> Thank you! </p>
          <p> Real Estate Properties Team</p>
          <Link className='btn btn-primary' to='/'>
            {' '}
            Go Home{' '}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PublishWaitingMsg;
