import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import notifications from '../../notifications';

export const ConfirmDeleteModal = ({
  setShowReqModal,
  showReqModal,
  itemId,
}) => {
  const queryClient = useQueryClient();

  const handleDeleteProperty = async () => {
    try {
      const res = await axios.delete(`/property/${itemId}`);
      return res;
    } catch (err) {
      throw err.response.data;
    }
  };

  const mutation = useMutation(handleDeleteProperty, {
    onSuccess: () => {
      setShowReqModal(false);
      notifications.success('Success', 'Deleted Successfully');
      queryClient.invalidateQueries('userProperties');
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  return (
    <>
      <Modal
        isOpen={showReqModal}
        toggle={() => setShowReqModal(!showReqModal)}
        centered
      >
        <ModalHeader toggle={() => setShowReqModal(!showReqModal)}>
          Delete Property
        </ModalHeader>
        <ModalBody>
          <h5 className='text-uppercase text-center py-3'>
            Are you sure you want to delete this property?
          </h5>
        </ModalBody>
        <ModalFooter>
          <button
            class='btn btn-light text-uppercase font-weight-bold'
            onClick={() => setShowReqModal(false)}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              mutation.mutate({ id: itemId });
            }}
            disabled={mutation.isLoading}
            className={`btn btn-danger font-weight-bold text-uppercase`}
          >
            {mutation.isLoading && <Spinner className='mr-2' size='sm' />}
            Delete Property
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
