import React from 'react';
import { useHistory } from 'react-router-dom';

const Verify = () => {
  const history = useHistory();

  const goHomeHandler = () => {
    history.push('/');
  };

  return (
    <div
      style={{
        height: '90vh',
      }}
      className='row d-flex  justify-content-center flex-fill align-items-center '
    >
      <div className='col-md-6 '>
        <div className='section-title text-center'>
          <img
            height='200px'
            src={`${process.env.PUBLIC_URL}/assets/img/success.png`}
            alt=''
          />
          <h3 className='text-secondary text-uppercase my-3'>Success</h3>
          <div className='text-muted '>
            You email has been verified successfully!
          </div>
          <div className='my-2'>
            You can now use the start using your account.
          </div>
          <div className='mt-1'>
            <button
              className='btn btn-light font-weight-bold text-uppercase mt-2'
              type='submit'
              onClick={goHomeHandler}
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
