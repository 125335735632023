import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Navbar from '../components/global-components/navbar';
import { SvgBox } from '../images/icons/SvgBox';
import { DashboardCardLoader } from '../components/shimmers/dashboardCards';
import DashboardActivity from '../components/Activity/dashboardActivity';

import { ReactComponent as HouseIcon } from '../images/icons/house.svg';
import { ReactComponent as HeartIcon } from '../images/icons/heart.svg';
import { ReactComponent as MailIcon } from '../images/icons/mail.svg';
import { ReactComponent as EyeIcon } from '../images/icons/eye.svg';
import { useGetProfile } from '../hooks/useGetProfile';

const getUserDashboard = async () => {
  try {
    const res = await axios.get('/profile/dashboard/count');
    return res.data;
  } catch (err) {
    throw err.response;
  }
};
const MyProfile = () => {
  const { data: profile } = useGetProfile();
  const { data, isLoading } = useQuery('get-user-dashboard', getUserDashboard);

  const dashboardData = [
    {
      icon: <HouseIcon />,
      number: isLoading ? '' : data?.propertiesCount,
      url: `/my-properties?state=${'active'}`,
      title: 'Total Properties',
    },
    {
      icon: <EyeIcon />,
      number: isLoading ? '' : data?.activeCount,
      url: `/my-properties?state=${'active'}`,
      title: 'Active Properties',
    },
    {
      icon: <MailIcon />,
      number: isLoading ? '' : data?.inActiveCount,
      url: `/my-properties?state=${'inActive'}`,
      title: 'In Active Properties',
    },
    {
      icon: <HeartIcon />,
      number: isLoading ? '' : data?.requestsCount,
      url: '/my-properties?state=active',
      title: 'Total Requests',
    },
  ];

  return (
    <section className='vh-100 d-flex flex-column'>
      <Navbar />
      <Container>
        <Row className='flex-fill mx-0'>
          <Col md={6} className='bg-white'>
            <Container className='mt-5 pt-3'>
              <div>
                <div className='d-flex justify-content-between mb-4'>
                  <div>
                    <h2 className='text-capitalize'>
                      Welcome Back, {profile?.name}
                    </h2>
                  </div>
                </div>
                <div className='row row-cols-1 row-cols-md-2 row-cols-xl-12'>
                  {isLoading ? (
                    <>
                      <DashboardCardLoader />
                      <DashboardCardLoader />
                      <DashboardCardLoader />
                      <DashboardCardLoader />
                    </>
                  ) : (
                    <>
                      {dashboardData.map(item => (
                        <Link key={item.title} className='p-3' to={item.url}>
                          <div className='bg-white shadow-sm rounded-lg p-3 row mx-0'>
                            <div className='icon-box bg-icon mr-4'>
                              <SvgBox size='25px' className='text-primary'>
                                {item.icon}
                              </SvgBox>
                            </div>
                            <div className='mb-0 col px-0 mt-2 text-capitalize'>
                              <h3 className='mb-0 text-primary'>
                                {item.number}
                              </h3>
                              <h6 className='text-body mt-2 font-weight-bold'>
                                {item.title}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Container>
          </Col>
          <Col md={6}>
            <div className='bg-white mt-5 h-100'>
              <DashboardActivity />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MyProfile;
