import React from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as HomeIcon } from '../../../images/icons/home.svg';
import { ReactComponent as HouseIcon } from '../../../images/icons/house.svg';
import { ReactComponent as HeartIcon } from '../../../images/icons/heartbeat.svg';
import { ReactComponent as CapIcon } from '../../../images/icons/graduate-cap.svg';

export const MarkerComponent = ({ type }) => {
  return (
    <div
      className='position-absolute d-flex align-items-center justify-content-center m-0 p-0'
      style={{ borderRadius: '8px' }}
    >
      {type === 'prop' ? (
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ zIndex: 1 }}
        >
          <div
            style={{
              borderRadius: 10,
            }}
            className=' bg-primary shadow mr-3 p-2'
          >
            <SvgBox size='20px' className='text-white'>
              <HomeIcon />
            </SvgBox>
          </div>
        </div>
      ) : (
        <div className='d-flex align-items-center justify-content-center p-1'>
          <SvgBox size='30px' className='bg-primary  text-white rounded p-1'>
            {type === 'school' ? (
              <CapIcon />
            ) : type === 'hotel' ? (
              <HouseIcon />
            ) : type === 'hospital' ? (
              <HeartIcon />
            ) : null}
          </SvgBox>
        </div>
      )}
    </div>
  );
};
