import React from 'react';
import Navbar from '../components/global-components/navbar';
import SearchPropertiesSection from '../components/properties-components/search/search-properties';
import Footer from '../components/global-components/footer';

const SearchProperties = () => {
  return (
    <section className='d-flex flex-column vh-100'>
      <Navbar />
      <SearchPropertiesSection />
      <Footer />
    </section>
  );
};

export default SearchProperties;
