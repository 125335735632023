import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sectiondata from '../../data/sections.json';

const AboutUs = () => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  let imagealt = 'image';
  let data = sectiondata.aboutus;
  const { user } = useSelector((state) => state);

  return (
    <div className='about-area pb-5 mt-5'>
      <div className='container'>
        <div className='section-title text-center pt-4'>
          <h2 className='text-capitalize'>Our Mission Is To moveappy</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <div className='row'>
          <div className='col-lg-6 mb-4 mb-lg-0'>
            <div className='shape-image-list-wrap'>
              <div className='shape-image-list left-top'>
                <img
                  className='shadow-img h-100 w-100'
                  src={publicUrl + data.image}
                  alt={imagealt}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 align-self-center'>
            <div className='section-title pl-5 mb-0 mt-4 mt-lg-0'>
              <h5 className='sub-title'>{data.subtitle}</h5>
              <h2 className='title'>{data.title}</h2>
              <p>{data.content}</p>
              <Link to={`/register?type=${'free'}`} className='btn btn-primary'>
                {user?.isAuthenticated ? 'Home' : 'Register Now'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
