import React from 'react';

import Banner from '../components/properties-components/home/banner';
import Service from '../components/properties-components/home/service';
//import Explore from '../components/properties-components/home/explore';
import FeaturedProperties from '../components/properties-components/home/featured-properties';
import RecentProperties from '../components/properties-components/home/recent-properties';
import Ads from '../components/section-components/ads';
//import PropertiesByCities from '../components/properties-components/propertiesByCitiesCount/properties-by-cities';
import IntroCards from '../components/properties-components/home/intro-cards';
//import FeaturedPorject from '../components/section-components/featured-project';
import WhyChooseUs from '../components/section-components/why-choose-us';
import OurPartner from '../components/section-components/our-partner';
import Footer from '../components/global-components/footer';
import { Trends } from '../components/properties-components/home/Trends';
import ExploreCities from '../components/properties-components/home/ExploreCities';
import { ExploreOurProperties } from '../components/properties-components/home/ExploreOurProperties';
import ListingsHome from '../components/properties-components/home/ListingsHome';
import RegionalPartners from '../components/properties-components/home/RegionalPartners';
import Faqs from '../components/properties-components/home/Faqs';

const Home = () => {
  return (
    <section>
      <Banner />
      <RegionalPartners />
      <ExploreCities />
      {/* <Faqs /> */}
      {/* <ListingsHome /> */}
      {/* <FeaturedProperties /> */}
      {/* <ExploreOurProperties />
      <Trends />

      <Service /> */}

      {/* <PropertiesByCities /> */}
      <IntroCards />
      <RecentProperties />
      {/* <WhyChooseUs /> */}
      {/* <FeaturedPorject /> */}
      {/* <OurPartner /> */}
      {/* <Ads /> */}
      <Footer />
    </section>
  );
};

export default Home;
