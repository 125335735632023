import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Spinner } from 'reactstrap';

export const Save = props => {
  const { submitCountt, setSubmitCountt, loading } = props;

  let { formState } = useFormContext();
  if (formState.submitCount > submitCountt) {
    setSubmitCountt(formState.submitCount);
  }

  return (
    <div>
      <button
        className='btn btn-secondary py-5 w-100 font-weight-bold text-uppercase'
        type='submit'
        disabled={loading}
      >
        {loading && <Spinner className='mr-2' size='sm' />}
        Save
      </button>
    </div>
  );
};
