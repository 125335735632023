import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';

export const CroydonFloorPlanes = ({ property }) => {
  const [toggleOne, setToggleOne] = useState(false);

  const { floorImages } = property;

  return (
    <div>
      <div className='px-3 px-lg-4'>
        <h5 className='mb-0 title-font font-weight-bold text-uppercase ls-3'>
          FLOORPLANS
        </h5>
        <div className='row'>
          {floorImages.map((image, idx) => (
            <div key={image} className='col-md-6 col-sm-12'>
              <div className='mt-4'>
                <div
                  className='d-flex align-items-center cursor-pointer btn-3 text-black-1 p-3 col-12 col-md-5 col-lg-5 col-xl-3'
                  onClick={() => setToggleOne(!toggleOne)}
                >
                  <div className='mb-0 title-font text-uppercase h5 ml-0 mr-auto'>
                    FLOOR PLAN {idx + 1}
                  </div>
                  <SvgBox rotate={!toggleOne ? 90 : -90} size='20px'>
                    <ArrowIcon />
                  </SvgBox>
                </div>
              </div>
              <Collapse isOpen={toggleOne}>
                <div className='mt-4'>
                  <img className='d-block h-100 w-100' src={image} alt='...' />
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
