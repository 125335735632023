import React, { useState } from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
import { truncateString } from '../../../utils/truncateString';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';
import CroydonDetails from './CroydonDetails';

function getMiles(i) {
  return i * 0.000621371192;
}

export const CroydonDescription = ({ property }) => {
  const {
    title,
    description,
    distanceFromEastCroydon,
    distanceFromWestCroydon,
    location,
  } = property;

  const [readmore, setReadMore] = useState(false);
  return (
    <div>
      <div className='row'>
        <div className='col'>
          <div>
            <div className='pl-4 py-4'>
              <div className='d-flex flex-wrap'>
                <div className='mr-auto text-blue'>
                  <div className='display-4 title-font font-weight-bold mb-2 text-uppercase'>
                    {title}
                  </div>
                  <div className='h5 ls-3 title-font mb-2 text-uppercase text-black-1'>
                    {truncateString(location, 205)}
                  </div>
                </div>
              </div>

              <div className='mt-2 text-muted'>
                {!readmore ? (
                  <div className='row'>
                    <div
                      className='col'
                      dangerouslySetInnerHTML={{
                        __html: truncateString(description, 300),
                      }}
                    />
                  </div>
                ) : null}

                {readmore && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                    className={`mt-3 read-more ${readmore ? 'show' : 'hide'}`}
                  />
                )}
              </div>

              {/* {!readmore ? (
                ''
              ) : (
                <div className='d-flex align-items-center flex-wrap'>
                  <div style={{ height: 35 }}>
                    <img
                      className='h-100 w-100 d-block'
                      src='/assets/croydon-images/walk.svg'
                      alt='...'
                    />
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='text-body text-uppercase h5 mb-0 title-font font-weight-bold'>
                      EAST CROYDON:{' '}
                      {getMiles(distanceFromEastCroydon).toFixed(2)} m
                    </div>
                    <span
                      className='d-block border-blue mx-3'
                      style={{ borderRight: '3px solid' }}
                    />
                    <div className='text-body text-uppercase h5 mb-0 title-font font-weight-bold'>
                      WEST CROYDON:{' '}
                      {getMiles(distanceFromWestCroydon).toFixed(2)} m
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div
            className='d-flex col-12 flex-column align-items-center cursor-pointer mb-4 mb-xl-0'
            onClick={() => setReadMore(!readmore)}
          >
            <h5 className='mb-0 title-font font-weight-bold text-uppercase ls-3'>
              {!readmore ? 'View More' : 'View Less'}
            </h5>
            <SvgBox rotate={!readmore ? 90 : -90} size='20px'>
              <ArrowIcon />
            </SvgBox>
          </div>
        </div>
        <div className='col-12 col-xl-6'>
          <CroydonDetails
            details={property}
            style={{ height: `${readmore ? '100%' : '320px'}` }}
            boxClassName={`overflow-hidden transition read-more ${
              readmore ? 'show' : ''
            }`}
          />
        </div>
      </div>
    </div>
  );
};
