import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Col, Container, Row } from 'reactstrap';
import Navbar from '../components/global-components/navbar';
import { PropertyList } from '../components/properties-components/userPropAndRequests/propertyList';
import { Error } from '../utils/errorComponent';
import { Loader } from '../components/Loader';

function useQueryParams() {
  return new URLSearchParams(window.location.search);
}

const getUserProperties = async () => {
  try {
    const res = await axios.get('/property/user/all');
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};
const UserProperties = () => {
  const queryData = useQueryParams();

  const { data, isLoading, error } = useQuery(
    'userProperties',
    getUserProperties,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }
  return (
    <section className='vh-100 d-flex flex-column'>
      <Navbar />
      <Row className='flex-fill mx-0'>
        <Col>
          <Container className='mt-5 pt-3'>
            <PropertyList
              activeProperties={data.activeProperties}
              queryData={queryData}
              inActiveProperties={data.inActiveProperties}
            />
          </Container>
        </Col>
      </Row>
    </section>
  );
};

export default UserProperties;
