import React from 'react';

import moment from 'moment';

import { FormatPrice } from '../../common/FormatPrice';

export const PropertyHeader = ({ details }) => {
  const { title, createdAt, location } = details || {};

  return (
    <div>
      <div className='my-1 row mx-0 my-2'>
        <div>
          <div className='text-capitalize my-0 d-flex align-items-center'>
            <span className='h2 d-none d-lg-block font-weight-bold mb-0'>
              {title}
            </span>
            <span className='h3 d-lg-none font-weight-bold mb-0'>{title}</span>
          </div>
          <div className='d-none d-md-block'>
            <small className='text-secondary text-capitalize font-weight-bold'>
              <span className='text-yellow'>
                Added On: {moment.utc(createdAt).format('MMM DD, YYYY')}
              </span>
            </small>
          </div>
          <div className='text-secondary my-2 d-none d-md-block'>
            <span>{location}</span>
          </div>
        </div>
        <div className='ml-auto d-none d-lg-block'>
          <span className=' mb-0 h4 font-weight-bold'>
            <FormatPrice item={details} />
          </span>
        </div>
      </div>
    </div>
  );
};
