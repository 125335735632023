import React from 'react';
import { Verify } from '../../components/auth-components/verify';
import { useRedirect } from '../../hooks/useRedirect';

const Verification = () => {
    useRedirect();
    return <div>
        <Verify />
    </div>
}

export default Verification

