import axios from 'axios';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row, Spinner } from 'reactstrap';

import { usePlaceHolder } from '../../../../utils/usePlaceholder';
import { ReactComponent as FloorImage } from '../../../../images/icons/floor-plan.svg';
import { SvgBox } from '../../../../images/icons/SvgBox';

const RemainingItem = ({ onChange }) => {
  const { inputFile, onImageClick } = usePlaceHolder();
  return (
    <>
      <span
        onClick={onImageClick}
        className='badge badge-dark cursor-pointer p-2'
      >
        +
      </span>
      <input
        type='file'
        ref={inputFile}
        style={{ display: 'none' }}
        name='file'
        onChange={e => onChange(e)}
      />
    </>
  );
};

export const DocumentUploader = ({ name, title, uploads }) => {
  const [loading, setLoading] = useState(false);
  const { watch, setValue } = useFormContext();

  const document = watch(name);

  const documentLength = (document && document.length) || 0;

  const remaining = new Array(2 - documentLength).fill(1);

  const onChange = e => {
    const [ecpFile] = e.target.files;
    if (ecpFile) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append('images', ecpFile);
      axios
        .post('/image', formdata)
        .then(res => {
          const previousDocs = watch(name);
          setLoading(false);
          setValue(
            name,
            !previousDocs
              ? [res.data.urls[0]]
              : [res.data.urls[0], ...previousDocs]
          );
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };

  const removeDocuments = currentImage => {
    const updatedDocuments = document.filter(image => image !== currentImage);
    setValue(name, updatedDocuments);
  };

  return (
    <section>
      <Col>
        {loading ? (
          <div className='d-flex justify-content-center my-5'>
            <Spinner />
          </div>
        ) : (
          <Row className='row-cols-1 mb-5'>
            {document?.length
              ? document?.map((image, index) => (
                  <Col key={`${title}-index-${index}`} className='px-2 mb-3'>
                    <div className='d-flex align-items-center py-4 border-bottom justify-content-between w-100'>
                      <div className='d-flex align-items-center'>
                        <SvgBox size='30px' className='text-muted'>
                          <FloorImage />
                        </SvgBox>
                        <div className='mx-4'>
                          <h4 className=' mb-2'>
                            {index + 1}. {title}{' '}
                          </h4>
                          <span className='text-muted'>{image}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          onClick={() => removeDocuments(image)}
                          className='badge badge-danger cursor-pointer p-2'
                        >
                          Remove{' '}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              : null}
            {remaining.map((r, index) => (
              <Col key={`${r}-${index}`} className='px-2 mb-3'>
                <div className='d-flex align-items-center cursor-pointer py-4 border-bottom justify-content-between w-100'>
                  <div className='d-flex align-items-center'>
                    <SvgBox size='30px' className='text-muted'>
                      <FloorImage />
                    </SvgBox>
                    <div className='mx-4'>
                      <h4 className=' mb-2 text-capitalize'>
                        {index + 1}. {title}{' '}
                      </h4>
                      <span className='text-muted'>
                        Tap to upload a file...
                      </span>
                    </div>
                  </div>
                  <div>
                    <RemainingItem onChange={onChange} />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Col>
    </section>
  );
};
