import React from 'react';

const CustomAccordion = ({ title, size, rooms, baths, price, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <div className='accordion-wrapper'>
            <div
                className={`accordion-title ${isOpen ? 'open' : ''}`}
                onClick={() => setOpen(!isOpen)}
            >
                <div className='font-weight-bold text-capitalize mr-auto'>{title}</div>
                <div className='small'>
                    <ul className='nav'>
                        {/* <li className='px-3'>
                            <div className='d-none d-md-flex align-items-center'>
                                <div className='font-weight-bold text-capitalize mr-2'>
                                    Size:
                </div>
                                <div>{size}</div>
                            </div>
                        </li>
                        <li className='px-3'>
                            <div className='d-none d-md-flex align-items-center'>
                                <div className='font-weight-bold text-capitalize mr-2'>
                                    rooms:
                </div>
                                <div>{rooms}</div>
                            </div>
                        </li>
                        <li className='px-3'>
                            <div className='d-none d-md-flex align-items-center'>
                                <div className='font-weight-bold text-capitalize mr-2'>
                                    baths:
                </div>
                                <div>{baths}</div>
                            </div>
                        </li>
                        <li className='px-3'>
                            <div className='d-flex align-items-center'>
                                <div className='font-weight-bold text-capitalize mr-2'>
                                    price:
                </div>
                                <div>{price}</div>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
                <div className='accordion-content'>{children}</div>
            </div>
        </div>
    );
};

export default CustomAccordion;
