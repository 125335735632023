import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sectiondata from '../../../data/sections.json';

const IntroCards = () => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  let imagealt = 'image';
  let data = sectiondata.propertiesbycities;

  const { user } = useSelector(state => state);

  if (user?.isAuthenticated) {
    return null;
  }

  const inlineStyle = {
    backgroundImage: 'url(' + publicUrl + '/assets/img/bg/2.png)',
  };

  return (
    <div
      className='city-intro-area pd-bottom-70 pd-top-100 d-none'
      style={inlineStyle}
    >
      {/* intro area start */}
      <div className='intro-area'>
        <div className='container'>
          <div className='row'>
            {data?.intro?.map((item, i) => (
              <div key={i} className='col-md col-sm-6 mt-4 mt-sm-0'>
                <Link
                  to={item.url}
                  className={
                    'd-flex flex-column  flex-lg-row  align-items-center text-center align-items-lg-start text-lg-left h-100 ' +
                    item.class
                  }
                >
                  <div className='media-left'>
                    <img
                      height='40px'
                      width='40px'
                      src={publicUrl + item.icon}
                      alt={imagealt}
                    />
                  </div>
                  <div
                    className='media-body d-flex flex-column '
                    style={{ height: 165 }}
                  >
                    <div className='intro-cards-heading' style={{}}>
                      <h4>{item.title}</h4>
                    </div>
                    <p style={{ fontSize: '14px' }}>{item.content}</p>
                    <div className='intro-cards-heading mt-auto'>
                      <Link
                        className={`h5 text-white ${item.className}`}
                        to={item.url}
                      >
                        REGISTER NOW
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* intro area end */}
    </div>
  );
};

export default IntroCards;
