import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';
import notifications from '../../notifications';

export const ReqModal = ({
  setShowReqModal,
  showReqModal,
  propertyTitle,
  requestId,
  requestorName,
}) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const queryClient = useQueryClient();

  const responseToRequestHandler = (data) => {
    return axios
      .post(`/property/request/${requestId}`, data)
      .then((res) => res.data)
      .catch((err) => {
        throw err.response.data;
      });
  };

  const mutation = useMutation(responseToRequestHandler, {
    onSuccess: () => {
      reset();
      setShowReqModal(false);
      notifications.success('Success', 'Replied Successfully');
      // queryClient.invalidateQueries('propertiesByCityCount');
      queryClient.invalidateQueries([`requests-${requestId}`, requestId]);
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  const onSubmit = (data) => {
    const newData = {
      response: data.response,
      propertyTitle,
    };
    mutation.mutate(newData);
  };

  return (
    <>
      <Modal
        isOpen={showReqModal}
        toggle={() => setShowReqModal(!showReqModal)}
      >
        <form className='contact-form-bg' onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => setShowReqModal(!showReqModal)}>
            Response To <span className='text-capitalize'>{requestorName}</span>
          </ModalHeader>
          <ModalBody>
            <div className='rld-single-input mt-3'>
              <textarea
                rows={5}
                type='text'
                name='response'
                placeholder='Reply To Request...'
                ref={register({ required: true })}
              />
              {errors.response && (
                <small className='font-weight-bold text-danger'>
                  This field is required
                </small>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='btn-wrap'>
              <button
                class='btn yellow-btn btn-light mr-2'
                onClick={() => setShowReqModal(false)}
              >
                Cancel
              </button>
              <button
                type='submit'
                disabled={mutation.isLoading}
                className={`btn btn-primary ${
                  mutation.isLoading ? 'button-disabled' : null
                }`}
              >
                {mutation.isLoading ? (
                  <div
                    class='spinner-border spinner-border-sm text-light mr-2'
                    role='status'
                  >
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : null}
                Send Response
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
