import React from 'react';
import CroydonNavbar from '../../components/global-components/CroydonNavbar';
import CroydonSearch from '../../components/global-components/CroydonSearch';
import { useAgencyProfile } from '../../hooks/useAgencyProfile';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router';
import { CroydonPartners } from './components/CroydonPartners';
import { CroydonFeaturedListing } from './components/CroydonFeaturedListing';
import { CroydonOffices } from './components/CroydonOffices';

const CroydonHome = () => {
  const { id } = useParams();

  const { isLoading: isLoadingProfile, data } = useAgencyProfile({ id });

  if (isLoadingProfile) {
    return <Loader />;
  }

  return (
    <main className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r text-body'>
      <CroydonNavbar data={data} />
      <main className='d-flex flex-column flex-fill p-r bg-light'>
        <section className='flex-fill pt-5 pos-r'>
          {/* <div
            className='bg-image-cover pos-1 position-absolute'
            style={{
              zIndex: -200,
              backgroundImage: `url(/assets/croydon-images/HERO_TOP_Skyline-at-Night.jpg)`,
            }}
          />
          <div
            className='bg-image-cover pos-2 position-absolute'
            style={{
              backgroundImage: `url(/assets/croydon-images/HERO-BOTTOM.jpeg)`,
            }}
          /> */}
          <div className='container-2 pt-3 pb-5'>
            <div className='row mx-0'>
              <div className='col px-0 mt-4'>
                <div className='px-xl-5'>
                  <CroydonSearch />
                </div>
                {/* <div className='bg-black-1 row mx-0 py-5 px-3'>
                  <div className='col'>
                    <div>
                      <p>
                        Croydon Commerical is an end-to-end platform connecting
                        property owners, agents, developers, buyers, renters and
                        investors.
                      </p>
                      <p>
                        As aut fugia debis sundaero eaquasp elecume nihillupta
                        core pos sus doluptati qui archiliquam, sinum aut qui
                        nonet assimaximi, sedi quis quasimi nctempo restior
                        essincta ditaquaepe quunt mincto volorerumet, quia aut
                        assum ligenis eos non rent volecernam ut fuga. Uptat
                      </p>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <p>
                      Otatus, corit, quiaecum re, sa pres endandite odisquis
                      eaturion et offictatiora sumet omni dem re, aut ma ea
                      iumquam qui venihit elisquae venimai onsectatatur
                      sedigendiate voloreiundam venecea por sitaspi ciisque
                      dolores temodior rem
                    </p>
                    <p>
                      Idem fugit aut rehent accuscid ut reium si ommo cum, arum,
                      officim odignatur arupta niae samus verspie niment, tecte
                      que corit ditas aci consequ untiur resedigent, sinverro
                      eos estrum denientur?
                    </p>
                  </div>
                </div> */}
              </div>
              <div className='col-12 col-lg-6 col-xl-6 px-0'>
                <div className='bg-blue p-3 p-md-5 p-lg-3 p-xl-5'>
                  <h2 className='pb-4 text-white'>FEATURED LISTINGS</h2>
                  <CroydonFeaturedListing id={data?._id} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {/* <div className='pt-5 mt-lg-5 px-4 pos-r'>
            <h1 className='mb-0 ls-4 text-uppercase'>CROYDON’S</h1>
            <div className='title-big'>NEW OFFICE DEVELOPMENTS</div>
            <div className='mt-4 pt-2 ml-md-2'>
              <Link t className='btn-4 btn-text py-3 px-4 font-weight-bold title-font text-uppercase'>
                View all
              </Link>
            </div>
          </div> */}
          <div className='row mx-0 mt-xl-n5'>
            {/* <div className='col px-0'>
              <div className='h-100 bg-black-1 text-white px-4 px-lg-5 py-5'>
                <div className='heading-title'>
                  Commercial Listings. Simplified.
                </div>
                <div className='row'>
                  <div className='col'>
                    <div>
                      <div className='text-small mt-4'>
                        Whether selling, letting, or developing, Croydon
                        Commercial allows you to take control and share and
                        monitor the interest in your properties.
                      </div>
                      <div className='text-small mt-4'>
                        Upload, manage and update all listings through our
                        hassle-free, tailored, 100% digital platform.
                      </div>
                      <div className='title-font h1 mb-0 mt-4'>
                        “ Commercial Croydon’s team brought our building to
                        life. The digital walkthrough delivered a noticeable
                        surge in interest for our commercial office space, which
                        we can easily monitor through our dashboard. ”
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6 '>
                    <div className='mt-4'>
                      <h1 className='mb-1 text-uppercase title-font'>
                        ON THE UP
                      </h1>
                      <p className='mb-0'>
                        Our easy-to-use platform allows you to upload, update
                        and upsell your properties through our feature-rich,
                        intuitive user dashboard. On top of this, our in-site
                        communication channels allow direct contact between all
                        parties.
                      </p>
                    </div>
                    <div className='mt-4'>
                      <h1 className='mb-1 text-uppercase title-font'>
                        TECHNOLOGY FIRST
                      </h1>
                      <p className='mb-0'>
                        Cutting edge visualisation eliminates guess-work and
                        allows fully immersive, unedited and 3D interactive
                        property tours, building trust and increased engagement.
                      </p>
                    </div>
                    <div className='mt-4'>
                      <h1 className='mb-1 text-uppercase title-font'>
                        BUILDING FOR THE FUTURE
                      </h1>
                      <p className='mb-0'>
                        In a competitive marketplace, with advance sales and
                        lettings crucial for your top line, Croydon Commercial
                        can provide advice and deliver detailed computer
                        generated (CGI) walkthroughs, video, drone footage or
                        stills for better-informed off-plan purchasers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='col px-0 mt-xl-n5'>
              <div className='bg-black-1 w-100 h-100 p-3 p-md-5 p-lg-3 p-xl-5'>
                <div className='position-sticky' style={{ top: 20 }}>
                  <h2 className='pb-4 text-white text-uppercase'>
                    {' '}
                    Commercial Listings. Simplified.
                  </h2>

                  <CroydonOffices id={data?._id} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-5 px-4 px-xl-5'>
          <div className='mb-4'>
            <h2 className='title-font ls-3 mb-1 text-black text-uppercase'>
              CROYDON'S
            </h2>
            <h1 className='display-4 mb-0 title-font letter-spacing-small text-blue font-weight-bold text-uppercase'>
              NEW OFFICE DEVELOPMENTS
            </h1>
          </div>

          <CroydonFeaturedListing
            id={data?._id}
            row={true}
            className=' row row-cols-1 row-cols-sm-2 row-cols-lg-4'
            noHeight={true}
          />
        </section>
        {/* <CroydonPartners id={data._id} /> */}
        <section className='croydon-footer bg-white py-4'>
          <div className='d-flex flex-wrap'>
            <div
              style={{
                height: 100,
              }}
              className='partners-item  m-0 px-3'
            >
              <img
                className='h-100 w-100 d-block'
                src='https://headsabovethecloud.co.uk/images/logo.png'
                alt='..'
              />
            </div>
          </div>
          <div className='mt-5 text-black text-center'>
            <span>© 2023 Croydon Commercial. All rights reserved.</span>
          </div>
        </section>
      </main>
    </main>
  );
};

export default CroydonHome;
