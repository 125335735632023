import React from 'react';

export const CroydonFeatures = ({ property }) => {
  const { features } = property;
  return (
    <div className='mt-4 pl-4 pl-lg-0'>
      <div className='p-3 p-lg-4'>
        <h5 className='mb-2 title-font font-weight-bold text-uppercase ls-3'>
          FEATURES
        </h5>
        <div className='row'>
          {features?.map((item) => (
            <div
              key={item._id}
              className='col-6 col-md-4 col-lg-6 col-xl-4 mb-3'
            >
              <div className='d-flex align-items-center'>
                <div className='icon-box-1'>
                  <img
                    alt={item.label}
                    className='d-block h-100 w-100 filter-invert'
                    src={item.icon}
                  />
                </div>
                <h6 className='ml-2 mb-0 title-font text-uppercase font-weight-bold'>
                  {item.label}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
