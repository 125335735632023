import React from 'react';
import { PostAd } from '../components/service-provider/postAd';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';

const PostAds = () => {
  return (
    <>
      <Navbar />
      <PostAd />
      <Footer />
    </>
  );
};

export default PostAds;
