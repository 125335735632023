import React from 'react';
import { ForgotPasswordd } from '../../components/auth-components/forgotPass';
import { useRedirect } from '../../hooks/useRedirect';

const ForgotPassword = () => {
  useRedirect();

  return (
    <div>
      <ForgotPasswordd />
    </div>
  );
};

export default ForgotPassword;
