import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ListCardLoader = () => {
  return (
    <div className=' shadow-sm border-0 text-left card rounded-xl p-3 mb-3 w-100'>
      <Skeleton height={200} />
    </div>
  );
};
