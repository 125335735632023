import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Tooltip } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { numFormatter } from '../../utils/numFormatter';
import { ReactComponent as IconPhoto } from '../../images/icons/photo.svg';
import { ReactComponent as IconVideo } from '../../images/icons/video.svg';
import { ReactComponent as IconBed } from '../../images/icons/bed.svg';
import { ReactComponent as IconBath } from '../../images/icons/bath.svg';
import { ReactComponent as IconRuler } from '../../images/icons/ruler.svg';
import { ReactComponent as IconGrid } from '../../images/icons/living-room.svg';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
import { SvgBox } from '../../images/icons/SvgBox';
import notifications from '../notifications';
import { FormatPrice } from '../common/FormatPrice';

const Card = ({
  item,
  className = 'col-xl-3 col-lg-4 col-md-6 col-sm-6',
  boxClassName,
  userClassName = 'd-none d-md-block',
  colOneClassName,
  colTwoClassName,
  imageClassName,
  mobileUserClassName = 'd-md-none',
}) => {
  let publicUrl = process.env.PUBLIC_URL + '/';

  const { register, handleSubmit, errors, reset } = useForm();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContact, setModalContact] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const toggleModal = () => setModal(!modal);

  const toggleModalContact = () => {
    setModal(false);
    setModalContact(!modalContact);
  };

  const onSubmit = (data) => {
    const { email, username, _id } = item?.userId;
    data.receiverEmail = email;
    data.receiverUsername = username;
    data.receiverId = _id;

    setLoading(true);
    axios
      .post('/contactus/email', data)
      .then((res) => {
        setLoading(false);
        reset();
        setModal(false);
        setModalContact(false);
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try again Later');
      });
  };

  return (
    <div className={`h-100 d-flex flex-column ${className}`}>
      <div className={`single-feature flex-fill ${boxClassName}`}>
        <div className={`position-relative ${colOneClassName}`}>
          <div className='position-absolute h-100 w-100 d-flex align-items-baseline  justify-content-between'>
            <span className='featured-overlay-1 text-uppercase d-flex align-items-center text-center'>
              {item?.category?.value}
            </span>
            {/* <span className='featured-overlay-2 text-uppercase d-flex align-items-center text-center'>
              {item?.type?.value}
            </span> */}
          </div>
          <Link className='p-0' to={`/property/${item.slug}`}>
            <LazyLoadImage
              src={
                item.images.length > 0
                  ? item.images[0]
                  : publicUrl + 'assets/img/placeholder.png'
              }
              useIntersectionObserver={true}
              wrapperClassName='w-100 h-100'
              placeholderSrc={publicUrl + 'assets/img/placeholder.png'}
              alt={item?.title}
              className={`${imageClassName}`}
              height='200px'
              width='100%'
              style={{
                objectFit: 'cover',
              }}
            />
          </Link>

          <span>
            <span className='item'>
              <SvgBox className='mr-2 cursor-pointer' size='18px'>
                <IconPhoto />
              </SvgBox>
              <span>{item.images.length}</span>
              <span className='d-flex py-1 mx-2'></span>
              <SvgBox className='mr-2 cursor-pointer' size='18px'>
                <IconVideo />
              </SvgBox>
              <span>{item.floorImages.length}</span>
            </span>
          </span>
        </div>
        {console.log(item?.userId?.imageUr)}
        <div className={`details ${colTwoClassName}`}>
          {item?.userId?.imageUrl !== undefined ? (
            <>
              <Link
                to={`/agency/${item?.userId?.slug}`}
                className={`feature-logo cursor-pointer  ${userClassName}`}
                id={`tooltip${item._id}`}
              >
                <LazyLoadImage
                  src={item?.userId?.imageUrl}
                  placeholderSrc={publicUrl + 'assets/img/placeholder.png'}
                  alt='logo'
                  className='rounded-circle object-fit-contain'
                />
                <Tooltip
                  placement='top'
                  style={{ opacity: 0.9 }}
                  isOpen={tooltipOpen}
                  target={`tooltip${item?._id}`}
                  toggle={toggle}
                >
                  <span style={{ fontSize: '10px' }}>{item?.userId?.name}</span>
                </Tooltip>
              </Link>
              {/* Mobile Start */}
              <div
                onClick={toggleModal}
                className={`feature-logo cursor-pointer ${mobileUserClassName}`}
              >
                <LazyLoadImage
                  src={item?.userId?.imageUrl}
                  placeholderSrc={publicUrl + 'assets/img/placeholder.png'}
                  alt='logo'
                  className='rounded-circle object-fit-contain'
                />
              </div>
              <Modal
                className='mt-auto m-0 slide-in-bottom'
                contentClassName='h-100 modal-rounded-top '
                isOpen={modal}
                toggle={toggleModal}
              >
                <div className='pt-4 pb-3 px-3 d-flex align-items-start'>
                  <div style={{ height: 40, width: 40 }}>
                    <Link to={`/agency/${item?.userId?.slug}`}>
                      <LazyLoadImage
                        src={item?.userId?.imageUrl}
                        placeholderSrc={
                          publicUrl + 'assets/img/user-placeholder.png'
                        }
                        alt='logo'
                        className='w-100'
                      />
                    </Link>
                  </div>
                  <div className='col-6'>
                    <h5 className='mb-0 font-weight-bold'>
                      {item?.userId?.name}
                    </h5>
                    <p className='mb-0 text-truncate'>
                      {' '}
                      {item?.userId?.address}
                    </p>
                  </div>
                  <div className='col pl-0 align-self-start'>
                    <div
                      onClick={toggleModalContact}
                      className='btn btn-block py-2 btn-light text-primary font-weight-bold text-uppercase'
                    >
                      <span style={{ letterSpacing: 0.6 }}>Contact</span>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                className='m-0 h-100vh slide-in-bottom'
                contentClassName='slide-in-bottom'
                isOpen={modalContact}
                toggle={toggleModalContact}
              >
                <div className='h-100 d-flex flex-column'>
                  {/* Header Start */}
                  <div className='d-flex align-items-center border-bottom py-2 px-3 mb-4'>
                    <h4 className='mb-0 text-capitalize'>Contact</h4>
                    <button
                      onClick={toggleModalContact}
                      type='button'
                      className='btn p-0 ml-auto'
                    >
                      <h1 className='mb-0 font-weight-light'>&times;</h1>
                    </button>
                  </div>
                  {/* Header End */}
                  <div className='mt-3 px-3'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='rld-single-input mb-4'>
                        <input
                          type='text'
                          name='senderName'
                          placeholder='Full Name'
                          ref={register({ required: true })}
                        />
                        {errors.senderName && (
                          <small className='font-weight-bold text-danger'>
                            This field is required
                          </small>
                        )}
                      </div>
                      <div className='rld-single-input mb-4'>
                        <input
                          type='text'
                          name='senderEmail'
                          placeholder='Email'
                          ref={register({ required: true })}
                        />
                        {errors.senderEmail && (
                          <small className='font-weight-bold text-danger'>
                            This field is required
                          </small>
                        )}
                      </div>
                      <div className='rld-single-input mb-3'>
                        <textarea
                          type='text'
                          rows={4}
                          placeholder='Write Your Message...'
                          name='message'
                          ref={register({ required: true })}
                        />
                        {errors.message && (
                          <small className='font-weight-bold text-danger'>
                            This field is required
                          </small>
                        )}
                      </div>
                      <div className='mb-3'>
                        <Button
                          color='primary'
                          type='submit'
                          block
                          className='py-3 text-uppercase'
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              class='spinner-border spinner-border-sm text-light mr-2'
                              role='status'
                            >
                              <span class='sr-only'>Loading...</span>
                            </div>
                          ) : null}
                          Submit
                        </Button>
                      </div>
                    </form>
                  </div>
                  <div className=' fixed-bottom bg-white py-3 border-top shadow row mx-0 d-flex align-items-center d-lg-none'>
                    <div className='col pr-2'>
                      <div>
                        <div
                          className='mb-0'
                          style={{ fontSize: 20, fontWeight: 900 }}
                        >
                          <FormatPrice item={item} />
                        </div>
                        <div
                          className='mb-0 text-black-50'
                          style={{ fontSize: 14, fontWeight: 300 }}
                        >
                          {item?.location}
                        </div>
                      </div>
                    </div>
                    <div className='pl-2'>
                      <div className='d-flex align-items-center'>
                        <a href={`tel:${item?.userId?.phoneNumber}`}>
                          <Button
                            color='outline-primary'
                            className='icon-box d-flex text-uppercase px-0 rounded-circle mr-3'
                            style={{ height: 30, width: 30 }}
                          >
                            <SvgBox size='15px'>
                              <PhoneIcon />
                            </SvgBox>
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* Mobile End */}
            </>
          ) : (
            <div
              className={`feature-logo cursor-pointer  ${userClassName}`}
              id={`tooltip${item._id}`}
            >
              {' '}
              <LazyLoadImage
                src={publicUrl + 'assets/img/user-placeholder.svg'}
                placeholderSrc={publicUrl + 'assets/img/user-placeholder.png'}
                alt='logo'
              />
              <Tooltip
                placement='top'
                style={{ opacity: 0.9 }}
                isOpen={tooltipOpen}
                target={`tooltip${item._id}`}
                toggle={toggle}
              >
                <span style={{ fontSize: '10px' }}>{item.landLordName}</span>
              </Tooltip>
            </div>
          )}
          <div className='mobile-data-hide text-truncate'>
            <Link to={`/property/${item.slug}`}>
              <h5 className='text-capitalize font-weight-bold text-truncate mb-2'>
                {item.title}
              </h5>
            </Link>
            <div
              className='text-black-50 mb-0 text-capitalize text-truncate'
              style={{ fontSize: '12px' }}
            >
              {item.location}
              {/* Added On {moment.utc(item.createdAt).format('MMM DD, YYYY')} */}
            </div>
          </div>
          <div className='mobile-data-show mb-auto'>
            <div className='row mx-0'>
              <div className='col px-0'>
                <Link to={`/property/${item.slug}`}>
                  <div
                    className='text-capitalize font-weight-bold text-truncate mb-0 text-secondary'
                    style={{ fontSize: '15px' }}
                  >
                    <small>{item.title}</small>
                  </div>
                </Link>
                <p
                  className='text-black-50 mb-0 text-capitalize text-truncate'
                  style={{ fontSize: '12px' }}
                >
                  {item.location}
                </p>
                <p className='mb-0 text-truncate' style={{ fontSize: '13px' }}>
                  <FormatPrice item={item} />
                </p>
              </div>
            </div>
          </div>

          <section className='mt-md-auto'>
            <div
              className='row justify-content-between mx-0 text-black-50 mt-2 mb-3 mobile-items-hide'
              style={{ fontSize: 11.5, fontWeight: 400 }}
            >
              <div className='pr-3 pt-3'>
                <div className='text-capitalize d-flex flex-column align-items-center'>
                  {item?.type?.value === 'commercial' ? (
                    <>
                      <SvgBox className='mb-1' size='17px'>
                        <IconGrid />
                      </SvgBox>
                      <div>{item?.receptions ?? 0} rec</div>
                    </>
                  ) : (
                    <>
                      <SvgBox className='mb-1' size='17px'>
                        <IconBed />
                      </SvgBox>
                      <div>{item?.bedrooms ?? 0} beds</div>
                    </>
                  )}
                </div>
              </div>
              <div className='pr-3 pt-3'>
                <div className='text-capitalize d-flex flex-column align-items-center'>
                  <SvgBox className='mb-1' size='17px'>
                    <IconBath />
                  </SvgBox>
                  <div>{item?.bathrooms ?? 0} baths</div>
                </div>
              </div>

              <div className='pr-3 pt-3 text-truncate'>
                <div className='text-capitalize d-flex flex-column align-items-center'>
                  <SvgBox className='mb-1' size='17px'>
                    <IconRuler />
                  </SvgBox>
                  <div>
                    {numFormatter(item.landArea)}{' '}
                    {item?.unit?.value === 'square feet (sq. ft)'
                      ? 'sq. ft'
                      : 'sq m'}
                  </div>
                </div>
              </div>
            </div>
            {/* === */}
            <div className='mobile-data-show d-none  mt-auto'>
              <div
                className='row row-cols-2 justify-content-between mx-0 text-black-50 w-100 pt-3'
                style={{ fontSize: '2.5vmin', fontWeight: 500 }}
              >
                <div className='p-2'>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    {item?.type?.value === 'commercial' ? (
                      <>
                        <SvgBox className='mb-1' size='12px'>
                          <IconGrid />
                        </SvgBox>
                        <div>{item?.receptions?.value} rec</div>
                      </>
                    ) : (
                      <>
                        <SvgBox className='mb-1' size='12px'>
                          <IconBed />
                        </SvgBox>
                        <div>{item?.bedrooms?.value} beds</div>
                      </>
                    )}
                  </div>
                </div>
                <div className='p-2'>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    <SvgBox className='mb-1' size='12px'>
                      <IconBath />
                    </SvgBox>
                    <div>{item?.bathrooms?.value} baths</div>
                  </div>
                </div>

                <div className='p-2'>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    <SvgBox className='mb-1' size='12px'>
                      <IconRuler />
                    </SvgBox>
                    <div>
                      {item.landArea} {(item?.unit?.value).replace(/\s/g, '')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className='border-top pt-2 row mx-0 mobile-items-hide align-items-center'>
            <div className='col pl-0 d-flex flex-column text-truncate'>
              <FormatPrice item={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
