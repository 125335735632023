import React from 'react';
import { useSearchParams } from '../properties-components/search/hooks/useSearchParams';
import { Searchbar } from '../../components/properties-components/search/Searchbar';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';

const CroydonSearch = ({
  stateTitle = true,
  statePara = true,
  containerClassName = 'px-0 col-12 col-lg-12 col-xl-12 mb-5',
  boxClassName = 'mt-4',
}) => {
  const { id } = useParams();

  const { onHandleSubmit, methods } = useSearchParams(id);

  return (
    <>
      <div className={`${containerClassName} `}>
        {stateTitle ? (
          <div>
            <h1 className='mb-0 ls-4 text-blue font-weight-normal text-uppercase'>
              INVEST IN
            </h1>
            <div className='title-big'>CROYDON</div>
          </div>
        ) : null}
        {statePara && (
          <div className='mt-4 pl-2'>
            <h5 className='mb-4 line-height-14'>
              Croydon Commerical is an end-to-end platform connecting property
              owners, agents, developers, buyers, renters and investors.
            </h5>
            <div className='mb-4 font-weight-light'>
              As aut fugia debis sundaero eaquasp elecume nihillupta core pos
              sus doluptati qui archiliquam, sinum aut qui nonet assimaximi,
              sedi quis quasimi nctempo restior essincta ditaquaepe quunt mincto
              volorerumet, quia aut assum ligenis eos non rent volecernam ut
              fuga. As aut fugia debis sundaero eaquasp.
            </div>
          </div>
        )}
        <div className={`${boxClassName}`}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
              <Searchbar isCroydonBranded />
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default CroydonSearch;
