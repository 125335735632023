import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import { PropertyForm } from '../../components/properties-components/addPropertyForm/PropertyForm';
import Footer from '../../components/global-components/footer';
import { Error } from '../../utils/errorComponent';
import { useFetchPropertyBySlug } from '../../hooks/useFetchPropertyBySlug';
import { useUpdateProperty } from './hooks/useUpdateProperty';
import { Loader } from '../../components/Loader';

export const EditPropertyPage = () => {
  const { id } = useParams();

  const { isLoading, data, error } = useFetchPropertyBySlug({
    slug: id,
  });

  const { mutation } = useUpdateProperty(id);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div>
      <Navbar />
      <PropertyForm defaultValues={data} type='edit' mutation={mutation} />
      <Footer />
    </div>
  );
};
