export const getOptionsByValue = (name, options) =>
  options?.find(option => option.value === name)?.options;

export const getSelectedOption = (options, categoryDefault, optionName) =>
  getOptionsByValue(optionName, options)?.find(
    category => category.value === categoryDefault
  );

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const formattedPartners = partners =>
  partners?.map(partner => ({
    ...partner,
    label: partner.name,
    value: partner._id,
  })) ?? [];

export const formatOptionLabel = data => {
  const { logo, label, icon } = data;

  return (
    <div class='d-flex align-items-center'>
      <img
        className={` p-1 object-fit-contain `}
        style={{
          height: 50,
          width: 50,
          borderRadius: 6,
        }}
        src={
          icon ??
          logo ??
          'https://bdibucket1.s3.amazonaws.com/F75XQE3JE2J39MUAMVK2TZSHWY3PFZQL6ARCX2S5%20%282%29.png'
        }
        alt=''
      />

      <div class='d-flex mx-3 p-1 '>
        <h6 className='mb-0'>{capitalizeFirstLetter(label)}</h6>
      </div>
    </div>
  );
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data?.label}</span>
    <span style={groupBadgeStyles}>{data?.options?.length}</span>
  </div>
);

export const GetClassOptions = options => {
  const b = getOptionsByValue('class b', options);
  const c = getOptionsByValue('class c', options);
  const e = getOptionsByValue('class e', options);
  const f = getOptionsByValue('class f', options);

  const classTypes = [
    {
      label: 'Class B',
      options: b,
    },
    {
      label: 'Class C',
      options: c,
    },
    {
      label: 'Class E',
      options: e,
    },
    {
      label: 'Class F',
      options: f,
    },
  ];
  return classTypes;
};

export const GetTypeOptions = options => {
  const others = getOptionsByValue('others', options);
  const offices = getOptionsByValue('offices', options);
  const retail = getOptionsByValue('retail', options);
  const residential = getOptionsByValue('residential', options);
  const industrial = getOptionsByValue('industrial', options);
  const commercialLand = getOptionsByValue('commercial land', options);
  const licensedAndLeisure = getOptionsByValue('licensed & leisure', options);

  const typeOptions = [
    {
      label: 'Industrial',
      options: industrial,
    },
    {
      label: 'Retail',
      options: retail,
    },
    {
      label: 'Licensed & Leisure',
      options: licensedAndLeisure,
    },
    {
      label: 'Offices',
      options: offices,
    },
    {
      label: 'Commercial Land',
      options: commercialLand,
    },
    {
      label: 'Residential',
      options: residential,
    },
    {
      label: 'Others',
      options: others,
    },
  ];
  return typeOptions;
};
