import React from 'react';
import { MyAdCard } from './myAdCard';

export const MyAds = ({ ads }) => {
  return (
    <div className='properties-area pd-top-90' style={{ minHeight: '80vh' }}>
      <div className='container'>
        <div className='section-title'></div>
        <div className='row'>
          {ads.length ? (
            ads.map((item, i) => <MyAdCard item={item} i={i} />)
          ) : (
            <div className='section-title text-center py-5 mt-5'>
              <h2 className='title'>No Ads Found...</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
