import React from 'react';
import Card from './card';

const Property = ({ properties, title }) => {
  return (
    <div className='properties-area pd-top-120'>
      <div className='container'>
        <div className='section-title'>
          <h2 className='title text-capitalize'>{title}</h2>
        </div>
        <div className='row'>
          {properties?.map(item => (
            <Card item={item} key={item._id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Property;
