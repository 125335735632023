import React from 'react';
import sectiondata from '../../data/sections.json';

const AboutBanner = () => {
  const inlineStyle = {
    backgroundImage: `url(${sectiondata.pageHeaders.aboutus})`,
    backgroundColor: '#0000005e',
    backgroundBlendMode: 'overlay',
  };

  return (
    <div
      className='banner-area row mx-0 py-5 position-relative'
      style={inlineStyle}
    >
      <div className='container'>
        <div className='py-5'>
          <div className='row w-100'>
            <div className='col text-center'>
              <h2 className='font-weight-bold text-white mb-2'>About Us</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
