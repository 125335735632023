import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { useCreatePartner } from '../../hooks/useCreatePartners';

const AddPartnerForm = ({ setOpen }) => {
  const { control, handleSubmit } = useForm();
  const { user } = useSelector(state => state.user);

  const { mutation } = useCreatePartner(user._id);

  const onSubmit = data => {
    const payload = {
      ...data,
      userId: user._id,
    };

    mutation.mutateAsync(payload, {
      onSuccess: () => setOpen(false),
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor='name'>Partner Name</label>
        <Controller
          placeholder='Partner Name'
          id='name'
          name='name'
          control={control}
          as={<Input className=' mb-2' />}
        />

        <label htmlFor='logo'>Partner Logo Link</label>
        <Controller
          placeholder='Partner logo'
          id='logo'
          name='logo'
          control={control}
          as={<Input className=' mb-2' />}
        />

        <button
          className='btn btn-outline-secondary btn-block text-uppercase my-4 font-weight-bold'
          disabled={mutation.isLoading}
          type='submit'
        >
          {mutation.isLoading && <Spinner className='mr-2 p-2' size='sm ' />}
          Submit
        </button>
      </form>
    </div>
  );
};

export const AddPartnerModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className='d-flex  justify-content-end mt-5'>
      <button
        className='btn btn-outline-secondary text-uppercase font-weight-bold'
        onClick={() => setOpen(true)}
      >
        Add Partner
      </button>
      <Modal className='p-4' toggle={() => setOpen(false)} isOpen={open}>
        <ModalHeader>Add Partner Form</ModalHeader>
        <ModalBody>
          <AddPartnerForm setOpen={setOpen} />
        </ModalBody>
      </Modal>
    </div>
  );
};
