import React, { useState } from 'react';
import { Col, Row, Modal, Spinner } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { usePlaceHolder } from '../../../../utils/usePlaceholder';
import notifications from '../../../notifications';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Close } from '../../../../images/icons/close.svg';
import { SvgBox } from '../../../../images/icons/SvgBox';
import { formatOptionLabel } from '../../../../utils/optionFormatter';

export const ImagesGrid = () => {
  const {
    placeholder,
    file,
    resetPlaceholder,
    inputFile,
    onChange,
    onImageClick,
  } = usePlaceHolder();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { watch, setValue, control } = useFormContext();

  const images = watch('images');

  const length = (images && images?.length) || 0;

  const uuid = uuidv4();
  const remaining = new Array(12 - length).fill(uuid);

  const removeImage = currentImage => {
    const updatedImages = images.filter(image => image !== currentImage);
    setValue('images', updatedImages);
  };

  const saveImageHandler = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append('images', file);
    axios
      .post('/image', formdata)
      .then(res => {
        resetPlaceholder();
        setLoading(false);

        setValue(
          'images',
          !images ? [res.data.urls[0]] : [res.data.urls[0], ...images]
        );
        setModal(false);
      })
      .catch(err => {
        setLoading(false);
        notifications.error('Error', err?.response?.data);
      });
  };

  return (
    <Col>
      <Row className='mb-4'>
        {images?.length ? (
          <Col md={12} className='p-0 position-relative'>
            <label> Select Cover Image</label>
            <Controller
              options={images?.map((image, index) => ({
                label: `Image ${index + 1}`,
                value: image,
                logo: image,
              }))}
              formatOptionLabel={formatOptionLabel}
              control={control}
              name='coverImage'
              as={Select}
            />
          </Col>
        ) : null}

        <Col className='pr-md-0 d-flex flex-column'>
          <div>
            <Modal
              contentClassName='border-0 h-auto'
              centered
              isOpen={modal}
              toggle={() => {
                resetPlaceholder();
                setModal(!modal);
              }}
            >
              <div className='p-3 d-flex flex-column align-items-center'>
                <h4 className='my-2'>Upload Photo</h4>
                <div
                  className={`${
                    placeholder ? 'bg-grey' : `bg-grey`
                  } mt-2 upload-circle shadow-sm rounded-sm `}
                  type='button'
                  onClick={onImageClick}
                >
                  <input
                    type='file'
                    id='file'
                    accept='image/png/jpeg'
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onChange}
                    name='file'
                  />
                  <span class='avatar-container'>
                    {placeholder ? (
                      <img
                        alt=''
                        className='rounded-sm'
                        height='200px'
                        width='200px'
                        src={placeholder}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <div
                        className='d-flex flex-column justify-content-center align-items-center'
                        style={{ width: 200, height: 200 }}
                      >
                        <h5 className='text-center text-muted font-weight-bold'>
                          Select An Image
                        </h5>
                        <h1 className='mb-0 '>+</h1>
                      </div>
                    )}
                  </span>
                </div>

                <div className='mt-4 mx-auto' style={{ width: 200 }}>
                  <button
                    className='btn btn-secondary btn-block'
                    onClick={() => saveImageHandler()}
                    disabled={loading || file === ''}
                  >
                    {loading && <Spinner size='sm' className='mr-2' />}
                    Save Image
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </Col>
      </Row>
      <div>
        <Row className='row-cols-2'>
          {images?.length
            ? images?.map((image, index) => (
                <Col key={`${image}-${index}`} className='px-2 mb-3'>
                  <div
                    onClick={e => e.stopPropagation()}
                    style={{ height: 200, width: '100%' }}
                  >
                    <div
                      onClick={() => removeImage(image)}
                      style={{
                        right: 0,
                        top: -10,

                        cursor: 'pointer',
                      }}
                      className='position-absolute bg-light btn-3 py-3 px-3 rounded-circle '
                    >
                      <SvgBox size='10px'>
                        <Close />
                      </SvgBox>
                    </div>
                    <img
                      src={image}
                      alt={image}
                      className='w-100 h-100 object-fit-cover '
                    />
                  </div>
                </Col>
              ))
            : null}
          {remaining.map((r, index) => (
            <Col key={`${r}-${index}`} className='px-2 mb-3'>
              <div
                onClick={() => {
                  resetPlaceholder();
                  setModal(!modal);
                }}
                style={{ height: 200, width: '100%' }}
              >
                <label className='btn p-0 btn-light h-100 w-100 rounded-lg-2 position-relative'>
                  <div className='h-100 d-flex flex-column justify-content-center align-items-center text-center'>
                    <h5 className='text-center text-muted font-weight-bold'>
                      Select An Image
                    </h5>
                    <span>
                      <div className='mb-0 h1 text-dark'>&#65291;</div>
                    </span>
                  </div>
                </label>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Col>
  );
};
