import React from 'react';
import { Row } from 'reactstrap';

export const PropertyEcpDocuments = ({ property }) => {
  const { ecpDocuments } = property || {};
  return (
    <div>
      {ecpDocuments?.length ? (
        <div className='border-bottom mt-4 pb-5 mb-5 font-container-text'>
          <h4 className='mb-4'>Documents</h4>
          <Row className='mx-0' style={{ gap: 15 }}>
            <div className='d-flex align-items-baseline'>
              <i className='fa fa-file-pdf-o mr-2' />
              <span>{ecpDocuments}</span>
              <a
                href={ecpDocuments}
                target='_blank'
                rel='noreferrer'
                className='ml-4'
              >
                <span className='text-uppercase text-underline font-weight-bold small'>
                  Download
                </span>
              </a>
            </div>
          </Row>
        </div>
      ) : null}
    </div>
  );
};
