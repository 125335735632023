import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { logout } from '../../features/userAuthentication';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { useGetProfile } from '../../hooks/useGetProfile';
import { USER_TYPES } from '../../utils/userTypes';
import { Link } from 'react-router-dom';

export const UserAccountDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { data, isLoading } = useGetProfile();

  const dispatch = useDispatch();

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className='px-0 bg-transparent text-center'
    >
      <DropdownToggle
        color='link'
        className='rounded-circle p-0 d-flex align-items-center justify-content-center'
        style={{ height: 30, width: 30 }}
      >
        <button className='border-0 p-1 bg-white rounded-circle nav-avatar'>
          {isLoading ? (
            <div
              style={{
                height: 30,
                width: 30,
              }}
              className='rounded-circle object-fit-contain bg-secondary'
            />
          ) : (
            <LazyLoadImage
              className='rounded-circle object-fit-contain'
              height={30}
              width={30}
              src={data?.imageUrl}
              alt='User'
            />
          )}
        </button>
      </DropdownToggle>

      <DropdownMenu
        right
        persist={true}
        className='text-center tr-box-shadow py-0 custom-width'
      >
        <DropdownItem className='py-0   border-bottom'>
          <div className='text-center py-2'>
            <LazyLoadImage
              className='rounded-circle object-fit-contain '
              height={60}
              width={60}
              src={data?.imageUrl}
              alt='User'
            />
            <div className='font-weight-bold h5 text-capitalize'>
              <div>{data?.name}</div>
            </div>
            <div className='text-black-50 text-capitalize'>
              <div>
                {data?.userType === USER_TYPES.serviceProvider
                  ? 'Service Provider'
                  : 'Partner'}{' '}
              </div>
            </div>
          </div>
        </DropdownItem>
        {data?.userType === USER_TYPES.serviceProvider ? (
          <Link className='remove-line' to='/my-services'>
            <DropdownItem className=' border-bottom py-2 '>
              Dashboard
            </DropdownItem>
          </Link>
        ) : (
          <Link className='remove-line' to='/dashboard'>
            <DropdownItem className=' border-bottom py-2 '>
              Dashboard
            </DropdownItem>
          </Link>
        )}
        <DropdownItem
          onClick={() => dispatch(logout())}
          className=' border-bottom py-2 '
        >
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
