import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ClientSideServiceCard } from './clientSideServiceCard';

export const MovingServiceCarousel = ({ services }) => {
  const [adsMove, setAdsMove] = useState(false);

  const moveAds = () => {
    if (window.scrollY >= 150) {
      setAdsMove(true);
    } else {
      setAdsMove(false);
    }
  };

  window.addEventListener('scroll', moveAds);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className='d-none d-md-flex flex-column position-relative bg-white'
      style={{ width: 260 }}
    >
      <div
        className={`bg-light p-4 rounded shadow-sm ${
          adsMove ? 'ads-move' : 'ads-stop'
        }`}
        style={{ height: 260 }}
      >
        <h5 className='font-weight-bold mb-3'>Related Services</h5>
        <div>
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            className=''
            containerClass=''
            dotListClass=''
            draggable
            focusOnSelect={false}
            infinite
            itemClass=''
            showDots
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={responsive}
          >
            {services?.map(service => (
              <ClientSideServiceCard key={service?._id} service={service} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
