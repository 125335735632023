import axios from 'axios';
import { useQuery } from 'react-query';

const getFeaturedProperties = async (id, limit) => {
  try {
    const res = await axios.get(`/property/featured`, {
      params: {
        userId: id,
        limit,
      },
    });
    return res.data.properties;
  } catch (err) {
    throw err.response.data;
  }
};

export const useFeaturedProperties = ({ id, limit }) => {
  const { data, isLoading, error } = useQuery(
    [id, limit, 'featured-listing'],
    () => getFeaturedProperties(id, limit),
    {
      enabled: id ? true : false,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};
