import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  CardImgOverlay,
  CardImg,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
// import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import notifications from '../notifications';
import { usePlaceHolder } from '../../utils/usePlaceholder';
import { PlacesAutoComp } from '../properties-components/placesAutoComplete';

export const UpdateAd = ({ adData }) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(adData.linkLayout);
  const [address, setAddress] = useState(adData.address);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [city, setCity] = useState(null);
  const [locError, setLocError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(adData.title);
  const [description, setDescription] = useState(adData.description);
  const [label, setLabel] = useState(adData.label);

  const { register, handleSubmit, errors, reset } = useForm();
  const { placeholder, file, onChange } = usePlaceHolder();

  const updateServiceProviderHandler = (data) => {
    setLoading(true);
    const { title, url, label, description, radius } = data;
    const formdata = new FormData();
    if (placeholder) {
      formdata.append('imageUrl', file);
    }
    // formdata.append('title', title);
    city !== null ? formdata.append('city', city) : <> </>;
    formdata.append('address', address);
    postCode !== null ? formdata.append('postCode', postCode) : <></>;
    lat !== null ? formdata.append('lat', lat) : <> </>;
    lng !== null ? formdata.append('lng', lng) : <> </>;
    formdata.append('radius', radius);
    formdata.append('description', description);
    formdata.append('url', url);
    formdata.append('label', label);
    formdata.append('linkLayout', activeTab);
    return axios
      .patch(`/service/ads/${adData.slug}`, formdata)
      .then((res) => res.data)
      .catch((err) => {
        throw err.response.data;
      });
  };

  const mutation = useMutation(updateServiceProviderHandler, {
    onSuccess: () => {
      reset();
      notifications.success('Success', 'Updated Successfully');
      setLoading(false);
      history.push('/my-services');
    },
    onError: () => {
      setLoading(false);
      notifications.error('Error', 'Try Again Later!');
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <section className='new-property-container pd-top-90 my-4'>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className='text-center'>Update Your Ad</h2>
          <Row className='justify-content-between'>
            <Col lg={6} xl={5}>
              <div>
                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>URL</h6>
                  <div class='input-group-prepend'>
                    <span class='input-group-text'>https://</span>
                    <input
                      type='text'
                      className='box-shadow-primary'
                      name='url'
                      defaultValue={adData !== null ? adData?.url : ''}
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.url && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                {/* <div>
                  <h6 class='title'>Draw Attention</h6>
                  <div className='mx-0 border box-shadow-primary rounded-lg row'>
                    <div className='d-flex align-items-center pr-0 col-10'>
                      <span className='font-weight-bold mr-2'>
                        Grid Row Size
                      </span>
                    </div>
                    <div className='pl-0 col'>
                      <select
                        id='exampleForm.ControlSelect1'
                        className='border-0 my-2 px-0 form-control'
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select>
                    </div>
                  </div>
                </div> */}

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Title</h6>
                  <input
                    type='text'
                    disabled={true}
                    className='box-shadow-primary'
                    value={adData !== null ? adData?.title : ''}
                    // name='title'
                    // defaultValue={adData !== null ? adData ?.title : ''}
                    // onChange={(e) => setTitle(e.target.value)}
                    // ref={register({ required: true })}
                  />
                  {/* {errors.title && ( */}
                  <small className='font-weight-bold text-muted'>
                    Title can not be changed*
                  </small>
                  {/* )} */}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Description</h6>
                  <textarea
                    className='box-shadow-primary'
                    name='description'
                    defaultValue={adData !== null ? adData?.description : ''}
                    rows='4'
                    onChange={(e) => setDescription(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.description && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Label</h6>
                  <input
                    type='text'
                    className='box-shadow-primary'
                    name='label'
                    defaultValue={adData !== null ? adData?.label : ''}
                    onChange={(e) => setLabel(e.target.value)}
                    ref={register({ required: true })}
                  />
                  {errors.label && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>

                <div class='rld-single-input mb-4 mt-4'>
                  <h6 class='title'>Radius</h6>
                  <input
                    type='number'
                    className='box-shadow-primary'
                    name='radius'
                    defaultValue={adData !== null ? adData?.radius : ''}
                    ref={register({ min: 10, required: true })}
                  />
                  {errors.radius && (
                    <small className='font-weight-bold text-danger'>
                      This field is required to be a positive value
                    </small>
                  )}
                </div>
                {/* <div class='rld-single-select mb-4 mt-4'>
                  <h6 class='title'>Type</h6>
                  <Select
                    options={typeOptions}
                    className='box-shadow-primary'
                    placeholder='Select Service Type'
                    onChange={(e) => setType(e.value)}
                  />
                </div> */}

                <div className='box-shadow-primary rounded-lg py-2 px-3 mt-3 border'>
                  <button
                    type='button'
                    className='px-0 py-1 d-flex justify-content-between align-items-center btn btn-link btn-block'
                  >
                    <div className='text-left font-weight-bold text-capitalize text-truncate'>
                      {placeholder ? (
                        <>{file.name}</>
                      ) : (
                        <>{adData?.imageUrl} </>
                      )}
                    </div>
                    <span className='d-flex'>
                      {placeholder ? (
                        <img
                          alt=''
                          src={placeholder}
                          width={60}
                          height='100%'
                        />
                      ) : (
                        <img
                          alt=''
                          src={adData?.imageUrl}
                          width={60}
                          height='100%'
                        />
                      )}
                      {/* <i className='fa fa-picture-o fa-2x' />k */}
                    </span>
                    <input
                      type='file'
                      id='file'
                      accept='.jpeg, .jpg, .svg, .png'
                      name='file'
                      className='position-absolute w-100 btn'
                      style={{ opacity: 0, zIndex: 10 }}
                      onChange={onChange}
                    />
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div class='rld-single-input mb-4 mt-4'>
                <h6 class='title'>Address</h6>
                <PlacesAutoComp
                  setAddress={setAddress}
                  setLat={setLat}
                  setLng={setLng}
                  setPostCode={setPostCode}
                  setCity={setCity}
                  placeholderValue={adData !== null ? adData.address : ''}
                />
                {locError ? (
                  <small className='font-weight-bold text-danger'>
                    Select your location again
                  </small>
                ) : null}
              </div>
              <div>
                <h6 class='title my-3'>Link Layout</h6>
                <Nav tabs className='border-0'>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../../images/image-1.svg').default}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../../images/image-2.svg').default}
                      />
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        toggle('3');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../images/image-3.svg').default}
                      />
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        toggle('4');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../../images/image-4.svg').default}
                      />
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' })}
                      onClick={() => {
                        toggle('5');
                      }}
                    >
                      <img
                        alt=''
                        src={require('../images/image-5.svg').default}
                      />
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <h6 class='title mb-4'>Link Preview</h6>
                <TabContent
                  activeTab={activeTab}
                  className='p-4 border rounded-lg shadow-sm'
                >
                  <TabPane tabId='1'>
                    <Card inverse>
                      <CardImg
                        width='100%'
                        height='100%'
                        src={
                          !placeholder
                            ? adData.imageUrl !== null
                              ? adData.imageUrl
                              : require('../../images/placeholder.png').default
                            : placeholder
                        }
                        alt='Card image cap'
                      />
                      <CardImgOverlay>
                        <CardTitle tag='h5'>
                          {title !== null ? title : 'Card Title'}
                        </CardTitle>
                        <CardText>
                          {description !== null ? description : 'Description'}
                        </CardText>
                        <CardText>
                          <small className='text-muted'>
                            {label !== null ? label : 'Label'}
                          </small>
                        </CardText>
                      </CardImgOverlay>
                    </Card>
                  </TabPane>
                  <TabPane tabId='2'>
                    <Card className='flex-row' style={{ height: 200 }}>
                      <div className='h-100 w-100'>
                        <CardImg
                          className='w-100 h-100'
                          src={
                            !placeholder
                              ? adData.imageUrl !== null
                                ? adData.imageUrl
                                : require('../../images/placeholder.png')
                                    .default
                              : placeholder
                          }
                          alt='Card image cap'
                        />
                      </div>
                      <CardBody className='h-100 scroll-box'>
                        <CardTitle tag='h5'>
                          {title !== null ? title : 'Card Title'}
                        </CardTitle>
                        <CardSubtitle tag='h6' className='mb-2 text-muted'>
                          {label !== null ? label : 'Label'}
                        </CardSubtitle>
                        <CardText>
                          {description !== null ? description : 'Description'}
                        </CardText>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId='3'>
                    <Card inverse>
                      <CardImg
                        width='100%'
                        src={
                          !placeholder
                            ? adData.imageUrl !== null
                              ? adData.imageUrl
                              : require('../../images/placeholder.png').default
                            : placeholder
                        }
                        alt='Card image cap'
                      />
                      <CardImgOverlay>
                        <CardTitle tag='h5'>Card Title</CardTitle>
                        <CardText>
                          This is a wider card with supporting text below as a
                          natural lead-in to additional content. This content is
                          a little bit longer.
                        </CardText>
                        <CardText>
                          <small className='text-muted'>
                            Last updated 3 mins ago
                          </small>
                        </CardText>
                      </CardImgOverlay>
                    </Card>
                  </TabPane>
                  <TabPane tabId='4'>
                    <Card>
                      <div style={{ height: 200 }}>
                        <CardImg
                          className='w-100 h-100'
                          src={
                            !placeholder
                              ? adData.imageUrl !== null
                                ? adData.imageUrl
                                : require('../../images/placeholder.png')
                                    .default
                              : placeholder
                          }
                          alt='Card image cap'
                        />
                      </div>
                      <CardBody className='h-100 scroll-box'>
                        <CardTitle tag='h5'>
                          {title !== null ? title : 'Card Title'}
                        </CardTitle>
                        <CardSubtitle tag='h6' className='mb-2 text-muted'>
                          {label !== null ? label : 'Label'}
                        </CardSubtitle>
                        <CardText>
                          {description !== null ? description : 'Description'}
                        </CardText>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId='5'>
                    <div className='single-feature card'>
                      <div className='position-relative'>
                        <span className='featured-overlay-1 text-capitalize'>
                          <span>for sale</span>
                        </span>
                        <span
                          className='w-100 h-100 lazy-load-image-background blur lazy-load-image-loaded'
                          style={{
                            display: 'inline-block',
                            height: 150,
                            width: '100%',
                          }}
                        >
                          <img
                            src={
                              !placeholder
                                ? 'https://bdibucket1.s3.eu-west-2.amazonaws.com/Discover-the-Ultimate-Master-Bedroom-Styles-and-Inspirations-6_1.jpg'
                                : placeholder
                            }
                            alt="JJ's Apartment"
                            height='150px'
                            width='100%'
                            style={{ objectFit: 'cover' }}
                          />
                        </span>
                      </div>
                      <div className='details'>
                        <p className='feature-logo'>
                          <span
                            className=' lazy-load-image-background  lazy-load-image-loaded'
                            style={{ display: 'inline-block' }}
                          >
                            <img
                              src='https://bdibucket1.s3.amazonaws.com/07823050-d51e-44ea-92f1-8cd828ae3f80.png'
                              alt='logo'
                              className='rounded-circle'
                            />
                          </span>
                        </p>
                        <h6 className='price'>
                          £<span className='mr-1'>460,000</span>
                        </h6>
                        <h6 className='text-yellow title readeal-top text-truncate mb-0 mt-1'>
                          <a href='/property-details/60460161421ec20a7ace695a'>
                            2 bed flat for sale
                          </a>
                        </h6>
                        <h6 className='font-weight-light mt-1 text-capitalize text-truncate'>
                          JJ's Apartment, Oxted
                        </h6>
                        <p
                          className='text-yellow mb-0 text-capitalize'
                          style={{ fontSize: 12 }}
                        >
                          Added On Mar 08, 2021
                        </p>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
          <div className='mt-5 mb-5 pb-5 mb-lg-0 pb-lg-0'>
            <Button
              block
              type='submit'
              disabled={loading}
              className={`${loading === true ? 'button-disabled' : null}`}
            >
              <h5 className='mb-0 text-white'>
                {loading === true ? (
                  <div
                    class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                    role='status'
                  >
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : null}
                Save <i className='fa fa-save ml-2'></i>
              </h5>
            </Button>
          </div>
        </form>
      </Container>
    </section>
  );
};
