import React from 'react';
import { FormatPrice } from '../../../components/common/FormatPrice';
import { numberWithCommas } from '../../../utils/numberWithCommas';

export const convertSqftToSqM = (area) => (area / 10.764).toFixed(0);

export const convertSqMToSqft = (area) => (area * 10.764).toFixed(0);

const CroydonDetails = ({ boxClassName, style, details }) => {
  const {
    unit,
    classes,
    parking,
    accessibility,
    landArea,
    type,
    tenure,
    outdoorSpace,
    availableTerm,
    availableSpaceMinimum,
    availableSpaceMaximum,
    numberOfMaximumPeople,
    numberOfMinimumPeople,
    availableSpace,
    DDACompliant,
    priceType,
  } = details || {};

  return (
    <div
      style={style}
      className={`bg-blue text-white pl-4 py-4 pr-5 ${boxClassName}`}
    >
      <div className='display-4 mb-0 text-uppercase'>
        <FormatPrice item={details} />
      </div>

      <div>
        <ol className='nav flex-column'>
          <li className='flex-column mt-2'>
            <h6 className='mb-1 text-uppercase text-body'>SIZE AVAILABLE</h6>
            {landArea && priceType?.value !== 'price per sq ft / sq m' ? (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {unit?.value === 'square feet (sq. ft)'
                  ? `${numberWithCommas(landArea)} SQ FT / ${numberWithCommas(
                      convertSqftToSqM(landArea)
                    )} SQ M`
                  : `${numberWithCommas(
                      convertSqMToSqft(landArea)
                    )} SQ FT / ${numberWithCommas(
                      numberWithCommas(landArea)
                    )} SQ M`}
              </h6>
            ) : priceType?.value === 'price per sq ft / sq m' &&
              availableSpace?.value === 'minimum to maximum' ? (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {unit?.value === 'square feet (sq. ft)'
                  ? `${numberWithCommas(
                      availableSpaceMinimum
                    )} TO ${numberWithCommas(
                      availableSpaceMaximum
                    )}  SQ FT / ${numberWithCommas(
                      convertSqftToSqM(availableSpaceMinimum)
                    )} TO ${numberWithCommas(
                      convertSqftToSqM(availableSpaceMaximum)
                    )}  SQ M`
                  : `${numberWithCommas(
                      availableSpaceMinimum
                    )} TO ${numberWithCommas(
                      availableSpaceMaximum
                    )}  SQ M / ${numberWithCommas(
                      convertSqMToSqft(availableSpaceMinimum)
                    )} TO ${numberWithCommas(
                      convertSqMToSqft(availableSpaceMaximum)
                    )}  SQ F`}
              </h6>
            ) : null}
          </li>
          <li className='flex-column mt-3'>
            <h6 className='mb-1 text-uppercase text-body'>TYPE</h6>
            <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
              {type?.value}
            </h6>
          </li>
          {numberOfMaximumPeople || numberOfMinimumPeople ? (
            <li className='flex-column mt-3'>
              <h6 className='mb-1 text-uppercase text-body'>Suitable for</h6>
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {numberOfMinimumPeople ?? null}{' '}
                {numberOfMaximumPeople && numberOfMinimumPeople ? 'TO' : ' '}
                {numberOfMaximumPeople ?? null} People
              </h6>
            </li>
          ) : null}

          <li className='mt-3 justify-content-between'>
            <div>
              <h6 className='mb-1 text-uppercase text-body'>TENURE</h6>
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {tenure?.value}
              </h6>
            </div>

            <div>
              <h6 className='mb-1 text-uppercase text-body'>AVAILABLE TERM</h6>
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {availableTerm?.value}
              </h6>
            </div>
          </li>
          <li className='mt-3 justify-content-between'>
            <div>
              <h6 className='mb-1 text-uppercase text-body'>USE CLASS</h6>
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {classes?.map((item) => item?.label).join(', ')}
              </h6>
            </div>

            <div>
              <h6 className='mb-1 text-uppercase text-body'>DDA COMPLIANT</h6>
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {DDACompliant ? 'YES' : 'NO'}
              </h6>
            </div>
          </li>
          <li className='flex-column mt-3'>
            <h6 className='mb-1 text-uppercase text-body'>OUTDOOR SPACE</h6>
            {outdoorSpace?.value ? (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {outdoorSpace?.value}
              </h6>
            ) : (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                NONE
              </h6>
            )}
          </li>
          <li className='flex-column mt-3'>
            <h6 className='mb-1 text-uppercase text-body'>PARKING</h6>
            {parking?.value ? (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                {parking?.value}
              </h6>
            ) : (
              <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                none
              </h6>
            )}
          </li>
          <li className='flex-column mt-3'>
            <h6 className='mb-2 text-uppercase text-body font-weight-bold'>
              ACCESSIBILITY
            </h6>
            <div className='row row-cols-1 mx-0'>
              {accessibility?.map((item) => (
                <div key={item._id} className='p-1'>
                  <h6 className='mb-0 text-white font-weight-light text-uppercase text-truncate'>
                    {item?.label}
                  </h6>
                </div>
              ))}
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default CroydonDetails;
