import React from 'react';
import { Col, Row, Input } from 'reactstrap';
import { useFormContext, Controller } from 'react-hook-form';

export const Links = () => {
  const { control } = useFormContext();

  return (
    <section>
      <Row>
        <Col md={12}>
          <div>
            <Row className='mb-4 align-items-center'>
              <Col md={12}>
                <label className='text-uppercase my-4'>Video Link</label>
                <div className='rld-single-input mb-0'>
                  <Controller
                    name='videoUrl'
                    placeholder='Iframe embed link'
                    control={control}
                    as={<Input className='rounded-0 custom-input-padding' />}
                  />
                </div>
              </Col>
              <Col md={12}>
                <label className='text-uppercase my-4'>CGI Walkthrough</label>
                <div className='rld-single-input mb-0'>
                  <Controller
                    control={control}
                    name='cgiLink'
                    placeholder='Iframe embed link'
                    as={<Input className='rounded-0 custom-input-padding' />}
                  />
                </div>
              </Col>
              <Col md={12}>
                <label className='text-uppercase my-4'>360 View</label>
                <div className='rld-single-input mb-0'>
                  <Controller
                    name='threeSixtyView'
                    placeholder='Iframe embed link'
                    control={control}
                    as={<Input className='rounded-0 custom-input-padding' />}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </section>
  );
};
