import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { SvgBox } from '../../../images/icons/SvgBox';

// icons
import { ReactComponent as HomeIcon } from '../../../images/icons/home.svg';
import { ReactComponent as ApartmentIcon } from '../../../images/icons/apartment.svg';
import { ReactComponent as OfficeIcon } from '../../../images/icons/office.svg';
import { ReactComponent as VillaIcon } from '../../../images/icons/villa.svg';
import { ReactComponent as RoomIcon } from '../../../images/icons/living-room.svg';

const RegionalPartners = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1900 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1900, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const dataArr = [
    {
      url: '/type/commercial',
      image: '/assets/home-images/home-item-0.png',
    },
    // {
    //   icon: <ApartmentIcon />,
    //   title: 'Maisonette',
    //   url: '/type/maisonette',
    //   image:
    //     'https://images.unsplash.com/photo-1542309175-9b88d743f89f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=327&q=80',
    // },
    // {
    //   icon: <OfficeIcon />,
    //   title: 'Flat',
    //   url: '/type/flat',
    //   image:
    //     'https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
    // },
    // {
    //   icon: <VillaIcon />,
    //   title: 'Land',
    //   url: '/type/land',
    //   image:
    //     'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
    // },
    // {
    //   icon: <RoomIcon />,
    //   title: 'House',
    //   url: '/type/house',
    //   image:
    //     'https://images.unsplash.com/photo-1561824045-29ede1bf6944?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=375&q=80',
    // },
  ];

  return (
    <section className='regional-partners mb-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6 col-xl-6'>
            <div className='position-relative h-100 h-all-100'>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=''
                dotListClass=''
                draggable
                focusOnSelect={false}
                infinite
                itemClass=''
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside
                responsive={responsive}
              >
                {dataArr?.length &&
                  dataArr?.map((item, i) => (
                    <div
                      key={item._id}
                      className='mx-auto px-0 col-12 col-md-9 col-lg-12 col-xl-9'
                      style={{ minHeight: '300px' }}
                      item={item}
                    >
                      <Link
                        to={item.url}
                        className='d-flex flex-column align-items-center justify-content-center w-100 rounded shadow'
                        style={{
                          height: '100%',
                          backgroundImage: `url(${item.image})`,
                          backgroundSize: 'cover',
                          // backgroundColor: 'rgba(0, 0, 0, 0.442)',
                          backgroundBlendMode: 'overlay',
                        }}
                      >
                        <div className='h-auto text-center'>
                          <SvgBox
                            size='60px'
                            className='mb-4 mx-auto text-white'
                          >
                            {item.icon}
                          </SvgBox>
                          <h5 className='mb-0 text-white text-capitalize'>
                            {item.title}
                          </h5>
                        </div>
                      </Link>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-6 mt-4 mt-lg-0'>
            <div>
              <h1 className='mb-3'>Regional development partners</h1>
              <h4 className='line-height-16 letter-spacing-small mb-4'>
                Focussing on Local Development Partnerships, Councils,
                Government Agencies, Institutional Investors, SMEs and
                Multinational Employers the Moveappy marketplace platform has
                been created with flexibility in mind, with an easy-to-use
                interface and multiple API integrations.
              </h4>
              <h5 className='letter-spacing-small mb-4'>
                <small>
                  Built on a 100% end-to-end digital process we can replace
                  viewingThis solution aims to help in solving complex problems
                  of bringing in much-needed investment to help with the
                  regeneration of local areas and conurbations.
                </small>
              </h5>
              <h5 className='letter-spacing-small mb-0'>
                <small>
                  Digital transformation is placed at the heart of the solution,
                  managing and optimising property marketing efforts; to deliver
                  asset listings, investor access, verification and validation,
                  account setup, communication and transaction.
                </small>
              </h5>
              <div className='mt-3 text-right'>
                <Link
                  to='/contact-us'
                  className='btn btn-orange text-white rounded-0'
                >
                  <span className='h6 mb-0 text-uppercase'>Contact us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegionalPartners;
