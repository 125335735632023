import React, { useState } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as NavBarIcon } from '../../images/icons/nav.svg';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';
// import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrowRight.svg';
import { ReactComponent as AddIcon } from '../../images/icons/add.svg';
import Sidebar from './Sidebar';
import { USER_TYPES } from '../../utils/userTypes';
import { useGetProfile } from '../../hooks/useGetProfile';
import { UserAccountDropdown } from './UserAccountDropdown';

const Navbar = ({ className }) => {
  const [sidebar, setSidebar] = useState(false);

  const { data } = useGetProfile();

  const location = useLocation();
  const history = useHistory();

  const isHome = location.pathname === '/' ? true : false;

  const { isAuthenticated } = useSelector((state) => state.user);

  // const [marketDropdownOpen, setMarketDropdownOpen] = useState(false);

  return (
    <>
      <div
        className={`navbar-area position-sticky top-0 px-xl-5 py-3 py-lg-2 bg-white shadow-sm`}
      >
        <nav className={`navbar navbar-area navbar-expand-lg navbar-2 `}>
          <div className='container nav-container align-items-start align-items-lg-center py-1'>
            {/* <div> */}
            {(history.location.pathname === '/dashboard' ||
              history.location.pathname === '/my-properties' ||
              history.location.pathname === '/partners' ||
              history.location.pathname === '/my-services' ||
              history.location.pathname === '/profile/messages' ||
              history.location.pathname === '/profile/update') &&
            sidebar === false ? (
              <div className='pr-5'>
                <button
                  type='button'
                  className='btn p-0'
                  onClick={() => setSidebar(true)}
                >
                  <SvgBox size='20px' className={` text-black `}>
                    <NavBarIcon />
                  </SvgBox>
                </button>
              </div>
            ) : null}

            <div className='d-none d-lg-flex align-items-center'>
              {/* {data?.imageUrl && isAuthenticated ? (
                <Link to='/' className='remove-line'>
                  <img
                    height={50}
                    width={100}
                    className='object-fit-contain'
                    alt={data?.name}
                    src={data?.imageUrl}
                  />
                </Link>
              ) : ( */}
              <Link className='ml-3 cursor-pointer remove-line' to='/'>
                <SvgBox
                  className={`t mr-1`}
                  size={`${isHome ? '60px' : '35px'}`}
                >
                  <Logo />
                </SvgBox>
              </Link>
              {/* )} */}

              <Link className='ml-0 remove-line' to='/'>
                <h3 className={`mb-0 remove-line text-body font-weight-bold`}>
                  moveappy
                </h3>
              </Link>
            </div>
            {/* </div> */}
            <ul className='navbar-nav menu-open readeal-top ml-auto flex-row  align-items-center d-lg-none'>
              {!isAuthenticated ? (
                <>
                  {/* <li className='position-relative'>
                    <button
                      type='button'
                      className='btn p-0 font-weight-bold'
                      onClick={() => setMarketDropdownOpen(!marketDropdownOpen)}
                    >
                      <div
                        className={`d-flex align-items-center ${
                          isHome ? 'text-white' : 'text-body'
                        }`}
                      >
                        Market Properties
                        <SvgBox
                          className='ml-2'
                          size='10px'
                          rotate={`${marketDropdownOpen ? '-90' : '92'}`}
                        >
                          <ArrowRightIcon />
                        </SvgBox>
                      </div>
                    </button>

                    <div
                      className={`new-dp ${
                        marketDropdownOpen ? 'new-dp-show' : 'd-none'
                      }`}
                    >
                      <NavLink exact to={`/register?type=${'free'}`}>
                        Register as a Property Agent
                      </NavLink>

                      <NavLink exact to={'/register/service-provider'}>
                        Register as a Service Provider
                      </NavLink>

                      <NavLink exact to='/property/add/landlord'>
                        Landlord looking to sell
                      </NavLink>
                    </div>
                  </li> */}

                  <li className='ml-3'>
                    <NavLink to='/login'>Login</NavLink>
                  </li>
                </>
              ) : null}
              {isAuthenticated ? (
                <>
                  <li className='readeal-top mr-4 pr-0'>
                    {data?.userType === USER_TYPES.serviceProvider ? (
                      <NavLink to='/service/add'>Post Ad</NavLink>
                    ) : (
                      <NavLink
                        to='/create-listing'
                        className='bg-white d-block px-2 py-1 rounded remove-line'
                      >
                        <div className='d-flex align-items-center'>
                          <SvgBox size='13px' className='mr-1 text-primary'>
                            <AddIcon />
                          </SvgBox>
                          <span
                            className='text-primary font-weight-bold'
                            style={{ fontSize: 9 }}
                          >
                            Create Listing
                          </span>
                        </div>
                      </NavLink>
                    )}
                  </li>
                  <UserAccountDropdown />
                </>
              ) : null}
            </ul>
            <div className='collapse navbar-collapse' id='realdeal_main_menu'>
              <ul className='navbar-nav menu-open readeal-top d-flex align-items-center justify-content-end'>
                {!isAuthenticated ? (
                  <>
                    {/* <li className='position-relative'>
                      <button
                        type='button'
                        className='btn p-0 font-weight-bold'
                        onMouseEnter={() => setMarketDropdownOpen(true)}
                      >
                        <div
                          className={`d-flex align-items-center ${
                            isHome ? 'text-white' : 'text-body'
                          }`}
                        >
                          Market Properties
                          <SvgBox
                            className='ml-2'
                            size='10px'
                            rotate={`${marketDropdownOpen ? '-90' : '92'}`}
                          >
                            <ArrowRightIcon />
                          </SvgBox>
                        </div>
                      </button>

                      <div
                        onMouseLeave={() => setMarketDropdownOpen(false)}
                        className={`new-dp ${
                          marketDropdownOpen ? 'new-dp-show' : 'd-none'
                        }`}
                      >
                        <NavLink exact to={`/register?type=${'free'}`}>
                          Register as a Property Agent
                        </NavLink>

                        <NavLink exact to={'/register/service-provider'}>
                          Register as a Service Provider
                        </NavLink>

                        <NavLink exact to='/property/add/landlord'>
                          Landlord looking to sell
                        </NavLink>
                      </div>
                    </li> */}
                    <li>
                      <NavLink className='remove-line h3 mb-0' to='/login'>
                        Login
                      </NavLink>
                    </li>
                  </>
                ) : null}
                {isAuthenticated ? (
                  <>
                    <li className='readeal-top mr-0 pr-0'>
                      {data?.userType === USER_TYPES.serviceProvider ? (
                        <NavLink to='/service/add'>Post Ad</NavLink>
                      ) : (
                        <NavLink
                          to='/create-listing'
                          className=' d-block px-4 py-1 rounded remove-line'
                        >
                          <div className='d-flex align-items-center'>
                            <SvgBox size='18px' className='mr-2 '>
                              <AddIcon />
                            </SvgBox>
                            <span>Create Listing</span>
                          </div>
                        </NavLink>
                      )}
                    </li>
                    <li className='mt-4 mt-lg-0 px-0 bg-transparent nav-right-part nav-right-part-desktop'>
                      <UserAccountDropdown />
                    </li>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Sidebar Start */}

      <div
        onClick={() => setSidebar(false)}
        className={`mobile-sidebar ${
          sidebar ? 'sidebar-show' : 'sidebar-hide'
        }`}
      >
        <div className='bg-white mobile-sidebar__container'>
          <header className='p-3 d-flex align-items-center'>
            <div className='d-lg-flex align-items-center flex-column'>
              {data?.imageUrl && isAuthenticated ? (
                <img
                  height={50}
                  width={100}
                  className='object-fit-contain'
                  alt={data?.name}
                  src={data?.imageUrl}
                />
              ) : (
                <SvgBox
                  className={`${isHome ? 'text-white' : 'text-primary'} mr-1`}
                  size={`${isHome ? '25px' : '35px'}`}
                >
                  <Logo />
                </SvgBox>
              )}

              {/* <Link className='mt-3' to='/'>
                <h6
                  className={`mb-0 ${
                    isHome ? 'text-white' : 'text-body'
                  } font-weight-bold`}
                >
                  {data?.name}
                </h6>
                <sub>Powered By Moveappy</sub>
              </Link> */}
            </div>
            <div className='ml-auto'>
              <button
                type='button'
                className='btn p-0'
                onClick={() => setSidebar(false)}
              >
                <span className='h1 mb-0'>&times;</span>
              </button>
            </div>
          </header>
          <Sidebar className='position-relative pt-0' />
        </div>
      </div>
      {/* Mobile Sidebar End */}
    </>
  );
};

export default Navbar;
