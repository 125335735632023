import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import Toggle from 'react-toggle';
import { useGetPartners } from '../../../../hooks/useGetPartners';
import {
  formatOptionLabel,
  formattedPartners,
} from '../../../../utils/optionFormatter';

export const PropertySetting = () => {
  const { control } = useFormContext();

  const { user } = useSelector((state) => state.user);

  const { isLoading, partners } = useGetPartners({
    userId: user?._id,
  });

  return (
    <div>
      <div className='d-flex flex-column  py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>Partners</h4>
          <div className='text-muted my-2 mb-2'>
            Select partner associated with this property.
          </div>
        </div>
        <Controller
          className='w-100'
          name='partnerId'
          control={control}
          defaultValue={false}
          isLoading={isLoading}
          formatOptionLabel={formatOptionLabel}
          options={formattedPartners(partners)}
          as={<Select />}
        />
      </div>
      <div className='d-flex align-items-center py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>Featured</h4>
          <span className='text-muted'>
            List this property as a Featured Property on the homepage.
          </span>
        </div>
        <Controller
          defaultValue={false}
          render={(field) => (
            <Toggle
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
          name='isFeatured'
          control={control}
        />
      </div>
      <div className='d-flex align-items-center py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>
            DDA Compliant (Disability Discrimination Act)
          </h4>
          <span className='text-muted'>
            All reasonable steps have been taken to provide access for disabled
            people.
          </span>
        </div>
        <Controller
          defaultValue={false}
          render={(field) => (
            <Toggle
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
          name='DDACompliant'
          control={control}
        />
      </div>
      <div className='d-flex align-items-center py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>Publish</h4>
          <span className='text-muted'>Make this property listing live.</span>
        </div>
        <Controller
          defaultValue={true}
          render={(field) => (
            <Toggle
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
          name='isActive'
          control={control}
        />
      </div>

      {/* <div className='d-flex   align-items-center py-4 border-bottom justify-content-between w-100'>
        <div>
          <h4 className=' mb-2'>Expiry Date (In-Coming)</h4>
          <span className='text-muted'>
            Date on which this property will be hidden.
          </span>
        </div>
        <Controller
          control={control}
          name='expiryDate'
          as={
            <input
              disabled={true}
              type='date'
              className='rounded-0 form-control w-25 custom-input-padding'
            />
          }
        />
      </div> */}
    </div>
  );
};
