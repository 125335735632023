import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrowRight.svg';
// import { ReactComponent as HeartIcon } from '../../images/icons/heart.svg';
// import { ReactComponent as HeartFillIcon } from '../../images/icons/heartFill.svg';
// import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';

const NavbarMobile = ({ className }) => {
  const { id } = useParams();
  // const [save, setSave] = React.useState(false);
  return (
    <div
      className={`bg-white d-flex align-items-center py-4 px-3 border-bottom position-sticky fixed-top ${className}`}
    >
      <Link
        to='/'
        className='d-flex align-items-center text-body text-truncate'
      >
        <SvgBox rotate='180' size='15px'>
          <ArrowIcon />
        </SvgBox>
        <span className='font-weight-bold ml-2 text-capitalize'>
          {' '}
          Home • {id.replace('-', ' ').slice(0, 14)}
        </span>
      </Link>
      <div className='ml-auto d-flex align-items-center'>
        {/* <button type='button' className='btn p-0 mr-3'>
          <SvgBox size='20px'>
            <DownloadIcon />
          </SvgBox>
        </button>
        <button
          onClick={() => setSave(!save)}
          type='button'
          className='btn p-0'
        >
          <SvgBox size='20px'>
            {save ? <HeartIcon /> : <HeartFillIcon />}
          </SvgBox>
        </button> */}
      </div>
    </div>
  );
};

export default NavbarMobile;
