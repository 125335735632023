import React from 'react';
// import axios from 'axios';
import { useForm } from 'react-hook-form';
// import { useHistory } from 'react-router-dom';
// import notifications from '../notifications';

export const RegisterSpecial = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  // const history = useHistory();

  const onSubmit = data => {
    setLoading(true);
    // axios
    //     .post('/profile/reset/check', data)
    //     .then((res) => {
    //         setLoading(false);
    //         notifications.success('Success', 'Email Sent Successfully');
    //         history.push(`/forgot-password/resend/${res.data.id}`);
    //     })
    //     .catch((err) => {
    //         setLoading(false);
    //         notifications.error('Error', 'Try Again Later!');
    //     });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className='mb-4 text-capitalize'>Register for your account today</h4>
      <div className='rld-single-input mt-3'>
        <label className='text-muted font-weight-bold'>
          Your Work Email Address
        </label>
        <input type='email' name='email' ref={register({ required: true })} />
        {errors.email && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='mt-5 text-center px-0 col-12 col-md-4 mx-auto'>
        <button
          type='submit'
          disabled={loading}
          className={`btn btn-secondary btn-block ${
            loading === true ? 'button-disabled' : null
          }`}
        >
          {loading ? (
            <div
              class='spinner-border spinner-border-sm text-light mr-2'
              role='status'
            >
              <span class='sr-only'>Loading...</span>
            </div>
          ) : null}
          Send Email
        </button>
      </div>
    </form>
  );
};
