import axios from 'axios';
import { useQuery } from 'react-query';

const getProperty = async propertyId => {
  try {
    const res = await axios.get(`property/${propertyId}`);
    return res.data.property;
  } catch (err) {
    throw err.response;
  }
};

export const useFetchPropertyBySlug = ({ slug }) => {
  const { isLoading, data, error } = useQuery(
    [slug, 'property-details'],
    () => getProperty(slug),
    {
      enabled: slug ? true : false,
    }
  );

  return {
    isLoading,
    data,
    error,
  };
};
