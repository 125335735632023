import React from 'react';
import { useSelector } from 'react-redux';
import { Container, ListGroup, ListGroupItem } from 'reactstrap';
import { useGetPartners } from '../../hooks/useGetPartners';
import { AddPartnerModal } from './AddPartnerForm';
import Navbar from '../../components/global-components/navbar';
import { Loader } from '../../components/Loader';

export const PartnersPage = () => {
  const { user } = useSelector(state => state.user);

  const { isLoading, partners } = useGetPartners({
    userId: user._id,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar />
      <Container>
        <AddPartnerModal />
        <ListGroup className='mt-4'>
          {partners.map(partner => (
            <ListGroupItem
              className='d-flex align-items-center'
              key={partner._id}
            >
              <img
                className='object-fit-contain '
                style={{
                  height: 40,
                  width: 40,
                }}
                src={partner.logo}
                alt=''
              />

              <div className='mx-4 font-weight-bold'>{partner?.name}</div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Container>
    </div>
  );
};
