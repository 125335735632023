import React from 'react';
import Logo from '../../../src/images/icons/logo.svg';

export const Verify = () => {
  return (
    <div className='row bg-gray mt-5 pd-top-120 d-flex justify-content-center align-items-center'>
      <div className='col-md-8 mt-5 text-center'>
        <img
          style={{
            height: 200,
          }}
          src={Logo}
          alt={'Logo'}
        />
      </div>
      <div className='col-md-6'>
        <div className='section-title text-center mt-5'>
          <h2 className='subtitle'>Almost there ...</h2>
          <p className='mb-0'>Please check your email</p>
          <p> to confirm your account Verification. </p>
          <p>If this is not the correct address, please re-register.</p>
          <p className='mb-0'>
            If you haven't received our email in 15 minutes,
          </p>
          <p>please check your spam folder.</p>
        </div>
      </div>
    </div>
  );
};
