import React from 'react';
import Navbar from '../../components/global-components/navbar';
import Banner from '../../components/section-components/register-banner';
import { Register } from '../../components/auth-components/register';
import Footer from '../../components/global-components/footer';
import { useRedirect } from '../../hooks/useRedirect';

const Login = () => {
  useRedirect();

  return (
    <div>
      <Navbar />
      {/* <Banner /> */}
      <Register />
      <Footer />
    </div>
  );
};

export default Login;
