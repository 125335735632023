import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import { UpdateAd } from '../components/service-provider/editYourAd';

const getAd = async adId => {
  try {
    const res = await axios.get(`/service/ads/${adId}`);
    return res.data.ad;
  } catch (err) {
    throw err.response;
  }
};
const EditAd = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useQuery(
    [`edit-ad-${id}`, id],
    () => getAd(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <div
            class='spinner-border text-dark mt-5'
            style={{ height: '3rem', width: '3rem' }}
            role='status'
          >
            <span class='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <h2 class='mt-5'>Something Went Wrong...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className='fade-in'>
      <Navbar />
      <UpdateAd adData={data} />
      <Footer />
    </div>
  );
};

export default EditAd;
