import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import notifications from '../notifications';
import { loginUser } from '../../features/userAuthentication';
import BgImage from '../../images/bg-image-2.jpg';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrowRight.svg';

export const SignIn = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = data => {
    setLoading(true);
    axios
      .post('/signin', data)
      .then(res => {
        setLoading(false);
        if (res.data.verify) {
          dispatch(loginUser(res.data));
        } else {
          history.push({
            pathname: '/verify',
            state: { email: res.data.email },
          });
        }
      })
      .catch(err => {
        setLoading(false);
        notifications.error('Error', err?.response?.data?.message);
      });
  };
  const inlineStyle = {
    backgroundImage: `url(${BgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backgroundBlendMode: 'overlay',
  };

  return (
    <div className='d-flex justify-content-center align-items-center mobile-vh-100'>
      <div className='row h-100 w-100 align-items-center bg-white'>
        <div className='col-12 col-lg-6 my-5 my-lg-0'>
          <div className='container-xl px-0'>
            <form
              className='col-md-8 col-xl-7 mx-auto'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='mb-4 row mx-0 align-items-baseline'>
                <Link to='/' className='d-flex align-items-center'>
                  <SvgBox rotate='180' size='10px'>
                    <ArrowIcon />
                  </SvgBox>
                  <span className='font-weight-bold ml-2'>Home • Moveappy</span>
                </Link>
              </div>
              <SvgBox size='40px' className='text-primary mb-4'>
                <Logo />
              </SvgBox>
              <h2 className='font-weight-bolder'>Login</h2>
              <p>See your growth and get consulting support!</p>
              <div className='rld-single-input my-4'>
                <input
                  type='email'
                  name='email'
                  placeholder='Enter Email'
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input mb-4'>
                <input
                  type='password'
                  placeholder='Enter Password'
                  name='password'
                  ref={register({ required: true })}
                />
                {errors.password && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='d-flex align-items-baseline'>
                <div className='d-flex align-items-center'>
                  <input id='Remember' type='checkbox' />
                  <label
                    htmlFor='Remember'
                    className='mb-0 ml-2 cursor-pointer font-weight-bold'
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  className='font-weight-bold  ml-auto'
                  style={{ textDecoration: 'underlined', fontSize: '14px' }}
                  to='/forgot-password'
                >
                  Forgot Password?
                </Link>
              </div>
              <div className='mt-4'>
                <button
                  type='submit'
                  disabled={loading}
                  className={`btn btn-secondary btn-block ${
                    loading === true ? 'button-disabled' : null
                  }`}
                >
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Login
                </button>
              </div>
            </form>
            {/* <div className='mt-3 text-center'>
              <span>Don't have an account?</span>
              <Link to='/register?type=free' className='ml-2'>
                <span className='font-weight-bold'>Sign up</span>
              </Link>
            </div> */}
          </div>
        </div>
        <div
          className='col-12 col-lg-6 px-0 order-first order-lg-last form-hero d-none d-sm-block'
          style={inlineStyle}
        >
          <div className='col-lg-8 d-flex flex-column text-center justify-content-center align-items-center h-100 mx-auto'>
            <h2 className='mb-4 text-white'>Welcome to MoveAppy</h2>
            <p className='my-0 text-white-50'>
              Moveappy is a one-stop platform connecting agents, developers,
              landlords, buyers, renters and investors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
