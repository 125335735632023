import React from 'react';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import { Link, useLocation } from 'react-router-dom';

function ScrollToTopOnMount() {
  React.useLayoutEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  console.log('object');
  return null;
}

export const Terms = () => {
  const { hash } = useLocation();

  return (
    <>
      <ScrollToTopOnMount />
      <Navbar />
      <main className='bg-light py-5 container-terms'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-xl-3 d-none d-lg-block'>
              <ul
                className='nav flex-column position-sticky'
                style={{ top: 80 }}
              >
                <li className='nav-item mb-2'>
                  <div>
                    <a
                      href='#policy0'
                      className={`nav-link h4 font-weight-bold ${
                        hash === '#policy0'
                          ? 'font-weight-bold text-yellow'
                          : ''
                      }`}
                    >
                      Terms & Conditions
                    </a>
                  </div>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy1'
                    className={`nav-link ${
                      hash === '#policy1' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Information about us
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy2'
                    className={`nav-link ${
                      hash === '#policy2' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Changes to these terms
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy3'
                    className={`nav-link ${
                      hash === '#policy3' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Changes to our site
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy4'
                    className={`nav-link ${
                      hash === '#policy4' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Accessing our site
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy5'
                    className={`nav-link ${
                      hash === '#policy5' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Your account and password
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy6'
                    className={`nav-link ${
                      hash === '#policy6' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Intellectual property rights
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy7'
                    className={`nav-link ${
                      hash === '#policy7' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    No reliance on information
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy8'
                    className={`nav-link ${
                      hash === '#policy8' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Limitation of our liability
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy9'
                    className={`nav-link ${
                      hash === '#policy9' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Uploading content to our site
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy10'
                    className={`nav-link ${
                      hash === '#policy10' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    User provided feedback
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy11'
                    className={`nav-link ${
                      hash === '#policy11' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Viruses
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy12'
                    className={`nav-link ${
                      hash === '#policy12' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Linking to our site
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy13'
                    className={`nav-link ${
                      hash === '#policy13' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Third party links and resources in our site
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy14'
                    className={`nav-link ${
                      hash === '#policy14' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    General Terms
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy15'
                    className={`nav-link ${
                      hash === '#policy15' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Applicable law
                  </a>
                </li>
              </ul>
            </div>
            <div className='col'>
              <section id='policy0' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>Terms and Conditions</h4>
                <p>
                  These terms and conditions tell you the terms on which you may
                  make use of the moveappy.co.uk website (our site), whether as
                  a guest or a registered user. Use of our site includes
                  accessing, browsing, or registering to use our site.
                </p>
                <p>
                  Please read these terms and conditions carefully before you
                  start to use our site, as these will apply to your use of our
                  site.
                </p>
                <p>
                  By using our site, you confirm that you accept these terms and
                  conditions and that you agree to comply with them.
                </p>
                <p>
                  If you do not agree to these terms and conditions, you must
                  not use our site.
                </p>
                <p>
                  These terms and conditions also include our
                  <Link to='/privacy' className='active mx-1 font-weight-bold'>
                    Privacy Policy
                  </Link>
                  which sets out the terms on which we process any personal data
                  we collect from you, or that you provide to us. By using our
                  site, you consent to such processing and you warrant that all
                  data provided by you is accurate.
                </p>
                <Link to='/acceptable'>
                  <h2 className='text-yellow mb-4'>
                    Click here for our Acceptable Use Policy
                  </h2>
                </Link>
                <Link to='/site-map' className='mb-3'>
                  <h2 className='text-yellow mb-0'>
                    Click here for our Complaints Procedure
                  </h2>
                </Link>
              </section>
              <section id='policy1' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Information about us</h5>
                <p>
                  Moveappy.co.uk is a site operated by Stockappy Ltd ("We"). We
                  are registered in England and Wales under company number
                  11738509 and have our registered office at Office 113,
                  Challenge House, 616 Mitcham Road, Croydon CR0 3AA.
                </p>
              </section>
              <section id='policy2' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Changes to these terms
                </h5>
                <p>
                  We may revise these terms and conditions at any time by
                  amending this page. Please check this page from time to time
                  to take notice of any changes we made, as they are binding on
                  you.
                </p>
              </section>
              <section id='policy3' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Changes to our site</h5>
                <p>
                  We may update our site from time to time, and may change the
                  content at any time.
                </p>
              </section>
              <section id='policy4' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Accessing our site</h5>
                <p>Our site is made available free of charge.</p>
                <p>
                  We do not guarantee that our site, or any content on it, will
                  always be available or be uninterrupted. Access to our site is
                  permitted on a temporary basis. We may suspend, withdraw,
                  discontinue or change all or any part of our site without
                  notice. We will not be liable to you if for any reason our
                  site is unavailable at any time or for any period.
                </p>
                <p>
                  You are responsible for making all arrangements necessary for
                  you to have access to our site.
                </p>
                <p>
                  You are also responsible for ensuring that all persons who
                  access our site through your internet connection are aware of
                  these terms and conditions and other applicable terms and
                  conditions, and that they comply with them.
                </p>
              </section>
              <section id='policy5' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Your account and password
                </h5>
                <p>
                  If you choose, or you are provided with, a user identification
                  code, password or any other piece of information as part of
                  our security procedures, you must treat such information as
                  confidential. You must not disclose it to any third party.
                </p>
                <p>
                  We have the right to disable any user identification code or
                  password, whether chosen by you or allocated by us, at any
                  time, if in our reasonable opinion you have failed to comply
                  with any of the provisions of these terms and conditions.
                </p>
                <p>
                  If you know or suspect that anyone other than you knows your
                  user identification code or password, you must promptly notify
                  us at hello@moveappy.co.uk.
                </p>
              </section>
              <section id='policy6' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Intellectual property rights
                </h5>
                <p>
                  We are the owner or the licensee of all intellectual property
                  rights in our site, and in the material published on it. Those
                  works are protected by copyright laws and treaties around the
                  world. All such rights are reserved.
                </p>
                <p>
                  You may print off one copy, and may download extracts, of any
                  page(s) from our site for your personal use and you may draw
                  the attention of others within your organisation to content
                  posted on our site.
                </p>
                <p>
                  You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text.
                </p>
                <p>
                  Our status (and that of any identified contributors) as the
                  authors of content on our site must always be acknowledged.
                </p>
                <p>
                  You must not use any part of the content on our site for
                  commercial purposes without obtaining a licence to do so from
                  us or our licensors.
                </p>
                <p>
                  If you print off, copy or download any part of our site in
                  breach of these terms and conditions, your right to use our
                  site will cease immediately and you must, at our option,
                  return or destroy any copies of the materials you have made.
                </p>
              </section>
              <section id='policy7' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  No reliance on information
                </h5>
                <p>
                  The content on our site is provided for general information
                  only. It is not intended to amount to advice on which you
                  should rely. You must obtain professional or specialist advice
                  before taking, or refraining from, any action on the basis of
                  the content on our site.
                </p>
                <p>
                  Although we make reasonable efforts to update the information
                  on our site, we make no representations, warranties or
                  guarantees, whether express or implied, that the content on
                  our site is accurate, complete or up-to-date.
                </p>
              </section>
              <section id='policy8' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Limitation of our liability
                </h5>
                <p>
                  Nothing in these terms and conditions excludes or limits our
                  liability for death or personal injury arising from our
                  negligence, or our fraud or fraudulent misrepresentation, or
                  any other liability that cannot be excluded or limited by
                  English law.
                </p>
                <p>
                  To the extent permitted by law, we exclude all conditions,
                  warranties, representations or other terms which may apply to
                  our site or any content on it, whether express or implied.
                </p>
                <p>
                  We will not be liable to any user for any loss or damage,
                  whether in contract, tort (including negligence), breach of
                  statutory duty, or otherwise, even if foreseeable, arising
                  under or in connection with:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    use of, or inability to use, our site;
                  </li>
                  <li className='nav-item mb-2'>
                    use of or reliance on any content displayed on our site; or
                  </li>
                  <li className='nav-item mb-2'>
                    use of the services or goods of any advertiser on our site.
                    If you are a business user, please note that in particular,
                    we will not be liable for
                  </li>
                  <li className='nav-item mb-2'>
                    loss of profits, sales, business, or revenue;
                  </li>
                  <li className='nav-item mb-2'>business interruption;</li>
                  <li className='nav-item mb-2'>
                    loss of anticipated savings;
                  </li>
                  <li className='nav-item mb-2'>
                    loss of business opportunity, goodwill or reputation; or
                  </li>
                  <li className='nav-item mb-2'>
                    any indirect or consequential loss or damage.
                  </li>
                </ul>
                <p>
                  If you are a consumer user, please note that we only provide
                  our site for domestic and private use. You agree not to use
                  our site for any commercial or business purposes, and we have
                  no liability to you for any loss of profit, loss of business,
                  business interruption, or loss of business opportunity.
                </p>
                <p>
                  We will not be liable for any loss or damage caused by a
                  virus, distributed denial-of-service attack, or other
                  technologically harmful material that may infect your computer
                  equipment, computer programs, data or other proprietary
                  material due to your use of our site or to your downloading of
                  any content on it, or on any website linked to it.
                </p>
                <p>
                  We assume no responsibility for the content of websites linked
                  on our site. Such links should not be interpreted as
                  endorsement by us of those linked websites. We will not be
                  liable for any loss or damage that may arise from your use of
                  them.
                </p>
                <p>
                  Stockappy Ltd assumes no responsibility or liability for any
                  of the products or services provided by organisations listed
                  on this website. Should you decide to appoint any of these
                  organisations to work for you, the contract will be directly
                  between you and the organisation on their terms and
                  conditions. Stockappy Ltd has no contractual involvement, and
                  will not be liable in contract or otherwise for the products
                  and services provided to you by these organisations.
                </p>
                <p>
                  Please note that although these organisations have attempted
                  to ensure that any quotes provided are as accurate as possible
                  based on the information you have provided, they are not
                  binding in any way. Our partners may contact you directly to
                  discuss the quotes provided.
                </p>
              </section>
              <section id='policy9' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Uploading content to our site
                </h5>
                <p>
                  Whenever you make use of a feature that allows you to upload
                  content to our site, or to make contact with other users of
                  our site, you must comply with the content standards set out
                  in our
                  <Link
                    to='/acceptable'
                    className='active mx-1 font-weight-bold'
                  >
                    Acceptable Use Policy
                  </Link>
                </p>
                <p>
                  You warrant that any such contribution does comply with those
                  standards, and you will be liable to us and indemnify us for
                  any breach of that warranty.
                </p>
                <p>
                  Any content you upload to our site will be considered
                  non-confidential and non-proprietary, and we have the right to
                  use, copy, distribute and disclose to third parties any such
                  content for any purpose.
                </p>
                <p>
                  We also have the right to disclose your identity to any third
                  party who is claiming that any content posted or uploaded by
                  you to our site constitutes a violation of their intellectual
                  property rights, or of their right to privacy.
                </p>
                <p>
                  We will not be responsible, or liable to any third party, for
                  the content or accuracy of any content posted by you or any
                  other user of our site.
                  <br /> We have the right to remove any posting you make on our
                  site if, in our opinion, your post does not comply with the
                  content standards set out in our
                  <Link
                    to='/acceptable'
                    className='active mx-1 font-weight-bold'
                  >
                    Acceptable Use Policy
                  </Link>
                </p>
                <p>
                  The views expressed by other users on our site do not
                  represent our views or values.
                </p>
              </section>
              <section id='policy10' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  User provided feedback
                </h5>
                <p>
                  Although your personal details will always be kept
                  confidential, if you opt to allow us to publish any feedback
                  that you provide (reviews of moveapp.co.uk or any members of
                  our network of service providers), then you agree that we can
                  use that feedback together with any alias you provide on
                  either moveappy.co.uk, or on other websites or printed
                  publications
                </p>
              </section>
              <section id='policy11' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Viruses</h5>
                <p>
                  We do not guarantee that our site will be secure or free from
                  bugs or viruses.
                </p>
                <p>
                  <p>
                    You are responsible for configuring your information
                    technology, computer programmes and platform in order to
                    access our site. You should use your own virus protection
                    software.
                  </p>
                </p>
                <p>
                  You must not misuse our site by knowingly introducing viruses,
                  trojans, worms, logic bombs or other material which is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack. By breaching this provision, you would commit a
                  criminal offence under the Computer Misuse Act 1990. We will
                  report any such breach to the relevant law enforcement
                  authorities and we will co-operate with those authorities by
                  disclosing your identity to them. In the event of such a
                  breach, your right to use our site will cease immediately.
                </p>
              </section>
              <section id='policy12' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Linking to our site</h5>
                <p>
                  You may link to our home page, provided you do so in a way
                  that is fair and legal and does not damage our reputation or
                  take advantage of it.
                </p>
                <p>
                  You must not establish a link in such a way as to suggest any
                  form of association, approval or endorsement on our part where
                  none exists.
                </p>
                <p>
                  You must not establish a link to our site in any website that
                  is not owned by you.
                </p>
                <p>
                  Our site must not be framed on any other site, nor may you
                  create a link to any part of our site other than the home
                  page.
                </p>
                <p>
                  We reserve the right to withdraw linking permission without
                  notice.
                </p>
                <p>
                  The website in which you are linking must comply in all
                  respects with the content standards set out in our
                  <Link
                    to='/acceptable'
                    className='active mx-1 font-weight-bold'
                  >
                    Acceptable Use Policy
                  </Link>
                </p>
                <p>
                  If you wish to make any use of content on our site other than
                  that set out above, please contact hello@moveappy.co.uk.
                </p>
              </section>
              <section id='policy13' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Third party links and resources in our site
                </h5>
                <p>
                  Where our site contains links to other sites and resources
                  provided by third parties, these links are provided for your
                  information only.
                </p>
                <p>
                  We have no control over the contents of those sites or
                  resources.
                </p>
              </section>
              <section id='policy14' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>General Terms</h5>
                <p>
                  These terms and conditions contain the entire terms governing
                  the relationship between us. No variation of these terms and
                  conditions shall be valid unless it is in writing and signed
                  on behalf of each of us.
                </p>
                <p>
                  Failure to exercise, or any delay in exercising, any right or
                  remedy provided under these terms and conditions or by law
                  shall not constitute a waiver of that (or any other) right or
                  remedy, nor shall it preclude or restrict any further exercise
                  of that (or any other) right or remedy.
                </p>
                <p>
                  A person who is not a party to these terms and conditions
                  shall not have any rights under or in connection with them.
                </p>
              </section>
              <section id='policy15' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Applicable law</h5>
                <p>
                  Please note that these terms and conditions are governed by
                  English law. You and we both agree to that the courts of
                  England and Wales will have exclusive jurisdiction to decide a
                  dispute involving these terms and conditions.
                </p>
                <p>
                  Stockappy Ltd, Office 113 Challenge House, 616 Mitcham Road,
                  Croydon, CR0 3AA
                </p>
                <p>
                  <i className='font-weight-bold text-body'>
                    This page was last reviewed and updated on 12st March 2021.
                  </i>
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
