import React, { useState } from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
// icons
import { ReactComponent as PinIcon } from '../../../images/icons/pin.svg';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';
import { ReactComponent as FloorPlanIcon } from '../../../images/icons/floor-plan.svg';
import { SimpleMapMbl } from '../../map/propDetailMbl';
import { Modal, TabContent, TabPane } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import classnames from 'classnames';

export const MobilePropertyView = ({ property }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [modePicOne, setModalPicOne] = useState(false);
  const [modePicTwo, setModalPicTwo] = useState(false);
  const [modePicThree, setModalPicThree] = useState(false);

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { viewIframe, floorImages, images, cgiIframe, title } = property || {};
  return (
    <div>
      <div className='d-md-none'>
        <ul className='nav pt-3 pb-2 mb-2 border-bottom '>
          <li className='mr-3'>
            <div
              className={`link-line-2 tab-title mb-0 ${classnames({
                active: activeTab === '1',
              })}`}
              onClick={() => {
                toggleTab('1');
              }}
            >
              <div className='pb-1'>Property Gallery</div>
            </div>
          </li>
          <li className='mr-3'>
            <div
              className={`link-line-2 tab-title mb-0 ${classnames({
                active: activeTab === '2',
              })}`}
              onClick={() => {
                toggleTab('2');
              }}
            >
              <div className='pb-1'>Property Video</div>
            </div>
          </li>
          <li>
            <div
              className={`link-line-2 tab-title mb-0 ${classnames({
                active: activeTab === '3',
              })}`}
              onClick={() => {
                toggleTab('3');
              }}
            >
              <div className='pb-1'>360° Virtual Tour</div>
            </div>
          </li>
        </ul>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <section>
              <div>
                <div>
                  <div className='d-md-none'>
                    <Carousel showArrows={false} showIndicators={false}>
                      {images?.map(value => (
                        <div
                          onClick={() => setModalPicThree(!modePicThree)}
                          className='item-size-img'
                        >
                          <img alt='' className='w-100 h-100' src={value} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <Modal
                    className='m-0 m-sm-auto rounded-0'
                    contentClassName='border-0 rounded-0'
                    centered
                    isOpen={modePicThree}
                    toggle={() => setModalPicThree(false)}
                  >
                    <div className='d-flex flex-column h-100 bg-black'>
                      {/* Header Start */}
                      <div className='d-flex align-items-center py-3 px-3 fixed-top'>
                        <button
                          onClick={() => setModalPicThree(false)}
                          type='button'
                          className='btn p-0  rounded-0'
                        >
                          <SvgBox
                            rotate='180'
                            size='15px'
                            className='text-white'
                          >
                            <ArrowIcon />
                          </SvgBox>
                        </button>
                        <h5 className='mb-0 text-capitalize text-white mx-auto font-weight-light'>
                          {title}
                        </h5>
                      </div>
                      {/* Header End */}
                      <div>
                        <Carousel
                          className='d-flex justify-content-center align-items-center h-100vh'
                          showThumbs={false}
                        >
                          {images?.map(value => (
                            <div
                              onClick={() => setModalPicThree(!modePicThree)}
                              className='item-size-img'
                            >
                              <img alt='' className='w-100 h-100' src={value} />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </section>
          </TabPane>
          <TabPane tabId='2'>
            {viewIframe !== null ? (
              <section>
                <iframe
                  className='w-100'
                  title='theSilk'
                  src={viewIframe}
                  style={{
                    border: '0px #ffffff none',
                    name: 'theSilkDistrict',
                    scrolling: 'no',
                    frameborder: '0',
                    marginheight: '0px',
                    marginwidth: '0px',
                    height: '300px',
                  }}
                  allowFullScreen
                />
              </section>
            ) : null}
          </TabPane>
          <TabPane tabId='3'>
            {cgiIframe !== null ? (
              <section>
                <iframe
                  className='w-100'
                  title='tada'
                  src={cgiIframe}
                  frameborder='0'
                  allowfullscreen
                  style={{ height: '300px' }}
                  allow='xr-spatial-tracking'
                />
              </section>
            ) : null}
          </TabPane>
        </TabContent>
      </div>
      <div className='border-top border-bottom py-3 px-2 row mx-0 mb-3 d-md-none'>
        <div
          className={`${
            floorImages.length > 0 ? 'col-6' : 'col-12'
          } px-0 border-right`}
        >
          <div className='d-flex align-items-center justify-content-center'>
            <button
              onClick={() => setModalPicOne(true)}
              type='button'
              className='btn p-0'
            >
              <div className='d-flex align-items-center'>
                <SvgBox size='15px'>
                  <PinIcon />
                </SvgBox>
                <span className='ml-2  font-weight-bold'>Map</span>
              </div>
            </button>
          </div>
          <Modal
            className='m-0 m-sm-auto rounded-0'
            contentClassName='border-0 rounded-0'
            centered
            isOpen={modePicOne}
            toggle={() => setModalPicOne(false)}
          >
            <div className='d-flex flex-column h-100'>
              {/* Header Start */}
              <div className='d-flex align-items-center py-1 px-3 border-bottom fixed-top bg-white'>
                <h5 className='mb-0 text-capitalize'>Map</h5>
                <button
                  onClick={() => setModalPicOne(false)}
                  type='button'
                  className='btn p-0 ml-auto rounded-0'
                >
                  <h1 className='mb-0 font-weight-light'>&times;</h1>
                </button>
              </div>
              {/* Header End */}
              <div className='flex-fill d-flex align-items-center'>
                <SimpleMapMbl property={property} />
              </div>
            </div>
          </Modal>
        </div>
        {floorImages.length > 0 ? (
          <div className='col-6 px-0'>
            <div className='d-flex align-items-center justify-content-center'>
              <button
                onClick={() => setModalPicTwo(true)}
                type='button'
                className='btn p-0'
              >
                <div className='d-flex align-items-center'>
                  <SvgBox size='15px'>
                    <FloorPlanIcon />
                  </SvgBox>
                  <span className='ml-2  font-weight-bold'>Floor plans</span>
                </div>
              </button>
            </div>
            <Modal
              className='m-0 m-sm-auto rounded-0'
              contentClassName='border-0 rounded-0'
              centered
              isOpen={modePicTwo}
              toggle={() => setModalPicTwo(false)}
            >
              <div className='d-flex flex-column h-100'>
                {/* Header Start */}
                <div className='d-flex align-items-center py-1 px-3 border-bottom fixed-top'>
                  <h5 className='mb-0 text-capitalize'>Floor plans</h5>
                  <button
                    onClick={() => setModalPicTwo(false)}
                    type='button'
                    className='btn p-0 ml-auto rounded-0'
                  >
                    <h1 className='mb-0 font-weight-light'>&times;</h1>
                  </button>
                </div>
                {/* Header End */}
                <div className='flex-fill row mx-0 flex-nowrap align-items-center overflow-auto scroll-hide'>
                  {floorImages.map(value => (
                    <div
                      className='col-10 px-2 mx-auto'
                      style={{ height: '80%' }}
                    >
                      <img
                        alt=''
                        className='w-100 h-100 border shadow-sm'
                        src={value}
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </div>
  );
};
