import React, { useState } from 'react';
import { Progress } from 'reactstrap';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as IconGrid } from '../../../images/icons/grid.svg';
import { ReactComponent as IconList } from '../../../images/icons/list.svg';
import Card from '../card';
import 'react-multi-carousel/lib/styles.css';
import { ServiceAdsOnCityPage } from '../../service-provider/serviceAdsOnAllPropByCity';
import { Link } from 'react-router-dom';

export const PropertiesSection = ({
  properties,
  count,
  title,
  showMoreHandler,
  type,
}) => {
  const [cardView, setCardView] = useState('list');
  const [filterType, setFilterType] = useState('listing');

  return (
    <div className='h-100 pb-5'>
      <div className='search-container'>
        <section>
          <div className='row'>
            <div className='col'>
              <section className=' pos-r'>
                <div className='text-center py-3 py-md-5'>
                  <h2 className='mb-0 font-weight-bolder text-body text-capitalize'>
                    {title}
                  </h2>
                  {/* <div className='d-flex align-items-center justify-content-center mt-4'>
                    <div className=' bg-light shadow rounded-pill'>
                      <button
                        type='button'
                        onClick={() => setFilterType('listing')}
                        className={
                          filterType !== 'listing'
                            ? 'btn py-2 bg-light rounded-pill ml-n2'
                            : 'btn py-2 bg-primary text-white font-weight-bold rounded-pill ml-n2'
                        }
                      >
                        Listing
                      </button>
                      <button
                        type='button'
                        onClick={() => setFilterType('partners')}
                        className={
                          filterType === 'partners'
                            ? 'btn py-2  bg-primary text-white font-weight-bold rounded-pill position-relative'
                            : 'btn py-2  bg-light rounded-pill'
                        }
                      >
                        Partners
                      </button>
                    </div>
                  </div> */}
                </div>
              </section>
              {filterType === 'listing' ? (
                <section className='container'>
                  <div className='row'>
                    <div className='col px-0'>
                      <div className='row mx-0 mb-3'>
                        <div className='col'>
                          <span className='text-body-50 py-2'>
                            <b>{properties?.length}</b> results
                          </span>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='row w-100 justify-content-end mx-0'>
                            <div className='d-flex justify-content-end'>
                              <div className='pl-3'>
                                <button
                                  type='button'
                                  onClick={() => setCardView('grid')}
                                  className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                                  style={{ height: 40, width: 40 }}
                                >
                                  <SvgBox size='40px'>
                                    <IconGrid />
                                  </SvgBox>
                                </button>
                              </div>
                              <div className='pl-3'>
                                <button
                                  type='button'
                                  onClick={() => setCardView('list')}
                                  className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                                  style={{ height: 40, width: 40 }}
                                >
                                  <SvgBox size='40px'>
                                    <IconList />
                                  </SvgBox>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {cardView === 'list' ? (
                        <>
                          {properties?.length > 0 ? (
                            <div className='row mx-0'>
                              {properties.map((item, i) => (
                                <Card
                                  className={`col-12 col-lg-6 mobile-card`}
                                  mobileClassName='mobile-items-show w-100 bg-gray'
                                  boxClassName='row mx-0'
                                  colOneClassName='col-4 col-md px-0'
                                  colTwoClassName='col-8 col-md-7 px-3 px-md-4 d-flex flex-column'
                                  imageClassName='card-item-img h-100'
                                  userClassName='d-none'
                                  mobileUserClassName='d-none'
                                  item={item}
                                  index={i}
                                />
                              ))}
                            </div>
                          ) : (
                            <>
                              <div className='section-title w-100 text-center py-5 mt-5'>
                                <div
                                  className='mx-auto d-flex flex-column'
                                  style={{ width: '70vmin' }}
                                >
                                  <img
                                    className='flex-fill w-100'
                                    src={
                                      require('../../../images/no-properties.png')
                                        .default
                                    }
                                    alt='no-properties'
                                  />
                                  <h5 className='title'>
                                    Results not found...
                                  </h5>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {properties.length > 0 ? (
                            <div className='row mx-0'>
                              {properties.map((item, i) => (
                                <Card
                                  className={`col-12 col-md-6 col-lg-4 col-xl-4`}
                                  item={item}
                                  index={i}
                                />
                              ))}
                            </div>
                          ) : (
                            <>
                              <div className='section-title w-100 text-center py-5 mt-5'>
                                <div
                                  className='mx-auto d-flex flex-column'
                                  style={{ width: '70vmin' }}
                                >
                                  <img
                                    className='flex-fill w-100'
                                    src={
                                      require('../../../images/no-properties.png')
                                        .default
                                    }
                                    alt='no-properties'
                                  />
                                  <h5 className='title'>
                                    Results not found...
                                  </h5>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className='d-none d-lg-block'>
                      <div className='position-sticky' style={{ top: 80 }}>
                        {type === 'byCity' ? (
                          <ServiceAdsOnCityPage city={title} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <div className='font-weight-lighter'>
                      Showing {properties.length} out of {count} Properties
                    </div>
                    <div className='mt-2 mb-3 px-0 col-10 col-md-5 mx-auto'>
                      <Progress value={(properties.length / count) * 100} />
                    </div>
                    {count > properties.length ? (
                      <button
                        className='link-line bg-transparent'
                        style={{ border: 'none' }}
                        onClick={showMoreHandler}
                      >
                        show more
                      </button>
                    ) : null}
                  </div>
                </section>
              ) : (
                <section className='container'>
                  <div className='card col-md-4 py-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        height={100}
                        width={100}
                        className='object-fit-contain'
                        src='https://bdibucket1.s3.amazonaws.com/Croydon-Logo.png'
                        alt=''
                      />
                      <div className='d-flex flex-column ml-4'>
                        <h5 className=' font-weight-bold text-uppercase '>
                          Croydon Commericial
                        </h5>
                        <small>
                          Croydon Commercial c/o White Label, 1st Floor Suffolk
                          House, George Street, Croydon.
                        </small>

                        <div className='d-flex justify-content-between mt-3'>
                          <Link
                            to='/partner/croydon-commercial-jYbgDRyjEk1cCP4aauvrou'
                            className='btn btn-secondary font-weight-bold'
                          >
                            View Listing
                          </Link>
                          <Link
                            to='/partner/croydon-commercial-jYbgDRyjEk1cCP4aauvrou/contact'
                            className='btn btn-light font-weight-bold'
                          >
                            Contact Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
