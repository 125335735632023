import axios from 'axios';
import { useQuery } from 'react-query';

const getUserPartners = async userId => {
  try {
    const res = await axios.get('/partner/get', {
      params: { userId },
    });

    return res.data?.data?.partnerList;
  } catch (err) {
    throw err.response;
  }
};

export const useGetPartners = ({ userId }) => {
  const {
    isLoading,
    data: partners,
    error,
  } = useQuery([userId, 'partners'], () => getUserPartners(userId));

  return {
    isLoading,
    partners,
    error,
  };
};
