import React from 'react';
import { Table } from 'reactstrap';
import { DeskItem } from './deskItem';
import { MblItem } from './mblItem';

export const AgencyMessagesTable = (props) => {
  const { messages } = props;

  return (
    <>
      <Table striped borderless hover className='shadow  d-none d-lg-table'>
        <thead>
          <tr className='text-capitalize'>
            <th className='border-top-0 text-secondary py-3 rounded-left-xl font-weight-bold'>
              #
            </th>
            <th className='border-top-0 text-secondary py-3 font-weight-bold'>
              Email
            </th>
            <th className='border-top-0 text-secondary py-3 font-weight-bold'>
              Message
            </th>
            {/* <th className='border-top-0 text-secondary py-3 text-center font-weight-bold rounded-right-xl'>
              Actions
            </th> */}
          </tr>
        </thead>
        <tbody>
          {messages.map((value, index) => (
            <DeskItem msg={value} index={index} />
          ))}
        </tbody>
      </Table>
      {messages.map((value, ind) => (
        <MblItem msg={value} index={ind} />
      ))}
    </>
  );
};
