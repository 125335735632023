import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import PropertyDetailsSection from '../../components/properties-components/property-details';

import Footer from '../../components/global-components/footer';
import NavbarMobile from '../../components/global-components/NavbarMobile';

import { Error } from '../../utils/errorComponent';
import PropertyBottomStatus from '../../components/properties-components/property-details/PropertyBottomStatus';
import CroydonProperty from '../Croydon/CroydonProperty';
import { useFetchPropertyBySlug } from '../../hooks/useFetchPropertyBySlug';
import { Loader } from '../../components/Loader';
import { ScrollToTop } from '../../components/ScrollToTop';

const PropertyDetail = () => {
  const { id } = useParams();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const { isLoading, data, error } = useFetchPropertyBySlug({
    slug: id,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div className='fade-in'>
      <ScrollToTop />
      {/* {data?.branding?.name === 'develop-croydon' ? (
        <>
          <CroydonProperty property={data} isCroydon={true} />
        </>
      ) : (
        <> */}
      <Navbar className='d-none d-md-flex' />
      <NavbarMobile className='d-md-none' />
      <PropertyDetailsSection property={data} />
      <PropertyBottomStatus data={data} />
      <Footer />
      {/* </>
      )} */}
    </div>
  );
};

export default PropertyDetail;
