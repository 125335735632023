import React from 'react';
import { numFormatter } from '../../utils/numFormatter';

export const FormatPrice = ({ item }) => {
  const { category, priceWeek, priceType, price } = item || {};

  return (
    <div>
      {category?.value === 'to rent' &&
      priceType?.value !== 'price on application (poa)' ? (
        <>
          <div className=' text-black-50 mb-0 font-weight-bold'>
            £{numFormatter(priceWeek)}/per week
          </div>
          <div className=' mr-1 font-weight-bold'>
            £{numFormatter(price)}/per month
          </div>
        </>
      ) : (
        <>
          {priceType?.value === 'price on application (poa)' ? (
            <span className='mr-1 font-weight-bold'>POA</span>
          ) : priceType?.value === 'offers in excess of (oieo)' ? (
            <span className='mr-1 font-weight-bold'>
              OIEO £{numFormatter(price)}
            </span>
          ) : priceType?.value === 'price per sq ft / sq m' ? (
            <span className='mr-1 font-weight-bold'>
              £{numFormatter(price)}{' '}
              {item?.unit?.value === 'square feet (sq. ft)' ? 'psf' : 'psm'}
            </span>
          ) : (
            <span className='mr-1 font-weight-bold'>
              £{numFormatter(price)}
            </span>
          )}
        </>
      )}
    </div>
  );
};
