import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from '../registerPremium';
import StripeScriptLoader from 'react-stripe-script-loader';

const Stripe = (props) => {
  return (
    <>
      <StripeScriptLoader
        uniqueId='myUniqueId'
        script='https://js.stripe.com/v3/'
        loader=''
      >
        <StripeProvider apiKey='pk_test_jyP40HEn8hXEjOLrLOPKfy8Q00tVhFuOWz'>
          <Elements>
            <CheckoutForm userType={props.userType} />
          </Elements>
        </StripeProvider>
      </StripeScriptLoader>
    </>
  );
};

export default Stripe;
