import React, { useState } from 'react';
import { PropertyItem } from './propertyItem';

export const PropertyList = (props) => {
  const { protocol, host, pathname } = window.location;
  const { activeProperties, inActiveProperties, queryData } = props;

  const [btn, setBtn] = useState(
    queryData.get('state') === 'active' ? true : false
  );
  const [activeList, setActiveList] = useState(
    queryData.get('state') === 'active' ? true : false
  );

  return (
    <>
      <section className=' min-vh-100 '>
        <div className='container '>
          <header className='col col-md-10 col-lg-9 mx-auto'>
            <div className='d-flex align-items-end justify-content-between'>
              <h4 className='mb-0'>Listings</h4>
              <div className=' bg-light shadow rounded-pill'>
                <button
                  onClick={() => {
                    setBtn(true);
                    setActiveList(true);
                    queryData.set('state', 'active');
                    const searchUrl = `${protocol}//${host}${pathname}?${queryData.toString()}`;
                    window.history.pushState(
                      { path: searchUrl },
                      '',
                      searchUrl
                    );
                  }}
                  type='button'
                  className={
                    btn
                      ? 'btn py-2  bg-primary text-white font-weight-bold rounded-pill position-relative'
                      : 'btn py-2  bg-light rounded-pill'
                  }
                >
                  Active
                </button>
                <button
                  onClick={() => {
                    setBtn(false);
                    setActiveList(false);
                    queryData.set('state', 'inActive');
                    const searchUrl = `${protocol}//${host}${pathname}?${queryData.toString()}`;
                    window.history.pushState(
                      { path: searchUrl },
                      '',
                      searchUrl
                    );
                  }}
                  type='button'
                  className={
                    btn
                      ? 'btn py-2 bg-light rounded-pill ml-n2'
                      : 'btn py-2 bg-primary text-white font-weight-bold rounded-pill ml-n2'
                  }
                >
                  Inactive
                </button>
              </div>
            </div>
          </header>
          {activeList ? (
            <>
              {activeProperties.length > 0 ? (
                <main className='col col-md-10 col-lg-9 mx-auto py-5 my-1'>
                  {activeProperties.map((item) => (
                    <PropertyItem key={item._id} item={item} />
                  ))}
                </main>
              ) : (
                <div className='section-title text-center py-5 mt-5'>
                  <h2 className='title'>No Active Properties...</h2>
                </div>
              )}
            </>
          ) : (
            <>
              {inActiveProperties.length > 0 ? (
                <main className='col col-md-10 col-lg-9 mx-auto py-5 my-1'>
                  {inActiveProperties.map((item) => (
                    <PropertyItem key={item._id} item={item} />
                  ))}
                </main>
              ) : (
                <div className='section-title text-center py-5 mt-5'>
                  <h4 className='title'>No InActive Properties...</h4>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};
