import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import {
  getOptionsByValue,
  GetTypeOptions,
  formatGroupLabel,
} from '../../../../utils/optionFormatter';
import { LocationSuggestion } from '../../../LocationSuggestion';
import { CustomSelect } from '../../../section-components/CustomSelect';

export const Intro = () => {
  const { errors, control, getValues } = useFormContext();

  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const defaultLocation = getValues('location');

  return (
    <Col className='p-0'>
      <Row>
        <Col md={12}>
          <div>
            <Row className='align-items-center'>
              <Col className='mb-4' sm={12}>
                <label className='text-uppercase mb-2'>Title</label>
                <div className=' mb-0'>
                  <Controller
                    control={control}
                    name='title'
                    rules={{ required: true }}
                    placeholder='Property Name / Number'
                    as={<Input className='rounded-0 custom-input-padding' />}
                  />

                  <ErrorMessage
                    errors={errors}
                    name='title'
                    message='This field is required.'
                    render={({ message }) => (
                      <p className='text-danger'>{message}</p>
                    )}
                  />
                </div>
              </Col>
              <Col className='mb-4' sm={12}>
                <label className='text-uppercase mb-2'>Location</label>
                <LocationSuggestion
                  propertyValue={'location'}
                  defaultValue={defaultLocation}
                  placeholderValue='Postcode (automated address match)'
                />
              </Col>
            </Row>

            <Row className='align-items-center'>
              <Col className='mb-4' md={6} sm={12}>
                <label className='text-uppercase mb-2'>Type</label>
                <Controller
                  as={CustomSelect}
                  options={GetTypeOptions(options)}
                  isClearable
                  rules={{ required: true }}
                  placeholder='Select type'
                  isLoading={isLoading}
                  formatGroupLabel={formatGroupLabel}
                  name='type'
                  control={control}
                />

                <ErrorMessage
                  errors={errors}
                  name='type'
                  message='This field is required.'
                  render={({ message }) => (
                    <p className='text-danger'>{message}</p>
                  )}
                />
              </Col>
              <Col className='mb-4' md={6} sm={12}>
                <label className='text-uppercase mb-2'>Category</label>
                <Controller
                  as={CustomSelect}
                  isClearable
                  options={getOptionsByValue('category', options)}
                  rules={{ required: true }}
                  placeholder='Select Category'
                  isLoading={isLoading}
                  name='category'
                  control={control}
                />

                <ErrorMessage
                  errors={errors}
                  name='type'
                  message='This field is required.'
                  render={({ message }) => (
                    <p className='text-danger'>{message}</p>
                  )}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
};
