import React from 'react';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import { useLocation } from 'react-router';

function ScrollToTopOnMount() {
  React.useLayoutEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return null;
}

export const Cookies = () => {
  const { hash } = useLocation();
  return (
    <section className='d-flex flex-column vh-100'>
      <ScrollToTopOnMount />
      <Navbar />
      <main className=' container-terms py-5 flex-fill'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-xl-3 d-none d-lg-block'>
              <ul
                className='nav flex-column position-sticky'
                style={{ top: 80 }}
              >
                <li className='nav-item mb-2'>
                  <div>
                    <div className='h4 text-dark font-weight-bold px-3'>
                      Cookies
                    </div>
                  </div>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy0'
                    className={`nav-link ${
                      hash === '#policy0' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    What are cookies?
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy1'
                    className={`nav-link ${
                      hash === '#policy1' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Local Storage
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy2'
                    className={`nav-link ${
                      hash === '#policy2' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Change your optional cookie settings
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy3'
                    className={`nav-link ${
                      hash === '#policy3' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    How do I change my cookie settings?
                  </a>
                </li>
              </ul>
            </div>
            <div className='col'>
              <section id='policy0' className='mb-5'>
                <h3 className='mb-4 font-weight-bold'>Cookies</h3>
                <h4 className='mb-4 font-weight-bold'>What are cookies?</h4>
                <p>
                  Cookies are very small text files which are sent to your
                  browser when you view a webpage or application. They are
                  widely used to make websites work more efficiently as well as
                  to provide information to the owners of the site.
                </p>

                <p>
                  Our website uses cookies to distinguish you from other users
                  of our website. This helps us to provide you with a good
                  experience when you browse our website and allows us to
                  improve our site.
                </p>
              </section>
              <section id='policy1' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>Local Storage</h4>
                <p>
                  In a similar way to cookies, local storage allows us to use a
                  small amount of space on your machine to store information. We
                  use this information to improve your journey through our site,
                  by remembering previous actions and making them the default
                  for future interactions with the site. Local storage is
                  persistent and does not expire.
                </p>
                <p>
                  Cookies and local storage can be categorised into different
                  types and are used in the following ways:
                </p>
                <ul
                  className='nav flex-column'
                  style={{ fontFamily: 'sans-serif', fontSize: 13 }}
                >
                  <li className='nav-item mb-2'>
                    1. Strictly Necessary - these are needed for the website to
                    function and can’t be switched off. However, a browser can
                    be set to block them, but please note that this will stop
                    you from being able to use some parts of our site,
                    particularly our quote forms. Please see
                    <a
                      href='/'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      How to turn off my cookie settings?
                    </a>
                    to see how to turn them off for the browser you use.
                  </li>
                  <li className='nav-item mb-2'>
                    2. Optional – you have the option to turn these off. They
                    include:
                    <ul className=' flex-column mt-2'>
                      <li className='nav-item mb-2'>
                        Functional – these allow us to provide a more
                        personalised service.
                      </li>
                      <li className='nav-item mb-2'>
                        Performance - these are anonymous and allow us to count
                        visits from different traffic sources. This helps us to
                        find out which pages are popular, so we can improve our
                        content. Some of these cookies are placed by 3rd Party
                        tools, for eg. Google Analytics. These can be turned off
                        in your browser settings. Please see
                        <a
                          href='/'
                          target='_blank'
                          rel='noreferrer'
                          className='mx-1 font-weight-bold active'
                        >
                          How to turn off my cookie settings?
                        </a>
                      </li>
                      <li className='nav-item mb-2'>
                        Marketing – These help us to recommend content relevant
                        to your interests, based on how you use our site. When
                        you register with our site, these preferences are linked
                        to your email address. If you’d rather we forget your
                        preferences please click on the link below. When you
                        decide to clear your preferences, the local storage and
                        cookies are reset, no longer linking them to your email
                        address.
                      </li>
                    </ul>
                  </li>
                </ul>
              </section>

              <section id='policy3' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>
                  How do I change my cookie settings?
                </h4>
                <p>
                  Most web browsers allow some control of most cookies through
                  the browser settings. To find out more about cookies,
                  including how to see what cookies have been set, visit
                  <a
                    href='http://www.aboutcookies.org'
                    rel='noreferrer'
                    target='_blank'
                    className='mx-1 font-weight-bold active'
                  >
                    www.aboutcookies.org
                  </a>
                  or
                  <a
                    href='http://www.allaboutcookies.org'
                    rel='noreferrer'
                    target='_blank'
                    className='mx-1 font-weight-bold active'
                  >
                    www.allaboutcookies.org.
                  </a>
                  Find out how to manage cookies on popular browsers:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    <a
                      href='https://www.google.com/chrome'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Google Chrome
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a
                      href='http://www.MicrosoftEdge.com'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Microsoft Edge
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a
                      href='http://www.MozillaFirefox.com'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Mozilla Firefox
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a
                      href='http://www.MicrosoftInternetExplorer.com'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Microsoft Internet Explorer
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a
                      href='http://www.Opera.com'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Opera
                    </a>
                  </li>
                  <li className='nav-item mb-2'>
                    <a
                      href='http://www.AppleSafari.com'
                      target='_blank'
                      rel='noreferrer'
                      className='mx-1 font-weight-bold active'
                    >
                      Apple Safari
                    </a>
                  </li>
                </ul>
                <p>
                  To find information relating to other browsers, visit the
                  browser developer's website. To opt out of being tracked by
                  Google Analytics across all websites, visit
                  <a
                    href='http://tools.google.com/dlpage/gaoptout'
                    rel='noreferrer'
                    target='_blank'
                    className='mx-1 font-weight-bold active'
                  >
                    http://tools.google.com/dlpage/gaoptout.
                  </a>
                </p>
                <p>
                  <b className='text-body'>
                    This page was last updated 13 March 2021.
                  </b>
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </section>
  );
};
