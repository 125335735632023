import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Row, Modal } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';

export const PropertyDesktopGallery = ({ property }) => {
  const { images, isFeatured, category } = property;
  const [modePic, setModalPic] = useState(false);
  const [activeSlide, setActiveSlide] = useState(property.images[0]);
  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div>
      <Row className='d-none d-md-flex mb-4'>
        <Col className='pr-1'>
          <div onClick={() => setModalPic(true)} className='h-100 py-1 d-block'>
            <div
              className='w-100 h-100 bg-image-cover rounded-sm'
              style={{
                backgroundImage: `url(${images[0]})`,
                minHeight: '390px',
              }}
            >
              <div className='row mx-0 p-3'>
                {isFeatured && (
                  <span className='badge badge-primary p-2 text-uppercase'>
                    Featured
                  </span>
                )}

                <span className='badge badge-dark p-2 text-uppercase ml-auto'>
                  {category?.label}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Modal
              size='lg'
              fullscreen
              contentClassName='border-0 p-2'
              centered
              isOpen={modePic}
              toggle={() => setModalPic(false)}
            >
              <div>
                <Carousel showStatus={true} selectedItem={activeSlide}>
                  {images?.map(value => (
                    <div key={value} className='item-size-img'>
                      <img alt='' src={value} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Modal>
          </div>
        </Col>
        <Col md={6} lg={5} xl={5}>
          <div className='row row-cols-2'>
            {images.slice(1).map((value, index) => (
              <>
                {index < 4 ? (
                  <div
                    onClick={() => {
                      setModalPic(!modePic);
                      setActiveSlide(index + 1);
                    }}
                    className='p-1 position-relative'
                    style={{ height: 200 }}
                  >
                    <LazyLoadImage
                      alt=''
                      placeholderSrc={publicUrl + 'assets/img/placeholder.png'}
                      className='w-100 h-100 rounded-sm'
                      src={value}
                      useIntersectionObserver={true}
                      wrapperClassName='w-100 h-100'
                      style={{ objectFit: 'cover' }}
                    />
                    {index === 3 && images.length > index + 2 ? (
                      <span
                        className='pos-center font-weight-bold bg-white icon-box font-weight-bold small'
                        style={{ height: 40, width: 40 }}
                      >
                        {images.length > index + 2 ? (
                          <>+{images.length - (index + 2)}</>
                        ) : null}
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};
