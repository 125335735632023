import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import { getSelectedOption } from '../../../../utils/optionFormatter';
import { getParam } from '../../../../utils/urlHelper';

const daysOptions = [
  { value: 1, label: 'Last 24 hours' },
  { value: 3, label: 'Last 3 days' },
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
];

export const useSearchState = () => {
  const { getValues, reset } = useFormContext();
  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const [text] = useState(getParam('text', 'single'));

  const [categoryDefault] = useState(getParam('category', 'single'));
  const [tenure] = useState(getParam('tenure', 'single'));
  const [unit] = useState(getParam('unit', 'single'));
  const [parking] = useState(getParam('parking', 'single'));
  const [accessibility] = useState(getParam('accessibility', 'single'));
  const [outdoorSpace] = useState(getParam('outdoorSpace', 'single'));
  const [type] = useState(getParam('type', 'single'));
  const [availableTerm] = useState(getParam('availableTerm', 'single'));
  const [distancefromeastcroydon] = useState(
    getParam('distancefromeastcroydon', 'single')
  );
  const [distancefromwestcroydon] = useState(
    getParam('distancefromwestcroydon', 'single')
  );
  const [DDACompliant] = useState(getParam('DDACompliant', 'single'));

  const [days] = useState(getParam('addedOn', 'single'));
  const [features] = useState(getParam('features', 'single'));

  useEffect(() => {
    const categoryValue =
      !isLoading &&
      categoryDefault &&
      getSelectedOption(options, categoryDefault, 'category');

    const tenureDefault =
      !isLoading && tenure && getSelectedOption(options, tenure, 'tenure');

    const unitDefault =
      !isLoading && unit && getSelectedOption(options, unit, 'unit');

    const parkingDefault =
      !isLoading && parking && getSelectedOption(options, parking, 'parking');

    const accessibilityDefault =
      !isLoading &&
      accessibility &&
      getSelectedOption(options, accessibility, 'accessibility');

    const outdoorSpaceDefault =
      !isLoading &&
      outdoorSpace &&
      getSelectedOption(options, outdoorSpace, 'outdoor space');

    const typeDefault =
      !isLoading && type && getSelectedOption(options, type, 'property type');

    const availableTermDefault =
      !isLoading &&
      availableTerm &&
      getSelectedOption(options, availableTerm, 'available term');

    const featuresDefault =
      !isLoading &&
      features &&
      getSelectedOption(options, features, 'features');

    const distancefromwestcroydonDefault =
      !isLoading &&
      distancefromwestcroydon &&
      getSelectedOption(
        options,
        distancefromwestcroydon,
        'distance from west croydon'
      );

    const distancefromeastcroydonDefault =
      !isLoading &&
      distancefromeastcroydon &&
      getSelectedOption(
        options,
        distancefromeastcroydon,
        'distance from east croydon'
      );

    const daysDefault =
      days && daysOptions.find(day => day.value === Number(days));

    reset({
      ...getValues(),
      days: daysDefault,
      text,
      features: featuresDefault,
      DDACompliant:
        DDACompliant === 'true'
          ? {
              label: 'Yes',
              value: true,
            }
          : DDACompliant === 'false'
          ? { label: 'No', value: false }
          : undefined,
      category: categoryValue,
      type: typeDefault,
      tenure: tenureDefault,
      unit: unitDefault,
      parking: parkingDefault,
      outdoorSpace: outdoorSpaceDefault,
      accessibility: accessibilityDefault,
      availableTerm: availableTermDefault,
      distancefromwestcroydon: distancefromwestcroydonDefault,
      distancefromeastcroydon: distancefromeastcroydonDefault,
    });
  }, [
    getValues,
    text,
    accessibility,
    distancefromwestcroydon,
    distancefromeastcroydon,
    availableTerm,
    categoryDefault,
    isLoading,
    options,
    outdoorSpace,
    parking,
    reset,
    tenure,
    type,
    unit,
    DDACompliant,
    days,
    features,
  ]);
};
