export const SelectThreeStyle = {
  option: styles => ({
    ...styles,
    color: 'black',
  }),
  menu: styles => ({
    ...styles,
    zIndex: 1000000000000,
  }),
  control: provided => ({
    ...provided,
    borderColor: '#009ee3',
    borderRadius: 0,
  }),
};
