import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrowRight.svg';
import { AgencyMessagesTable } from '../../components/auth-components/agencyMessages/messagesTable';

const AgencyMessages = () => {
  const { goBack } = useHistory();

  const getAgencyMessages = () => {
    return axios
      .get('/profile/messages/received')
      .then((res) => res.data.messages);
  };

  const { data, isLoading, isError } = useQuery(
    'profileMessages',
    getAgencyMessages,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <div
            class='spinner-border text-dark mt-5'
            style={{ height: '3rem', width: '3rem' }}
            role='status'
          >
            <span class='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <h2 class='mt-5'>Something Went Wrong...</h2>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Navbar />
        <section className='bg-white open-sans min-vh-100 my-4'>
          <div className='container '>
            <header>
              <div className='d-flex border-top border-bottom align-items-center justify-content-between px-1'>
                <div className=' py-2 mb-2'>
                  <div className='h4 text-capitalize mb-0 d-flex align-items-center'>
                    <span onClick={goBack}>
                      <SvgBox
                        className='mr-2 cursor-pointer '
                        rotate='180'
                        size='15px'
                      >
                        <ArrowIcon />
                      </SvgBox>{' '}
                    </span>
                    Messages
                  </div>
                </div>
              </div>
            </header>
            <main className='px-1 py-1 scroll-box'>
              <AgencyMessagesTable messages={data} />
            </main>
          </div>
        </section>
      </>
    );
  }
};

export default AgencyMessages;
