import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ConfirmDeleteModal } from './confirmDeleteModal';

import { SvgBox } from '../../../images/icons/SvgBox';

// icons
import { ReactComponent as IconBed } from '../../../images/icons/bed.svg';
import { ReactComponent as IconBath } from '../../../images/icons/bath.svg';
import { ReactComponent as IconCar } from '../../../images/icons/car.svg';
import { ReactComponent as IconRuler } from '../../../images/icons/ruler.svg';
import { ReactComponent as IconGrid } from '../../../images/icons/living-room.svg';
import { ReactComponent as IconVideo } from '../../../images/icons/video.svg';
import { ReactComponent as IconPhoto } from '../../../images/icons/photo.svg';
import { ReactComponent as IconDelete } from '../../../images/icons/delete.svg';
import { ReactComponent as IconEdit } from '../../../images/icons/edit.svg';
import { FormatPrice } from '../../common/FormatPrice';

export const PropertyItem = (props) => {
  const [showReqModal, setShowReqModal] = useState(false);
  const { item } = props;
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className='list-card-container shadow border-0 text-left card mb-5 w-100'>
      <div className='d-flex flex-column flex-lg-row justify-content-center mx-0 w-100'>
        <div className='list-card-image-size'>
          <Link to={`/property-details/${item._id}`}>
            <img
              className='h-100 w-100 '
              src={
                item?.coverImage ??
                publicUrl + 'assets/img/default-img.jpg' ??
                item?.images[0]
              }
              alt=''
            />
          </Link>
          <div className='list-card-overlay'>
            <div className='text-white d-flex flex-column h-100 w-100'>
              <div className='mt-auto w-100 p-3 row mx-0 list-card-overlay__item'>
                <div className='d-flex mr-3 text-white'>
                  <SvgBox size='18px'>
                    <IconPhoto />
                  </SvgBox>
                  <span className='ml-2'>{item.images.length}</span>
                </div>
                <div className='d-flex mr-3 text-white'>
                  <SvgBox size='18px'>
                    <IconVideo />
                  </SvgBox>
                  <span className='ml-2'>{item.floorImages.length}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='list-card-items'>
            <div className='d-flex text-uppercase justify-content-between p-3'>
              <span className='badge badge-primary font-weight-bold p-2'>
                {moment.utc(item.createdAt).format('MMM DD, YYYY')}
              </span>
              <span className='badge badge-secondary font-weight-bold p-2'>
                {item?.category?.value}
              </span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-between w-100 p-3 p-md-4'>
          <div className='mb-4'>
            <div className='d-flex align-items-end pt-2  justify-content-between w-100'>
              <h5 className='font-weight-bold mb-1 text-truncate text-capitalize'>
                <Link className='text-secondary' to={`/property/${item.slug}`}>
                  {item.title}
                </Link>
              </h5>
              <Link
                className='text-yellow'
                to={`/property/requests/${item.slug}`}
              >
                <span className='badge badge-light h6 font-weight-bold p-2'>
                  Requests
                </span>
              </Link>
            </div>
            <div className='d-flex align-items-center mb-0'>
              <div className=' text-black-50 text-capitalize '>
                {item.location},<span>{item.city}</span>
              </div>
            </div>
          </div>

          <div className='row justify-content-between border-top pt-3  '>
            <div className='col'>
              <div
                className='row justify-content-between mx-0 text-black-50  w-100 pt-1 '
                style={{ fontSize: 14, fontWeight: 500 }}
              >
                <div>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    {item?.type?.value === 'commercial' ? (
                      <>
                        <SvgBox className='mb-1' size='20px'>
                          <IconGrid />
                        </SvgBox>
                        <div className='small font-weight-bold'>
                          {item?.receptions?.value} recep
                        </div>
                      </>
                    ) : (
                      <>
                        <SvgBox className='mb-1' size='20px'>
                          <IconBed />
                        </SvgBox>
                        <div className='small font-weight-bold'>
                          {item?.bedrooms?.value} beds
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    <SvgBox className='mb-1' size='20px'>
                      <IconBath />
                    </SvgBox>
                    <div className='small font-weight-bold'>
                      {item?.bathrooms?.value} baths
                    </div>
                  </div>
                </div>
                <div>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    <SvgBox className='mb-1' size='20px'>
                      <IconCar />
                    </SvgBox>
                    <div className='small font-weight-bold'>
                      {item?.parking?.value}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='text-capitalize d-flex flex-column align-items-center'>
                    <SvgBox className='mb-1' size='20px'>
                      <IconRuler />
                    </SvgBox>
                    <div className='small font-weight-bold'>
                      {item.landArea} {item?.unit?.value}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row border-top mt-3 pt-3'>
            <div className='col'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='font-weight-bolder'>
                  <FormatPrice item={item} />
                </div>
                <div>
                  <Link
                    className='btn btn-block py-2 mt-2 btn-light font-weight-bold'
                    to={`/property/${item.slug}`}
                  >
                    Details
                  </Link>
                </div>
              </div>
            </div>
            <div className=' d-flex align-items-center justify-content-end'>
              <div className='d-flex justify-content-end'>
                <Link
                  style={{ width: 25, height: 25 }}
                  to={`/property/edit/${item.slug}`}
                  className='  p-0 d-flex align-items-center justify-content-center mr-2 d-block'
                >
                  <SvgBox className='text-primary' size='20px'>
                    <IconEdit />
                  </SvgBox>
                </Link>
                <div
                  style={{ width: 25, height: 25 }}
                  onClick={() => setShowReqModal(true)}
                  className='d-flex cursor-pointer justify-content-center align-items-center   mr-2'
                >
                  <SvgBox className='text-danger' size='18px'>
                    <IconDelete />
                  </SvgBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showReqModal ? (
        <ConfirmDeleteModal
          showReqModal={showReqModal}
          setShowReqModal={setShowReqModal}
          itemId={item._id}
          slug={item.slug}
        />
      ) : null}
    </div>
  );
};
