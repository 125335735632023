export const getSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getParam = (name, type) => {
  const queryData = getSearchParams();

  const data =
    queryData.get(name) !== null ||
    queryData.get(name) !== '' ||
    queryData.get(name) !== undefined
      ? type === 'single'
        ? queryData.get(name)
        : queryData.getAll(name)
      : queryData.delete(name);

  if (type === 'single') {
    return data;
  } else if (data?.length === 0) {
    return '';
  } else return data;
};

const appendParams = (array, paramName, params) =>
  array.map(
    item =>
      !params.getAll(paramName).includes(item.toString()) &&
      params.append(paramName, item)
  );

export const setParams = (name = '', data, type, queryData) => {
  queryData.delete(name);

  type === 'single'
    ? data !== null &&
      data !== undefined &&
      data !== '' &&
      queryData.set(name, data)
    : data.length && appendParams(data, name, queryData);
  const { protocol, host, pathname } = window.location;

  const searchUrl = `${protocol}//${host}${pathname}?${queryData.toString()}`;
  window.history.replaceState({ path: searchUrl }, '', searchUrl);
};

export const keyHelper = key => (key?.length ? key.toString() : '');
