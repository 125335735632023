import axios from 'axios';
import { useMutation } from 'react-query';
import notifications from '../components/notifications';

const contactAgency = async data => {
  try {
    const res = await axios.post('/contactus/email', data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useContactAgency = () => {
  const mutation = useMutation(contactAgency, {
    onSuccess: () => {
      notifications.success('Success', 'Email Sent!');
    },
    onError: () => {
      notifications.error('Error', 'Try again Later');
    },
  });

  return {
    mutation,
  };
};
