import React from 'react';
import { DocumentUploader } from './DocumentUploader';

export const DocumentUploads = () => {
  return (
    <section>
      <DocumentUploader name='floorImages' title='Floor Plans' uploads={2} />
      <DocumentUploader name='epcDocuments' title='EPC Documents' uploads={2} />
      <DocumentUploader
        name='termsAndConditions'
        title='Terms & Conditions (Green Clause)'
        uploads={1}
      />

      <DocumentUploader
        name='brochureDocuments'
        title='Brochures'
        uploads={1}
      />
    </section>
  );
};
