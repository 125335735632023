import React from 'react';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

function ScrollToTopOnMount() {
  React.useLayoutEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return null;
}

export const Privacy = () => {
  const { hash } = useLocation();
  return (
    <>
      <ScrollToTopOnMount />
      <Navbar />
      <main className='bg-light container-terms py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-xl-3 d-none d-lg-block'>
              <ul
                className='nav flex-column position-sticky'
                style={{ top: 80 }}
              >
                <li className='nav-item mb-2'>
                  <div>
                    <a
                      href='#policy0'
                      className={`nav-link h4 font-weight-bold ${
                        hash === '#policy0'
                          ? 'font-weight-bold text-yellow'
                          : ''
                      }`}
                    >
                      Privacy policy <br /> Website Users
                    </a>
                  </div>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy1'
                    className={`nav-link ${
                      hash === '#policy1' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Other information we may collect on you
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy2'
                    className={`nav-link ${
                      hash === '#policy2' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Tracking and cookies
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy3'
                    className={`nav-link ${
                      hash === '#policy3' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Retargeting
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy4'
                    className={`nav-link ${
                      hash === '#policy4' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Where we store your personal data
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy5'
                    className={`nav-link ${
                      hash === '#policy5' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Uses made of the information
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy6'
                    className={`nav-link ${
                      hash === '#policy6' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Disclosure of your information
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy7'
                    className={`nav-link ${
                      hash === '#policy7' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Your rights
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy8'
                    className={`nav-link ${
                      hash === '#policy8' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Changes to our privacy policy
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy9'
                    className={`nav-link ${
                      hash === '#policy9' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Privacy Policy – moveappy.co.uk partners
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy10'
                    className={`nav-link ${
                      hash === '#policy10' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Privacy policy - affiliate partners
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy11'
                    className={`nav-link ${
                      hash === '#policy11' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Where we store your data
                  </a>
                </li>
              </ul>
            </div>
            <div className='col'>
              <section id='policy0' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Privacy policy – Website Users</h4>
                <p>
                  This policy (together with our
                  <Link to='/terms' className='mx-1 active font-weight-bold'>
                    Terms and Conditions
                  </Link>
                  ) sets out the basis on which we will process any personal
                  data that you provide to us. Please read it carefully.
                </p>
                <h5 className='mb-3 fw-bold'>Data Controller</h5>
                <p>
                  For the purposes of the Data Protection Act 1998 (the Act) and
                  the EU General Data Protection Regulation (“GDPR”), the data
                  controller is Stockappy Ltd of Office 113, Challenge House,
                  616 Mitcham Road, Croydon CR0 3AA.
                </p>
                <h5 className='mb-3 fw-bold'>Processing your data</h5>
                <p>Your personal data is processed in the following ways:</p>
                <h6 className='mb-3 fw-bold'>Requests for services</h6>
                <p>
                  Your personal data is provided to the service providers we
                  recommend on behalf of (up to a maximum of 5 companies,
                  depending on the service). They will only use your data to
                  contact you in respect of the service you have asked for. We
                  process your data under the ‘legitimate interest’ basis of
                  GDPR to ensure our service, which is free to all users, is
                  commercially viable.
                </p>
                <p>
                  This data comprises of your name, email address, telephone
                  number and address.
                </p>
                <h6 className='mb-3 fw-bold'>
                  Feedback on moveappy.co.uk and our partners
                </h6>
                <p>
                  We will use your data to email you after you have registered
                  for a quote to seek your feedback on moveappy.co.uk and
                  subsequently on any partner you may have used. You may opt-out
                  of receiving these emails at any time.
                </p>
                <p>
                  When you submit feedback on reallymoveappy.co.uk, or one of
                  our partners, we will invite you to consent to your feedback
                  being shown on the website. You may withdraw that consent at
                  any time.
                </p>
                <h6 className='mb-3 fw-bold'>Other services</h6>
                <p>
                  When you register for quotes for a service we may invite you
                  to opt-in to receive emails helping you get quotes for other
                  services from moveappy.co.uk. We process this data using the
                  “consent” basis of GDPR. Your personal details will only be
                  used to send you notifications you have requested and for no
                  other purpose. Your details are not shared with third-parties
                  who may provide the service to moveappy.co.uk at this stage.
                  If you respond to the emails we send inviting you to get
                  quotes for other services we will explicitly inform you when
                  other firms will be passed your details. You may opt-out of
                  receiving these emails at any time.
                </p>
              </section>
              <section id='policy1' className='mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Other information we may collect on you
                </h4>
                <p>
                  If you contact us we may keep a record of that correspondence.
                </p>
                <p>
                  We automatically collect the following data about you when you
                  visit our site:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item'>
                    Browser user-agent information – this will reveal your
                    browser (including version), operating system and may reveal
                    your mobile device model information. This information is
                    captured in our webserver logs and may be used for
                    anonymised analysis of site usage.
                  </li>
                  <li className='nav-item'>
                    IP address – this information is captured in our webserver
                    logs and is recorded when you request a quote or leave
                    feedback. We may use this information for protection against
                    fraudulent use of our services.
                  </li>
                </ul>
                <p>
                  We can automatically detect your approximate location
                  (latitude and longitude) if you opt-in to allow us to obtain
                  your location through your browser geolocation information –
                  we offer this facility on some of our web pages. You may
                  revoke this permission at any time via your browser.
                </p>
                <p>
                  On occasion we may reach out to you to talk about how you
                  found our service. Deciding to talk to us is completely up to
                  you. Calls may be recorded and transcribed. This market
                  research data may cover when and how you used the website, and
                  more general information about your experience of the moving
                  home process.
                </p>
              </section>
              <section id='policy2' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Tracking and cookies</h4>
                <p>
                  We use cookies. Cookies are small text files that are placed
                  on your computer by websites that you visit. They are widely
                  used to make websites work more efficiently as well as to
                  provide information to the owners of the site. For more
                  information, including information on how to prevent sites
                  from using cookies,
                  <a
                    href='http://www.aboutcookies.org'
                    rel='noreferrer'
                    target='_blank'
                    className='mx-1 active font-weight-bold'
                  >
                    please see our separate Cookies page.
                  </a>
                </p>
              </section>
              <section id='policy3' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Retargeting</h4>
                <p>
                  <a
                    href='https://www.wikipedia.org/'
                    rel='noreferrer'
                    target='_blank'
                    className='mx-1 active font-weight-bold'
                  >
                    See Wikipedia for an explanation of retargeting.
                  </a>
                </p>
                <p>
                  This website uses retargeting services (sometimes referred to
                  as remarketing) to advertise on 3rd party websites (including
                  Google and Facebook) to previous visitors to our site. Any
                  data collected will be used in accordance with our own privacy
                  policy and the 3rd parties’ privacy policies.
                </p>
                <p>
                  You can set preferences for how Google advertises to you using
                  the Google Ad Personalization page, and if you want to you can
                  opt out of interest-based advertising entirely by cookie
                  settings or permanently using a browser plugin.
                </p>
              </section>
              <section id='policy4' className='mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Where we store your personal data
                </h4>
                <p>
                  Your data will usually be held in a destination inside the
                  European Economic Area ("EEA"). The EEA consists of the 28 EU
                  Member States plus Norway, Liechtenstein and Iceland. However,
                  there may be times when the data we collect from you may be
                  transferred to, and stored at, a destination outside the
                  European Economic Area ("EEA").
                </p>
                <p>
                  By submitting your personal data, you agree to this transfer,
                  storing or processing. We will take all steps reasonably
                  necessary to ensure that your data are maintained securely and
                  in accordance with this privacy policy and applicable data
                  protection law.
                </p>
              </section>
              <section id='policy5' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Uses made of the information</h4>
                <p>We use information held about you in the following ways:</p>
                <ul className='flex-column'>
                  <li className='nav-item'>
                    To ensure that content from our site is presented in the
                    most effective manner for you and for your computer.
                  </li>
                  <li className='nav-item'>
                    To provide you with information, products or services that
                    you request from us or which we feel may interest you, where
                    you have consented to be contacted for such purposes.
                  </li>
                  <li className='nav-item'>
                    To allow you to participate in interactive features of our
                    service, when you choose to do so.
                  </li>
                </ul>
                <p>
                  We will hold your personal data for 1 year (unless there is an
                  outstanding matter with you that is still unresolved).
                </p>
                <p>
                  If we have interviewed you about your experience of
                  moveappy.co.uk and the moving home process, this information
                  will be used to improve our service, and our understanding of
                  the user journey.
                </p>
              </section>
              <section id='policy6' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Disclosure of your information</h4>
                <p>
                  We may disclose your personal information to third parties:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item'>
                    If Stockappy Ltd or its assets are acquired by a third
                    party, in which case personal data held by Stockappy Ltd
                    about its customers will be transferred to the acquirer.
                  </li>
                  <li className='nav-item'>
                    If we are under a duty to disclose your personal data, for
                    example to a Government agency, to comply with any legal
                    obligation, or to enforce our Terms and Conditions or to
                    protect the legitimate rights, property, or safety of us our
                    customers, or others. This includes exchanging information
                    with other companies and organisations for the purposes of
                    fraud protection and credit risk reduction.
                  </li>
                </ul>
                <p>
                  Our site may contain links to other websites. If you follow a
                  link to any of these websites, please note that these websites
                  have their own privacy policies and that we do not accept any
                  responsibility or liability for these policies. Please check
                  these policies before you submit any personal data to these
                  websites.
                </p>
              </section>
              <section id='policy7' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Your rights</h4>
                <p>
                  You have the following rights in relation to the processing of
                  your data:
                </p>
                <ul
                  className='nav flex-column mb-3'
                  style={{ fontFamily: 'sans-serif', fontSize: 13 }}
                >
                  <li className='nav-item'>
                    1. The right at any time to withdraw your consent to our use
                    of your data, but if you do so we may no longer be able to
                    provide services to you;
                  </li>
                  <li className='nav-item'>
                    2. The right to ask us not to process your personal data for
                    marketing purposes;
                  </li>
                  <li className='nav-item'>
                    3. The right to access the information we hold about you and
                    to be provided with a copy. If you make more than one such
                    request, we will be entitled to charge a reasonable fee for
                    the second and subsequent requests;
                  </li>
                  <li className='nav-item'>
                    4. If you consider that any of the information that we hold
                    about you is inaccurate, you have the right to ask us to
                    rectify that inaccuracy;
                  </li>
                  <li className='nav-item'>
                    5. If you consider that the information that we hold about
                    you is incomplete, you have the right to ask us to complete
                    it;
                  </li>
                  <li className='nav-item'>
                    6. The right to ask us to delete the information that we
                    hold about you if, for example, you consider that this
                    information is no longer necessary for the purpose for which
                    we collected it. This is sometimes referred to as “the right
                    to be forgotten”;
                  </li>
                  <li className='nav-item'>
                    7. The right to ask us to suspend processing of your data
                    if: (i) you consider that it is inaccurate; or (ii) its
                    processing is unlawful but you do not wish us to delete it;
                    or (iii) we no longer require the data but you need us to
                    keep it for a legal claim; or (iv) you object to the
                    processing and we are verifying whether we have a legitimate
                    reason to continue processing it;
                  </li>
                  <li className='nav-item'>
                    8. The right to be provided with a copy of the data that we
                    hold about you in a commonly used electronic format. This is
                    sometimes referred to as “data portability”.
                  </li>
                </ul>
                <p>
                  If you wish to use any of these rights you can contact us on
                  us by email at
                  <a
                    href='mailto:hello@moveappy.co.uk'
                    className='active font-weight-bold mx-1'
                  >
                    hello@moveappy.co.uk
                  </a>
                  or in writing to Office 113, Challenge House, 616 Mitcham
                  Road, Croydon CR0 3AA.
                </p>
                <p>
                  You also have the right to bring any concerns about our
                  processing of your personal data to the Information
                  Commissioner’s Office (“ICO”). The ICO can be contacted by
                  telephone 0303 123 1113.
                </p>
              </section>
              <section id='policy8' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Changes to our privacy policy</h4>
                <p>
                  Any changes we may make to our privacy policy in the future
                  will be posted on this page.
                </p>
              </section>
              <section id='policy9' className='mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Privacy Policy – moveappy.co.uk partners
                </h4>
                <p>
                  “Partners” are defined as members of the moveappy.co.uk
                  quoting panel.
                </p>
                <p>We process your data under the ‘contract’ basis of GDPR.</p>
                <p>We use information held about you in the following ways:</p>
                <ul className='flex-column mb-4'>
                  <li className='nav-item mb-2'>
                    To provide quotes and listings to our website users.
                  </li>
                  <li className='nav-item mb-2'>
                    To carry out our obligations arising from any contracts
                    entered into between you and us.
                  </li>
                  <li className='nav-item mb-2'>
                    To notify you about changes to our service.
                  </li>
                  <li className='nav-item mb-2'>
                    To allow you to participate in interactive features of our
                    service, when you choose to do so.
                  </li>
                </ul>
                <h5 className='mb-4 fw-bold'>Where we store your data</h5>
                <p>
                  Your data will usually be held in a destination inside the
                  European Economic Area ("EEA"). The EEA consists of the 28 EU
                  Member States plus Norway, Liechtenstein and Iceland. However,
                  there may be times when the data we collect from you may be
                  transferred to, and stored at, a destination outside the
                  European Economic Area ("EEA").
                </p>
                <p>
                  By submitting your personal data, you agree to this transfer,
                  storing or processing. We will take all steps reasonably
                  necessary to ensure that your data are maintained securely and
                  in accordance with this privacy policy and applicable data
                  protection law.
                </p>
              </section>
              <section id='policy10' className='mb-5'>
                <h4 className='mb-4 fw-bold'>
                  Privacy policy - affiliate partners
                </h4>
                <p>Also known as “referral” partners.</p>
                <p>We process your data under the ‘contract’ basis of GDPR.</p>
                <p>We use information held about you in the following ways:</p>
                <ul className='flex-column mb-4'>
                  <li className='nav-item mb-2'>
                    To carry out our obligations arising from any contracts
                    entered into between you and us.
                  </li>
                  <li className='nav-item mb-2'>
                    To notify you about changes to our service.
                  </li>
                  <li className='nav-item mb-2'>
                    To allow you to participate in interactive features of our
                    service, when you choose to do so.
                  </li>
                </ul>
                <p>
                  All business agreements, contract and other related documents
                  will be retained for as per Section 5 of the Limitation Act
                  1980.
                </p>
              </section>
              <section id='policy11' className='mb-5'>
                <h4 className='mb-4 fw-bold'>Where we store your data</h4>
                <p>
                  Your data will usually be held in a destination inside the
                  European Economic Area ("EEA"). The EEA consists of the 28 EU
                  Member States plus Norway, Liechtenstein and Iceland. However,
                  there may be times when the data we collect from you may be
                  transferred to, and stored at, a destination outside the
                  European Economic Area ("EEA").
                </p>
                <p>
                  By submitting your personal data, you agree to this transfer,
                  storing or processing. We will take all steps reasonably
                  necessary to ensure that your data are maintained securely and
                  in accordance with this privacy policy and applicable data
                  protection law.
                </p>
                <p>
                  <i className='font-weight-bold text-body'>
                    This page was last updated on March 12th 2021
                  </i>
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
