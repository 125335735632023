import React, { useState } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomAccordion from './CustomAccordion';
import { PropertyHeader } from './property-details/PropertyHeader';
import { PropertyInfo } from './property-details/PropertyInfo';
import { NearbyPlaces } from './property-details/NearbyPlaces';
import { AgencyDetails } from './property-details/AgencyDetails';
import { PropertyFeatures } from './property-details/PropertyFeatures';
import { PropertyDescription } from './property-details/PropertyDescription';
import { PropertyDesktopGallery } from './property-details/PropertyDesktopGallery';
import { MobilePropertyView } from './property-details/MobilePropertyView';
import { PropertyEcpDocuments } from './property-details/PropertyEcpDocuments';
import { PropertyThreeSixty } from './property-details/PropertyThreeSixty';
import { PropertyCgi } from './property-details/PropertyCgi';
import { SimpleMap } from '../map/propDetailMap';

const PropertyDetails = ({ property }) => {
  const [activeTab, setActiveTab] = useState('1');

  const { floorImages } = property;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (property) {
    return (
      <section className='py-md-5'>
        <Container>
          <div>
            <PropertyHeader details={property} />
            <PropertyInfo details={property} />
            <PropertyDesktopGallery property={property} />
            <MobilePropertyView property={property} />
          </div>
          <div className='row justify-content-center'>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <PropertyDescription details={property} />

                  <div className='d-none d-md-block'>
                    <PropertyEcpDocuments details={property} />
                  </div>

                  <PropertyFeatures features={property.features} />

                  {floorImages?.length > 0 ? (
                    <div className='pb-5 mb-5 border-bottom font-container-text d-none d-md-block'>
                      <h5 className='mb-4 text-capitalize'>Floor plans</h5>
                      {floorImages?.map((value, index) => (
                        <div className='mb-4'>
                          <CustomAccordion title={`Floor Plan ${index + 1}`}>
                            <div className='row mx-0'>
                              <div className='px-0 col-10 col-md-7 col-lg-8 col-xl-7'>
                                <img
                                  alt=''
                                  className='w-100 h-100 border shadow-sm'
                                  src={value}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                              <div className='px-0 col'></div>
                            </div>
                          </CustomAccordion>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {/* <div className='pb-5 mb-5 border-bottom d-none d-md-block'>
                    <div>
                      <ul className='nav mb-4'>
                        {property?.cgiLink && (
                          <li className='mr-5'>
                            <div
                              className={`link-line-2 h4 mb-0 ${classnames({
                                active: activeTab === '1',
                              })}`}
                              onClick={() => {
                                toggleTab('1');
                              }}
                            >
                              <div className='pb-1'>Property CGI</div>
                            </div>
                          </li>
                        )}
                        {property?.threeSixtyView && (
                          <li>
                            <div
                              className={`link-line-2 h4 mb-0 ${classnames({
                                active: activeTab === '2',
                              })}`}
                              onClick={() => {
                                toggleTab('2');
                              }}
                            >
                              <div className='pb-1'>360° Virtual Tour</div>
                            </div>
                          </li>
                        )}
                      </ul>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId='1'>
                          <PropertyCgi details={property} />
                        </TabPane>
                        <TabPane tabId='2'>
                          <PropertyThreeSixty details={property} />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div> */}
                  <div className='pb-5 mb-5 border-bottom d-none d-md-block'>
                    <SimpleMap property={property} />
                  </div>
                  <div>
                    <h3 className='mb-4  text-uppercase font-weight-bold'>
                      What's Nearby
                    </h3>
                    <NearbyPlaces details={property} />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className='mt-3 position-relative d-none d-lg-block'>
              <AgencyDetails details={property} />
            </Col>
          </div>{' '}
        </Container>
      </section>
    );
  }
};

export default PropertyDetails;
