import React from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';
import { useOptionsDropdown } from '../../../../hooks/useOptionGroup';
import {
  formatGroupLabel,
  formatOptionLabel,
  GetClassOptions,
  getOptionsByValue,
} from '../../../../utils/optionFormatter';

export const FeaturesList = () => {
  const { isLoading, options } = useOptionsDropdown({
    type: 'category',
  });

  const { control } = useFormContext();

  const feat = getOptionsByValue('features', options);

  const featuresList = feat?.filter(
    (feature) => !feature.value.includes('stockappy')
  );

  return (
    <Col className='p-0'>
      <Col md={12} className='mb-4 p-0'>
        <div>
          <Row>
            <Col>
              <div className='rld-single-select'>
                <label className='text-uppercase my-2'>Select Features</label>
                <Controller
                  isMulti
                  isLoading={isLoading}
                  name='features'
                  formatOptionLabel={formatOptionLabel}
                  placeholder='Select Features (Multiple)'
                  control={control}
                  options={featuresList}
                  as={<Select />}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Col>

      <Row className='align-items-center'>
        <Col className='mb-4' md={12} sm={12}>
          <label className='text-uppercase mb-2'>Use Classes</label>
          <Controller
            as={Select}
            options={GetClassOptions(options)}
            isClearable
            isMulti
            placeholder='Select Classes (Multiple)'
            isLoading={isLoading}
            defaultValue=''
            formatGroupLabel={formatGroupLabel}
            name='classes'
            control={control}
          />
        </Col>
        <Col className='mb-4' md={12}>
          <label className='text-uppercase mb-2'>Accessibility</label>
          <Controller
            isMulti
            name='accessibility'
            control={control}
            placeholder='Select Accessibility (Multiple)'
            as={Select}
            options={getOptionsByValue('accessibility', options)}
          />
        </Col>
        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Parking</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('parking', options)}
            placeholder='Select Parking '
            isLoading={isLoading}
            name='parking'
            control={control}
          />
        </Col>
        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Outdoor Space</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('outdoor space', options)}
            placeholder='Select Outdoor Space'
            isLoading={isLoading}
            name='outdoorSpace'
            control={control}
          />
        </Col>

        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Air Conditioning</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('ac', options)}
            placeholder='Select Air Conditioning'
            isLoading={isLoading}
            name='ac'
            control={control}
          />
        </Col>
        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Epc Rating</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('epc rating', options)}
            placeholder='Select Epc Rating'
            isLoading={isLoading}
            name='epcRating'
            control={control}
          />
        </Col>

        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Showers</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('showers', options)}
            placeholder='Select Showers'
            isLoading={isLoading}
            name='showers'
            control={control}
          />
        </Col>

        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>
            Terrace / External Areas
          </label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('terrace', options)}
            placeholder='Select Terrace'
            isLoading={isLoading}
            name='terrace'
            control={control}
          />
        </Col>

        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Cafe</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('cafe', options)}
            placeholder='Select Cafe'
            isLoading={isLoading}
            name='cafe'
            control={control}
          />
        </Col>
        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Managed</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('manager', options)}
            placeholder='Select Managed'
            isLoading={isLoading}
            name='manager'
            control={control}
          />
        </Col>

        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Available Term</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('available term', options)}
            placeholder='Select Term'
            isLoading={isLoading}
            name='availableTerm'
            control={control}
          />
        </Col>
        <Col className='mb-4' md={6}>
          <label className='text-uppercase mb-2'>Tenure</label>
          <Controller
            as={Select}
            isClearable
            options={getOptionsByValue('tenure', options)}
            placeholder='Select Tenure'
            isLoading={isLoading}
            name='tenure'
            control={control}
          />
        </Col>
      </Row>
    </Col>
  );
};
