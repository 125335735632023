import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import Navbar from '../components/global-components/navbar';
import { MyAds } from '../components/service-provider/my-ads';
import Footer from '../components/global-components/footer';
import { Error } from '../utils/errorComponent';

const getUserAds = async () => {
  try {
    const res = await axios.get('/service/ads');
    return res.data.ads;
  } catch (err) {
    throw err.response;
  }
};
const UserAds = () => {
  const { data, isLoading, error } = useQuery('userAds', getUserAds, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <div
            class='spinner-border text-dark mt-5'
            style={{ height: '3rem', width: '3rem' }}
            role='status'
          >
            <span class='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <Error />;
  }
  return (
    <div>
      <Navbar />
      <MyAds ads={data} />
      <Footer />
    </div>
  );
};

export default UserAds;
