import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class OurPartner extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let data = sectiondata.partner;

    return (
      <div className='pd-top-92 pd-bottom-100 d-none d-md-block'>
        <div className='container'>
          <div className='section-title text-center'>
            <h2 className='title'>{data.title}</h2>
          </div>
          <div className='row mx-0 row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3'>
            {data.items.map((item, i) => (
              <div key={i} className='item' style={{ opacity: 0.4 }}>
                <div className='thumb'>
                  <img src={publicUrl + item.image} alt='client' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default OurPartner;
