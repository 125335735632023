import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = (props) => {
  const { imageUrl } = props;
  let HeaderTitle = props.headertitle;
  let Subheader = props.subheader ? props.subheader : HeaderTitle;
  const inlineStyle = {
    backgroundImage: `url('${imageUrl}')`,
  };
  return (
    <div className='breadcrumb-area jarallax' style={inlineStyle}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='breadcrumb-inner'>
              <h1 className='page-title'>{HeaderTitle}</h1>
              <ul className='page-list'>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>{Subheader}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
