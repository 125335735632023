import React from 'react';

export const PropertyCgi = ({ details }) => {
  const { cgiLink } = details;
  return (
    <div>
      {cgiLink !== null ? (
        <section>
          <iframe
            className='w-100'
            title='tada'
            src={cgiLink}
            frameborder='0'
            allowfullscreen
            style={{ height: '450px' }}
            allow='xr-spatial-tracking'
          />
        </section>
      ) : null}
    </div>
  );
};
