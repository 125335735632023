import React from 'react';
import Navbar from '../components/global-components/navbar';
import Banner from '../components/section-components/about-banner';
// import Mission from '../components/section-components/mission';
import AboutUs from '../components/section-components/about-us';
import WhyChooseUs from '../components/section-components/why-choose-us';
// import Team from '../components/section-components/team';
// import Client from '../components/section-components/client';
import Footer from '../components/global-components/footer';
import { Trends } from '../components/properties-components/home/Trends';
import OurPartner from '../components/section-components/our-partner';
import Ads from '../components/section-components/ads';

const About = () => {
  return (
    <div className='d-flex flex-column vh-100'>
      <Navbar />
      <div className='flex-fill'>
        <Banner />
        <AboutUs />
        <Trends />
        <WhyChooseUs />
        <OurPartner />
        <Ads />
      </div>
      <Footer />
    </div>
  );
};

export default About;
