import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import Navbar from '../components/global-components/navbar';
import { RequestTable } from '../components/properties-components/requests/RequestTable';
import { SvgBox } from '../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../images/icons/arrowRight.svg';

const getPropertyRequests = async propertyId => {
  try {
    const res = await axios.get(`property/${propertyId}/requests`);
    return res.data.requests;
  } catch (err) {
    throw err.response;
  }
};

const Request = () => {
  const { id } = useParams();

  const { goBack } = useHistory();

  const { data, isLoading, error } = useQuery(
    [`requests-${id}`, id],
    () => getPropertyRequests(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div className='spinner-custom mt-5'>
        <div className='text-center mt-5'>
          <div
            className='spinner-border text-dark mt-5'
            style={{ height: '3rem', width: '3rem' }}
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='spinner-custom mt-5'>
        <div className='text-center mt-5'>
          <h2 className='mt-5'>Something Went Wrong...</h2>
        </div>
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <section className='bg-white open-sans min-vh-100 mt-5'>
        <div className='container '>
          <header>
            <div className='d-flex border-top border-bottom align-items-center justify-content-between px-1'>
              <div className=' py-2 mb-2'>
                <div className='h4 text-capitalize mb-0 d-flex align-items-center'>
                  <span onClick={goBack}>
                    {' '}
                    <SvgBox
                      className='mr-2 cursor-pointer '
                      rotate='180'
                      size='15px'
                    >
                      <ArrowIcon />
                    </SvgBox>{' '}
                  </span>
                  Requests List For{' '}
                  {data?.length > 0 ? (
                    <>
                      {data[0].property.title} ({data?.length ?? 0})
                    </>
                  ) : null}
                </div>
              </div>
              <Link
                to={`/property/${id}`}
                className='btn font-weight-bold btn-sm btn-link'
              >
                Go To Property
              </Link>
            </div>
          </header>
          <main>
            <RequestTable requests={data} />
          </main>
        </div>
      </section>
    </>
  );
};

export default Request;
