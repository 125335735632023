import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, Spinner } from 'reactstrap';

export const ContactAgencyForm = ({ isOpen, toggle, details }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [loading, setLoading] = useState(false);

  const publicUrl = process.env.PUBLIC_URL + '/';

  const { userId, title, location, _id, landLordEmail, landLordName } = details;

  const onSubmit = data => {
    setLoading(true);
    data.agencyName = userId !== null ? userId.name : landLordName;
    data.agencyEmail = userId !== null ? userId.email : landLordEmail;
    data.propertyTitle = title;
    axios
      .patch(`/property/${_id}/request`, data)
      .then(res => {
        setLoading(false);
        reset();
        toggle();
      })
      .catch(err => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Modal
        className='m-0 m-sm-auto slide-in-bottom '
        centered
        contentClassName='slide-in-bottom'
        isOpen={isOpen}
        toggle={toggle}
      >
        <div>
          <div className='d-flex align-items-center border-bottom   px-3 mb-4'>
            <div className='mr-3 d-flex'>
              <img
                style={{ objectFit: 'contain', height: 40, width: 40 }}
                alt=''
                src={
                  userId !== null
                    ? userId.imageUrl
                    : publicUrl + 'assets/img/user-placeholder.svg'
                }
              />
            </div>
            <div className='flex flex-column'>
              <h4 className='mb-0 text-capitalize'>{title}</h4>
              <div className='text-muted'>
                <span>{location}</span>
              </div>
            </div>
            <button onClick={toggle} type='button' className='btn p-0 ml-auto'>
              <h1 className='mb-0 font-weight-light'>&times;</h1>
            </button>
          </div>
          {/* Header End */}
          <div className='mt-3 px-3'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='rld-single-input mb-4'>
                <input
                  type='text'
                  name='name'
                  ref={register({ required: true })}
                  placeholder='Name'
                />
                {errors.name && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input mb-4'>
                <input
                  type='number'
                  name='phoneNumber'
                  ref={register({ required: true })}
                  placeholder='Phone'
                />
                {errors.phoneNumber && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input mb-4'>
                <input
                  type='email'
                  name='email'
                  ref={register({ required: true })}
                  placeholder='Email'
                />
                {errors.email && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input mb-3'>
                <textarea
                  rows='4'
                  name='message'
                  ref={register({ required: true })}
                  placeholder='Message'
                />
                {errors.message && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='mb-3'>
                <Button
                  color='secondary'
                  type='submit'
                  block
                  disabled={loading}
                  className='py-3 text-uppercase font-weight-bold '
                >
                  {loading && <Spinner />}
                  CONTACT PROPERTY
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
