import React, { useState } from 'react';
import { Row, Col, Container, Modal, ModalBody } from 'reactstrap';

import Navbar from '../../components/global-components/navbar';
import { ProfileManagementSection } from '../../components/auth-components/profileManagementForm';

import { DashboardMap } from '../../components/map/dashboardMap';
import { Error } from '../../utils/errorComponent';
import { Loader } from '../../components/Loader';
import { useGetProfile } from '../../hooks/useGetProfile';

const ProfileManagement = () => {
  const [modal, setModal] = useState(false);

  const togglee = () => setModal(!modal);

  const { isLoading, data, isError } = useGetProfile();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }
  return (
    <section className='vh-100 d-flex flex-column'>
      <Navbar />
      <Row className='flex-fill mx-0'>
        <Col className='px-0'>
          <Container className='px-0'>
            <Row className='mx-0 pt-4 pb-5'>
              <Col lg='12'>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    onClick={togglee}
                    className='btn btn-secondary text-uppercase py-3'
                  >
                    View Location On Map
                  </button>
                  <Modal
                    className='m-0 p-0'
                    contentClassName='border-0 d-flex flex-column'
                    centered
                    style={{
                      maxWidth: '1600px',
                      width: '100%',
                      height: '100vh',
                    }}
                    isOpen={modal}
                    toggle={togglee}
                  >
                    <ModalBody className='flex-fill d-flex flex-column p-0 m-0'>
                      <div className='bg-white shadow-sm rounded-lg my-auto'>
                        <DashboardMap profile={data} />
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </Col>
              <Col lg='12'>
                <ProfileManagementSection profileData={data} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </section>
  );
};

export default ProfileManagement;
