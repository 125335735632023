import React from 'react';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

function ScrollToTopOnMount() {
  React.useLayoutEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return null;
}

export const Acceptable = () => {
  const { hash } = useLocation();
  return (
    <>
      <ScrollToTopOnMount />
      <Navbar />
      <main className='bg-light container-terms py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-xl-3 d-none d-lg-block'>
              <ul
                className='nav flex-column position-sticky'
                style={{ top: 80 }}
              >
                <li className='nav-item mb-2'>
                  <div>
                    <a
                      href='#policy0'
                      className={`nav-link h4 font-weight-bold ${
                        hash === '#policy0'
                          ? 'font-weight-bold text-yellow'
                          : ''
                      }`}
                    >
                      Acceptable Use Policy
                    </a>
                  </div>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy1'
                    className={`nav-link ${
                      hash === '#policy1' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Prohibited Uses
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy2'
                    className={`nav-link ${
                      hash === '#policy2' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Interactive Services
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy3'
                    className={`nav-link ${
                      hash === '#policy3' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Content Standards
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy4'
                    className={`nav-link ${
                      hash === '#policy4' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Suspension and Termination
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='#policy5'
                    className={`nav-link ${
                      hash === '#policy5' ? 'font-weight-bold text-yellow' : ''
                    }`}
                  >
                    Changes to the Acceptable Use Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className='col'>
              <section id='policy0' className='mb-5'>
                <h4 className='mb-4 font-weight-bold'>Acceptable Use Policy</h4>
                <h5 className='mb-4 text-black-50'>
                  This Acceptable Use Policy (AUP) sets out the terms between
                  you and us under which you may access the moveappy.co.uk
                  website (our site). This AUP applies to all users of, and
                  visitors to, our site.
                </h5>

                <p>
                  Your use of our site means that you accept, and agree to abide
                  by, all the policies in this AUP, which supplement our
                  <Link to='/terms' className='active mx-1 font-weight-bold'>
                    Terms and Conditions
                  </Link>
                  of website use.
                </p>
                <p>
                  Moveappy.co.uk is a site operated by Stockappy Ltd ("We" or
                  “Us”). We are registered in England and Wales under company
                  number 11738509 and have our registered office at Office 113,
                  Challenge House, 616 Mitcham Road, Croydon CR0 3AA.
                </p>
              </section>
              <section id='policy1' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Prohibited Uses</h5>
                <p>
                  You may use our site only for lawful purposes. You may not use
                  our site:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    In any way that breaches any applicable local, national or
                    international law or regulation
                  </li>
                  <li className='nav-item mb-2'>
                    In any way that is unlawful or fraudulent, or has any
                    unlawful or fraudulent purpose or effect
                  </li>
                  <li className='nav-item mb-2'>
                    For the purpose of harming or attempting to harm minors in
                    any way
                  </li>
                  <li className='nav-item mb-2'>
                    To send, knowingly receive, upload, download, use or re-use
                    any material which does not comply with our content
                    standards
                  </li>
                  <li className='nav-item mb-2'>
                    To transmit, or procure the sending of, any unsolicited or
                    unauthorised advertising or promotional material or any
                    other form of similar solicitation (spam)
                  </li>
                  <li className='nav-item mb-2'>
                    To knowingly transmit any data, send or upload any material
                    that contains viruses, Trojan horses, worms, time-bombs,
                    keystroke loggers, spyware, adware or any other harmful
                    programs or similar computer code designed to adversely
                    affect the operation of any computer software or hardware
                  </li>
                </ul>
                <p>You also agree:</p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    Not to reproduce, duplicate, copy or re-sell any part of our
                    site in contravention of the provisions of our
                    <Link to='/terms' className='active mx-1 font-weight-bold'>
                      Terms of website use
                    </Link>
                  </li>
                  <li className='nav-item mb-2'>
                    Not to access without authority, interfere with, damage or
                    disrupt:
                    <ul className='flex-column mt-2'>
                      <li className='nav-item mb-2'>any part of our site;</li>
                      <li className='nav-item mb-2'>
                        any equipment or network on which our site is stored;
                      </li>
                      <li className='nav-item mb-2'>
                        any software used in the provision of our site; or
                      </li>
                      <li className='nav-item mb-2'>
                        any equipment or network or software owned or used by
                        any third party
                      </li>
                    </ul>
                  </li>
                </ul>
              </section>
              <section id='policy2' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Interactive Services</h5>
                <p>
                  We may from time to time provide interactive services on our
                  site, including, without limitation:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>Chat rooms</li>
                  <li className='nav-item mb-2'>Bulletin boards</li>
                  <li className='nav-item mb-2'>Partner Feedback / Reviews</li>
                  <li className='nav-item mb-2'>Property Professor Q & As</li>
                </ul>
                <p>
                  Where we do provide any interactive service, we will provide
                  clear information to you about the kind of service offered, if
                  it is moderated and what form of moderation is used (including
                  whether it is human or technical).
                </p>

                <p>
                  We will do our best to assess any possible risks for users
                  (and in particular, for children) from third parties when they
                  use any interactive service provided on our site, and we will
                  decide in each case whether it is appropriate to use
                  moderation of the relevant service (including what kind of
                  moderation to use) in the light of those risks. However, we
                  are under no obligation to oversee, monitor or moderate any
                  interactive service we provide on our site, and we expressly
                  exclude our liability for any loss or damage arising from the
                  use of any interactive service by a user in contravention of
                  our content standards, whether the service is moderated or
                  not.
                  <br /> The use of any of our interactive services by a minor
                  is subject to the consent of their parent or guardian. We
                  advise parents who permit their children to use an interactive
                  service that it is important that they communicate with their
                  children about their safety online, as moderation is not
                  foolproof. Minors who are using any interactive service should
                  be made aware of the potential risks to them.
                </p>
                <p>
                  Where we do moderate an interactive service, we will normally
                  provide you with a means of contacting the moderator, should a
                  concern or difficulty arise.
                </p>
              </section>
              <section id='policy3' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>Content Standards</h5>
                <p>
                  These content standards apply to any and all material which
                  you contribute to our site (contributions), and to any
                  interactive services associated with it.
                  <br /> You must comply with the spirit of the following
                  standards as well as the letter. The standards apply to each
                  part of any contribution as well as to its whole.
                </p>
                <p className='font-weight-bold text-body'>
                  Contributions must:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    Be accurate (where they state facts)
                  </li>
                  <li className='nav-item mb-2'>
                    Be genuinely held (where they state opinions)
                  </li>
                  <li className='nav-item mb-2'>
                    Comply with applicable law in the UK and in any country from
                    which they are posted
                  </li>
                </ul>
                <p className='font-weight-bold text-body'>
                  Contributions must not:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    Contain any material which is defamatory of any person
                  </li>
                  <li className='nav-item mb-2'>
                    Contain any material which is obscene, offensive, hateful or
                    inflammatory
                  </li>
                  <li className='nav-item mb-2'>
                    Promote sexually explicit material
                  </li>
                  <li className='nav-item mb-2'> Promote violence</li>
                  <li className='nav-item mb-2'>
                    Promote discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age
                  </li>
                  <li className='nav-item mb-2'>
                    Infringe any copyright, database right or trade mark of any
                    other person
                  </li>
                  <li className='nav-item mb-2'>
                    Be likely to deceive any person
                  </li>
                  <li className='nav-item mb-2'>
                    Be made in breach of any legal duty owed to a third party,
                    such as a contractual duty or a duty of confidence
                  </li>
                  <li className='nav-item mb-2'>
                    Promote any illegal activity
                  </li>
                  <li className='nav-item mb-2'>
                    Be threatening, abuse or invade another's privacy, or cause
                    annoyance, inconvenience or needless anxiety
                  </li>
                  <li className='nav-item mb-2'>
                    Be likely to harass, upset, embarrass, alarm or annoy any
                    other person
                  </li>
                  <li className='nav-item mb-2'>
                    Be used to impersonate any person, or to misrepresent your
                    identity or affiliation with any person
                  </li>
                  <li className='nav-item mb-2'>
                    Give the impression that they emanate from us, if this is
                    not the case
                  </li>
                  <li className='nav-item mb-2'>
                    Advocate, promote or assist any unlawful act such as (by way
                    of example only) copyright infringement or computer misuse
                  </li>
                </ul>
              </section>
              <section id='policy4' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Suspension and Termination
                </h5>
                <p>
                  We will determine, in our discretion, whether there has been a
                  breach of this AUP through your use of our site. When a breach
                  of this AUP has occurred, we may take such action as we deem
                  appropriate.
                </p>
                <p>
                  Failure to comply with this AUP constitutes a material breach
                  of the terms of our
                  <Link to='/terms' className='active mx-1 font-weight-bold'>
                    Terms and Conditions
                  </Link>
                  upon which you are permitted to use our site, and may result
                  in our taking all or any of the following actions:
                </p>
                <ul className='flex-column'>
                  <li className='nav-item mb-2'>
                    Immediate, temporary or permanent withdrawal of your right
                    to use our site
                  </li>
                  <li className='nav-item mb-2'>
                    Immediate, temporary or permanent removal of any posting or
                    material uploaded by you to our site
                  </li>
                  <li className='nav-item mb-2'>Issue of a warning to you</li>
                  <li className='nav-item mb-2'>
                    Legal proceedings against you for reimbursement of all costs
                    on an indemnity basis (including, but not limited to,
                    reasonable administrative and legal costs) resulting from
                    the breach
                  </li>
                  <li className='nav-item mb-2'>
                    Further legal action against you
                  </li>
                  <li className='nav-item mb-2'>
                    Disclosure of such information to law enforcement
                    authorities as we reasonably feel is necessary
                  </li>
                  <li className='nav-item mb-2'>
                    We exclude liability for actions taken in response to
                    breaches of this AUP. The responses described in this policy
                    are not limited, and we may take any other action we
                    reasonably deem appropriate
                  </li>
                </ul>
              </section>
              <section id='policy5' className='mb-5'>
                <h5 className='mb-4 font-weight-bold'>
                  Changes to the Acceptable Use Policy
                </h5>
                <p>
                  We may revise this AUP at any time by amending this page. You
                  are expected to check this page from time to time to take
                  notice of any changes we make, as they are legally binding on
                  you. Some of the provisions contained in this AUP may also be
                  superseded by provisions or notices published elsewhere on our
                  site.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
