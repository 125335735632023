import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as HouseIcon } from '../../images/icons/house.svg';

const getDashboardActivity = async () => {
  try {
    const res = await axios.get('/activity');
    return res.data.activities;
  } catch (err) {
    throw err.response;
  }
};

const DashboardActivity = () => {
  const { data, isLoading } = useQuery(
    'dashboardActivity',
    getDashboardActivity
  );

  if (!isLoading && data?.length === 0) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center'>
          <h1 class='mt-5'>No Recent Activity...</h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      {data?.slice(0, 8).map(item => (
        <div key={item._id} className='row mx-0 mt-4'>
          <div
            className='icon-box bg-light mr-3'
            style={{ height: 35, width: 35 }}
          >
            <SvgBox size='15px' className='text-black-50'>
              <HouseIcon />
            </SvgBox>
          </div>
          <div className='mb-0 col px-0 mt-2 text-capitalize'>
            <p className='mb-0'>
              <small>
                Your Property
                <Link
                  className='text-yellow'
                  to={`/property/${item?.propertyId?.slug}`}
                >
                  {' '}
                  &nbsp;{item?.propertyId?.title} &nbsp;
                </Link>
                {item.message}
              </small>
              {item.type === 'request' ? (
                <small className='ml-2 text-uppercase'>
                  <Link to={`/property/requests/${item?.propertyId?.slug}`}>
                    {item.otherLink}.
                  </Link>
                </small>
              ) : null}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardActivity;
