import axios from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

const createProperty = async data => {
  try {
    const res = await axios.post('/property', data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useCreateProperty = () => {
  const { push } = useHistory();
  const mutation = useMutation(createProperty, {
    onSuccess: data => {
      push(`/property/${data.savedProperty.slug}`);
    },
  });

  return { mutation };
};
