import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ReplyModal } from './replyModal';

export const MblItem = (props) => {
  const { msg, index } = props;
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <>
      <section className='rounded-xl shadow-sm border p-4 mb-4 d-lg-none small'>
        <Row className='mt-3'>
          <Col xs={3}>
            <div>
              <span className='text-secondary text-capitalize font-weight-bold'>
                #
              </span>
            </div>
          </Col>
          <Col>
            <div>
              <span className='text-capitalize'>{index + 1}</span>
            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col xs={3}>
            <div>
              <span className='text-secondary text-capitalize font-weight-bold'>
                Email
              </span>
            </div>
          </Col>
          <Col>
            <div>
              <span className='text-capitalize'>{msg.senderEmail}</span>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={3}>
            <div>
              <span className='text-secondary text-capitalize font-weight-bold'>
                Message
              </span>
            </div>
          </Col>
          <Col>
            <div>
              <span className='text-capitalize'>{msg.message}</span>
            </div>
          </Col>
        </Row>

        <Row className='mt-3 align-items-center'>
          <Col xs={3}>
            <div>
              <span className='text-secondary text-capitalize font-weight-bold'>
                Action
              </span>
            </div>
          </Col>
          {/* <Col>
            <div className='d-flex align-items-center'>
              <span className='text-capitalize'>
                <button
                  className='btn btn-secondary py-1'
                  onClick={() => setShowReqModal(true)}
                >
                  Reply
                </button>
              </span>
            </div>
          </Col> */}
        </Row>
        {showReqModal ? (
          <ReplyModal
            showReqModal={showReqModal}
            setShowReqModal={setShowReqModal}
            senderEmail={msg.senderEmail}
            requestId={msg._id}
            propertyTitle={`Response To  Croydon Commercial `}
          />
        ) : null}
      </section>
    </>
  );
};
