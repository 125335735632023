import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { FeaturedLoader } from './featuredLoader';
import Card from '../card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';

const getHomeRecentProperties = async () => {
  try {
    const res = await axios.get(`/property/recent?limit=${10}`);
    return res.data.properties;
  } catch (err) {
    console.log(err);
  }
};
const Recent = (props) => {
  let Customclass = props.Customclass ? props.Customclass : 'pt-5';

  const { data, isLoading } = useQuery(
    'homeRecentProperties',
    getHomeRecentProperties,
    {
      refetchOnWindowFocus: false,
    }
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <div className='carousel-button-group d-flex'>
        <button
          style={{ height: 25, width: 25 }}
          aria-label='Go to previous slide'
          className={`icon-box border-0 ${
            currentSlide === 0 ? 'disable' : 'btn-primary'
          }`}
          onClick={() => next()}
        >
          <SvgBox rotate='180' size='10px'>
            <ArrowIcon />
          </SvgBox>
        </button>
        <div className='mx-2' />
        <button
          style={{ height: 25, width: 25 }}
          aria-label='Go to next slide'
          className={`icon-box border-0 ${
            currentSlide === totalItems - slidesToShow
              ? 'disable'
              : 'btn-primary'
          }`}
          onClick={() => previous()}
        >
          <SvgBox size='10px'>
            <ArrowIcon />
          </SvgBox>
        </button>
      </div>
    );
  };

  return (
    <div className={'featured-area ' + Customclass}>
      <div className='container py-0 py-md-5'>
        <div className='section-title mb-4 mb-md-5 text-center'>
          <div>
            <h2
              className='title d-none d-md-block'
              style={{ fontWeight: '400px' }}
            >
              Recent Properties Added
            </h2>
            <h2
              className='title text-left d-md-none'
              style={{ fontWeight: '400px' }}
            >
              Recent Properties Added
            </h2>
          </div>
        </div>
        <div className='row justify-content-center position-relative'>
          {isLoading ? (
            <>
              <FeaturedLoader />
              <FeaturedLoader />
              <FeaturedLoader />
              <FeaturedLoader />
            </>
          ) : (
            <>
              {data?.length > 0 && (
                <>
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    customButtonGroup={<ButtonGroup />}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className='d-none d-md-flex'
                    containerClass='container'
                    dotListClass=''
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=''
                    showDots
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside
                    responsive={responsive}
                  >
                    {data.length &&
                      data?.map((item, i) => (
                        <Card
                          key={item._id}
                          className='w-100 pr-4'
                          item={item}
                          index={i}
                        />
                      ))}
                  </Carousel>
                  {/* Mobile Start */}
                  <div className='row mx-0 flex-nowrap overflow-auto scroll-hide d-md-none'>
                    {data?.map((item, i) => (
                      <div className='col-11 pr-0'>
                        <Card
                          key={item._id}
                          className='w-100 pr-4'
                          item={item}
                          index={i}
                        />
                      </div>
                    ))}
                  </div>
                  {/* Mobile End */}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recent;
