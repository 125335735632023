import axios from 'axios';
import { useQuery } from 'react-query';

const getAgencyProfile = async id => {
  try {
    const res = await axios.get(`/profile/${id}`);
    return res.data.profile;
  } catch (err) {
    throw err.response;
  }
};

export const useAgencyProfile = ({ id }) => {
  const { data, isLoading, error } = useQuery(
    [id, 'agency-profile'],
    () => getAgencyProfile(id),
    {
      enabled: id ? true : false,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};
