import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { CardElement, injectStripe } from 'react-stripe-elements';
import notifications from '../notifications';
import { PlacesAutoComp } from '../properties-components/placesAutoComplete';
import { USER_TYPES } from '../../utils/userTypes';

const typeOptions = [
  { value: 'electrician', label: 'Electrician' },
  { value: 'plumber', label: 'Plumber' },
  { value: 'carpenter', label: 'Carpenter' },
  { value: 'mechanic', label: 'Mechanic' },
];

const RegisterPremium = ({ stripe, userType }) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [loading, setLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [country, setCountry] = useState(null);
  const [address, setAddress] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [city, setCity] = useState(null);
  const [locError, setLocError] = useState(null);
  const [referral, setReferral] = useState(null);
  const [type, setType] = useState(null);
  const [error, setError] = useState();

  const history = useHistory();

  useEffect(() => {
    axios
      .get('/countries')
      .then(res => {
        setCountryOptions(res.data.countriesList);
      })
      .catch(err => {
        throw err.response.data;
      });
  }, []);

  const stripeElementChange = element => {
    if (element.error) {
      setError(element.error.message);
    } else {
      setError('');
    }
  };
  const onSubmit = async data => {
    if (address === null || lat === null || lng === null) {
      setLocError(true);
    } else {
      setLoading(true);
      let { token } = await stripe.createToken();
      if (token === undefined) {
        setLoading(false);
        setError('Your Card Data Is InComplete.');
      } else {
        const paymentData = {
          stripeToken: token.id,
          brand: token.card.brand,
          addressZip: token.card.address_zip,
          expYear: token.card.exp_year,
          expMonth: token.card.exp_month,
          lastFour: token.card.last4,
        };
        data.country = country;
        data.userType = userType;
        data.lng = lng;
        data.lat = lat;
        data.address = address;
        if (city !== null) {
          data.city = city;
        }
        if (postCode !== null) {
          data.postCode = postCode;
        }
        data.referral = referral;
        if (userType === USER_TYPES.serviceProvider) {
          data.type = type;
        }
        data.paymentData = paymentData;
        data.userSubscriptionType = 'premium';
        axios
          .post('/signup', data)
          .then(res => {
            setLoading(false);
            notifications.success('Success', 'Registered Successfully');
            history.push({
              pathname: '/verify',
              state: { email: res.data.email },
            });
          })
          .catch(err => {
            setLoading(false);
            notifications.error('Error', err?.response?.data?.message);
          });
      }
    }
  };

  const referralOptions = [
    { value: 'online', label: 'Online' },
    { value: 'word of mouth', label: 'Word Of Mouth' },
    { value: 'social media', label: 'Social Media' },
    { value: 'email campaign', label: 'Email Campaign' },
    { value: 'press advert', label: 'Press Advert' },
    { value: 'radio advert', label: 'Radio Advert' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4 className='mb-4 text-capitalize'>Premium Registration</h4>
      <div className='rld-single-input mb-4 mt-4'>
        <h6 className='title'>Company name*</h6>
        <input type='text' name='name' ref={register({ required: true })} />
        {errors.name && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Username*</h6>
        <input type='text' name='username' ref={register({ required: true })} />
        {errors.username && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Email*</h6>
        <input type='email' name='email' ref={register({ required: true })} />
        {errors.email && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Phone number*</h6>
        <input
          type='number'
          name='phoneNumber'
          ref={register({ required: true })}
        />
        {errors.phoneNumber && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Address*</h6>
        <PlacesAutoComp
          setAddress={setAddress}
          setLat={setLat}
          setLng={setLng}
          setPostCode={setPostCode}
          setCity={setCity}
          placeholderValue={''}
        />
        {locError ? (
          <small className='font-weight-bold text-danger'>
            Enter your location again
          </small>
        ) : null}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Company website*</h6>
        <input type='text' name='website' ref={register({ required: true })} />
        {errors.website && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      <div className='rld-single-select mb-4'>
        <h6 className='title'>Country*</h6>
        <Select
          options={countryOptions}
          placeholder='Choose Country...'
          onChange={e => setCountry(e.label)}
        />
      </div>
      <div className='rld-single-select mb-4'>
        <h6 className='title'>Referral*</h6>
        <Select
          options={referralOptions}
          placeholder='Choose Referral'
          onChange={e => setReferral(e.value)}
        />
        {errors.referral && (
          <small className='font-weight-bold text-danger'>
            This field is required
          </small>
        )}
      </div>
      {userType === USER_TYPES.serviceProvider ? (
        <div className='rld-single-input mb-4'>
          <h6 className='title'>Type*</h6>
          <Select
            options={typeOptions}
            placeholder='Choose Type'
            onChange={e => setType(e.value)}
          />
        </div>
      ) : null}
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Password*</h6>
        <input
          type='password'
          name='password'
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
            pattern: {
              value: /^(?=.*\d)(?=.*[A-Z]).{8,24}$/,
              message:
                'Password must be 8 to 24 characters long with at least one uppercase and one number character',
            },
          })}
        />
        {errors.password && (
          <small className='font-weight-bold text-danger'>
            {errors.password.message}
          </small>
        )}
      </div>
      <div className='rld-single-input mb-4'>
        <h6 className='title'>Confirm password*</h6>
        <input
          type='password'
          name='confirmPassword'
          ref={register({
            validate: value =>
              value === password.current || 'The passwords do not match',
          })}
        />
        {/* {errors.password_repeat && <p>{errors.password_repeat.message}</p>} */}
        {errors.confirmPassword && (
          <small className='font-weight-bold text-danger'>
            {errors.confirmPassword.message}
          </small>
        )}
      </div>
      <h6 className='title mt-2 mb-3'>Card Number*</h6>
      <CardElement onChange={stripeElementChange} required />
      {error && (
        <span className='font-weight-bold text-danger text-capitalize mb-0'>
          * {error}
        </span>
      )}
      <div className='d-flex flex-column flex-md-row align-items-md-center mt-4'>
        <h6 className='title mb-3 mb-md-0 mr-md-3'>Market:</h6>
        <div className='d-flex align-items-center mb-3 mb-md-0 mr-md-3'>
          <input
            className='my-radiobox'
            type='radio'
            name='market'
            value='commercial'
            id='flexRadioDefault1'
            ref={register({ required: false })}
          />
          <label
            class='mb-0 font-weight-bold ml-2 cursor-pointer'
            for='flexRadioDefault1'
          >
            Commercial Properties
          </label>
        </div>
        <div className='d-flex align-items-center'>
          <input
            className='my-radiobox'
            type='radio'
            value='residential'
            name='market'
            ref={register({ required: false })}
            id='flexRadioDefault2'
          />
          <label
            class='mb-0 font-weight-bold ml-2 cursor-pointer'
            for='flexRadioDefault2'
          >
            Residential Properties
          </label>
        </div>
      </div>
      {locError ? (
        <small className='font-weight-bold text-danger'>
          Please Enter Your Location Again*
        </small>
      ) : null}
      <div className='mt-5 text-center px-0 col-12 col-md-4 mx-auto'>
        <button
          type='submit'
          disabled={loading || country === null || type === null}
          className={`btn btn-secondary btn-block ${
            loading === true ? 'button-disabled' : null
          }`}
        >
          {loading === true ? (
            <div
              class='spinner-border spinner-border-sm text-light mr-2'
              role='status'
            >
              <span class='sr-only'>Loading...</span>
            </div>
          ) : null}
          Register
        </button>
      </div>
    </form>
  );
};

export default injectStripe(RegisterPremium);
