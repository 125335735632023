import { useQuery } from 'react-query';
import axios from 'axios';

export const usePropertiesByType = (limit, skip, type) => {
  const fetchPropertiesByType = async (limit = 0, typee) => {
    try {
      const res = await axios.get(
        `property/type/${typee}?skip=${skip}&limit=${limit}`
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  };

  const {
    isLoading,
    isError,

    data,
  } = useQuery(
    [`properties-by-type-${type}`, limit],
    () => fetchPropertiesByType(limit, type),
    { keepPreviousData: true }
  );

  return { data, isError, isLoading };
};
