import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

const updateProperty = async data => {
  try {
    const res = await axios.patch(`/property/${data.slug}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const useUpdateProperty = slug => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const mutation = useMutation(updateProperty, {
    onSuccess: () => {
      queryClient.invalidateQueries([slug, 'property-details']);
      push(`/property/${slug}`);
    },
  });

  return { mutation };
};
