import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

export const Trends = () => {
  const getPropertiesStats = () => {
    return axios
      .get('/property/dashboard')
      .then(res => {
        return res.data;
      })
      .catch(err => {});
  };

  const { data, status } = useQuery('getPropertiesStats', getPropertiesStats);
  return (
    <section className='trends-container border-bottom py-3 py-md-5 d-none d-md-block'>
      <div className='container'>
        <div className='row row-cols-4 text-center text-uppercase justify-content-center'>
          <div className='col'>
            <div className='title'>
              {status === 'loading' ? '' : data?.userCount}
            </div>
            <p className='m-0'>Total Agencies</p>
          </div>
          <div className='col'>
            <div className='title'>
              {status === 'loading' ? '' : data?.commercialPropCount}
            </div>
            <p className='m-0'>Commercial Properties</p>
          </div>
          <div className='col'>
            <div className='title'>
              {status === 'loading' ? '' : data?.forSaleCount}
            </div>
            <p className='m-0'>For Sale</p>
          </div>
          <div className='col'>
            <div className='title'>
              {status === 'loading' ? '' : data?.toRentCount}
            </div>
            <p className='m-0'>To Rent</p>
          </div>
        </div>
      </div>
    </section>
  );
};
