import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router';
import { getSearchParams, setParams } from '../../../../utils/urlHelper';
export const useSearchParams = id => {
  const methods = useForm({
    shouldUnregister: false,
  });

  const { push } = useHistory();

  const queryData = getSearchParams();

  const onHandleSubmit = data => {
    const {
      text,
      category,
      bedrooms,
      receptions,
      bathrooms,
      days,
      maxPrice,
      minPrice,
      tenure,
      type,
      unit,
      outdoorSpace,
      parking,
      accessibility,
      features,
      DDACompliant,
      availableTerm,
      distancefromeastcroydon,
      distancefromwestcroydon,
    } = data;

    setParams('text', text, 'single', queryData);
    setParams('outdoorSpace', outdoorSpace?.value, 'single', queryData);
    setParams('type', type?.value, 'single', queryData);
    setParams('parking', parking?.value, 'single', queryData);
    setParams('tenure', tenure?.value, 'single', queryData);
    setParams('accessibility', accessibility?.value, 'single', queryData);
    setParams('unit', unit?.value, 'single', queryData);
    setParams('addedOn', days?.value, 'single', queryData);
    setParams('category', category?.value, 'single', queryData);
    setParams('bedrooms', bedrooms, 'single', queryData);
    setParams('receptions', receptions, 'single', queryData);
    setParams('bathrooms', bathrooms, 'single', queryData);
    setParams('min', minPrice, 'single', queryData);
    setParams('max', maxPrice, 'single', queryData);
    setParams('features', features?.value, 'single', queryData);
    setParams('DDACompliant', DDACompliant?.value, 'single', queryData);
    setParams('availableTerm', availableTerm?.value, 'single', queryData);
    setParams(
      'distancefromeastcroydon',
      distancefromeastcroydon?.value,
      'single',
      queryData
    );
    setParams(
      'distancefromwestcroydon',
      distancefromwestcroydon?.value,
      'single',
      queryData
    );

    id
      ? push(`/partner/${id}/search?${queryData.toString()}`)
      : push(`/search?${queryData.toString()}`);
  };
  return {
    methods,
    onHandleSubmit,
  };
};
