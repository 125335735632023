import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import {
  RealtorSectionOne,
  RealtorSectionTwo,
} from '../components/section-components/RealtorSections';
import { Loader } from '../components/Loader';
import { Error } from '../utils/errorComponent';
import { useAgencyProfile } from '../hooks/useAgencyProfile';

const RealtorPage = () => {
  const { id } = useParams();

  const { isLoading, data, error } = useAgencyProfile({
    id,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <section>
      <Navbar />
      <RealtorSectionOne data={data} />
      <RealtorSectionTwo description={data.description} id={data._id} />
      <Footer />
    </section>
  );
};

export default RealtorPage;
