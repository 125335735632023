import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  CardText,
  CardImgOverlay,
  CardImg,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ConfirmDeleteModal } from './confirmDeleteModel';

export const MyAdCard = props => {
  const [showReqModal, setShowReqModal] = useState(false);
  const { item } = props;

  return (
    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
      <a rel='noreferrer' href={`https://${item.url}`} target='_blank'>
        {item.linkLayout === '1' ? (
          <>
            <Card inverse className='overlay'>
              <div style={{ height: '220px' }}>
                <CardImg
                  width='100%'
                  height='100%'
                  src={
                    !item.imageUrl
                      ? require('../../images/placeholder.png').default
                      : item.imageUrl
                  }
                  alt='Card image cap'
                />
                <CardImgOverlay className='d-flex flex-column justify-content-end'>
                  <CardTitle tag='h5'>{item.title}</CardTitle>
                  <CardSubtitle tag='h6' className='mb-2 text-muted'>
                    {item.label}
                  </CardSubtitle>
                  <CardText className='text-truncate'>
                    {item.description}
                  </CardText>
                </CardImgOverlay>
              </div>
            </Card>

            <div className='d-flex justify-content-center'>
              <Link
                className='btn btn-primary'
                to={`/service/edit/${item.slug}`}
              >
                Update
              </Link>
              <Link
                className='btn btn-primary'
                type='button'
                onClick={() => setShowReqModal(true)}
              >
                Delete
              </Link>
            </div>
          </>
        ) : null}
        {item.linkLayout === '2' ? (
          <>
            <Card className='flex-row' style={{ height: 200 }}>
              <div className='h-100 w-100'>
                <CardImg
                  className='w-100 h-100'
                  src={
                    !item.imageUrl
                      ? require('../../images/placeholder.png').default
                      : item.imageUrl
                  }
                  alt='Card image cap'
                />
              </div>
              <CardBody className='h-100 scroll-box'>
                <CardTitle tag='h5'>{item.title}</CardTitle>
                <CardSubtitle tag='h6' className='mb-2 text-muted'>
                  {item.label}
                </CardSubtitle>
                <CardText>{item.description}</CardText>
              </CardBody>
            </Card>
            <div className='d-flex justify-content-center'>
              <Link
                className='btn btn-primary'
                to={`/service/edit/${item.slug}`}
              >
                Update
              </Link>
              <Link
                className='btn btn-primary'
                type='button'
                onClick={() => setShowReqModal(true)}
              >
                Delete
              </Link>
            </div>{' '}
          </>
        ) : null}
        {showReqModal ? (
          <ConfirmDeleteModal
            showReqModal={showReqModal}
            setShowReqModal={setShowReqModal}
            itemId={item._id}
          />
        ) : null}
      </a>
    </div>
  );
};
