import axios from 'axios';
import { useQuery } from 'react-query';

const getOptionsDropdown = async type => {
  try {
    const res = await axios.get('/optionGroup/getDropDownOptions', {
      params: { type },
    });
    return res.data.data.optionList[0].list;
  } catch (err) {
    throw err.response;
  }
};

export const useOptionsDropdown = ({ type }) => {
  const {
    isLoading,
    data: options,
    error,
  } = useQuery([type, 'options-group'], () => getOptionsDropdown(type));

  return {
    isLoading,
    options,
    error,
  };
};
