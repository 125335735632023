import React from 'react';

export const SvgBox = ({
  color,
  size,
  children,
  className,
  rotate,
  opacity,
  zIndex,
  top,
}) => {
  return (
    <i
      className={`d-flex ${className}`}
      style={{
        height: ` ${size}`,
        width: ` ${size}`,
        minWidth: ` ${size}`,
        fontSize: ` ${size}`,
        color: `${color}`,
        opacity: `${opacity}`,
        zIndex: `${zIndex}`,
        top: `${top}`,
        transform: `
      rotate(${rotate}deg)`,
        transition: 'all 0.3s ease',
      }}
    >
      {children}
    </i>
  );
};
