import React from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import '@reach/combobox/styles.css';
import { useFormContext } from 'react-hook-form';
import { SvgBox } from '../../images/icons/SvgBox';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';

export const LocationSuggestion = ({
  placeholderValue,
  defaultValue,
  propertyValue,
  inputClassName,
}) => {
  const { setValue: setValueForm } = useFormContext();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue,
  });
  const handleInput = async (e) => {
    setValue(e.target.value);
    setValueForm(propertyValue, e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address);
    setValueForm(propertyValue, address);
  };

  return (
    <Combobox onSelect={handleSelect} aria-labelledby='demo'>
      <ComboboxInput
        value={value}
        placeholder={placeholderValue}
        onChange={handleInput}
        disabled={!ready}
        className={`w-100 p-2 ${inputClassName}`}
        style={{
          height: '35px',
          border: '1px solid #ccc',
          fontFamily: 'inherit',
        }}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <div
                style={{
                  zIndex: 1000000,
                  position: 'relative',
                }}
                key={place_id}
                className='d-flex align-items-center border-bottom bg-white'
              >
                <SvgBox className='text-dark mx-2' size='20px'>
                  <Logo />
                </SvgBox>

                <ComboboxOption className='py-3 w-100' value={description} />
              </div>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
