import React from 'react';
import { NavLink } from 'react-router-dom';

// icons
import { ReactComponent as HouseIcon } from '../../images/icons/house.svg';
import { ReactComponent as ListIcon } from '../../images/icons/web-page.svg';
import { ReactComponent as UserIcon } from '../../images/icons/user.svg';
import { SvgBox } from '../../images/icons/SvgBox';
import { USER_TYPES } from '../../utils/userTypes';
import { useGetProfile } from '../../hooks/useGetProfile';

const Sidebar = ({ className }) => {
  const { data: user } = useGetProfile();

  return (
    <div className={`sidebar-container ${className}`}>
      <div className='sidebar-container__box'>
        <ul className='nav flex-center pt-4'>
          {user?.userType !== USER_TYPES.serviceProvider ? (
            <li className='nav-item w-100'>
              <NavLink exact to='/dashboard'>
                <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                  <SvgBox size='20px'>
                    <ListIcon />
                  </SvgBox>
                  <span className='ml-3'>Dashboard</span>
                </div>
              </NavLink>
            </li>
          ) : null}

          {/* <li className='nav-item w-100'>
            <NavLink exact to='/profile/update'>
              <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                <SvgBox size='20px'>
                  <UserIcon />
                </SvgBox>
                <span className='ml-3'>My Profile</span>
              </div>
            </NavLink>
          </li> */}
          {user?.userType !== USER_TYPES.serviceProvider ? (
            <>
              <li className='nav-item w-100'>
                <NavLink exact to='/profile/messages'>
                  <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                    <SvgBox size='20px'>
                      <UserIcon />
                    </SvgBox>
                    <span className='ml-3'>Messages</span>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item w-100'>
                <NavLink exact to='/partners'>
                  <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                    <SvgBox size='20px'>
                      <UserIcon />
                    </SvgBox>
                    <span className='ml-3'>Partners</span>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item w-100'>
                <NavLink exact to={`/my-properties?state=${'active'}`}>
                  <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                    <SvgBox size='20px'>
                      <HouseIcon />
                    </SvgBox>
                    <span className='ml-3'>My Properties List</span>
                  </div>
                </NavLink>
              </li>
            </>
          ) : (
            <li className='nav-item w-100'>
              <NavLink exact to='/my-services'>
                <div className='sidebar-container__box-item-container d-flex align-items-center text-capitalize'>
                  <SvgBox size='20px'>
                    <HouseIcon />
                  </SvgBox>
                  <span className='ml-3'>My Ads List</span>
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
