import React from 'react';
import { Link, useParams } from 'react-router-dom';
import CroydonNavbar from '../../components/global-components/CroydonNavbar';
import { Loader } from '../../components/Loader';
import { useAgencyProfile } from '../../hooks/useAgencyProfile';

import { CroydonContactForm } from './components/CroydonContactForm';

const contactList = [
  {
    key: 0,
    text: '020 8726 7968',
  },
  {
    key: 1,
    text: 'info@croydon commercial',
  },
  {
    key: 2,
    text: 'Mon-Fri 9:00-17:30',
  },
  {
    key: 3,
    text: '@croydoncommercial',
  },
  {
    key: 4,
    text: '@croydoncommercial',
  },
];
const CroydonContact = () => {
  const { id } = useParams();

  const { isLoading: isLoadingProfile, data } = useAgencyProfile({ id });

  if (isLoadingProfile) {
    return <Loader />;
  }

  return (
    <main className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r'>
      <CroydonNavbar data={data} />
      <main className='d-flex flex-column flex-fill mt-3 p-r'>
        <section className='container-1 w-100 my-5'>
          <div className='row'>
            <div className='col-12 col-md-4 col-xl-3'>
              <div>
                <h1 className='title-font display-4 font-weight-normal mb-4'>
                  CONTACT{' '}
                  <div
                    className='title-font display-2 font-weight-bold'
                    style={{ lineHeight: 0.8 }}
                  >
                    US
                  </div>
                </h1>
                <h4 className='mb-0 title-font font-weight-light line-height-14'>
                  Croydon Commercial <br /> c/o White Label, <br /> 1st Floor
                  Suffolk House, <br /> George Street, <br /> Croydon, CR0 1PE
                </h4>
                <div>
                  {contactList.map((item) => (
                    <div key={item.key} className='d-flex align-items-end mt-4'>
                      <span className='mr-2'>
                        <img
                          src={`/assets/croydon-images/contact-icon-${item.key}.jpg`}
                          alt='..'
                        />
                      </span>
                      <h3 className='mb-0 title-font font-weight-light line-height-14'>
                        {item.text}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col'>
              <CroydonContactForm agencyDetails={data} />
            </div>
          </div>
        </section>
        {/* <section>
          <div className='px-md-4 py-4 py-md-5 bg-blue'>
            <h2 className='px-3 px-md-4 mb-3 text-white text-uppercase'>
              RELATED PROPERTIES
            </h2>
            <div className='mx-0 row row-cols-1 row-cols-md-2 row-cols-xl-4 justify-content-between'>
              {cards.map(item => (
                <div className='p-3 p-md-4'>
                  <div
                    className='croydon-card bg-image-cover d-flex flex-column'
                    style={{
                      height: '300px',
                      backgroundImage: `url(${item.image})`,
                    }}
                  >
                    <div className='mt-auto px-3 pt-3 pb-4 bg-black-o-4 d-flex flex-column justify-content-end'>
                      <div className='d-flex flex-wrap'>
                        <div>
                          <div
                            className='h2 text-uppercase text-white title-font font-weight-bold'
                            style={{ letterSpacing: '2px' }}
                          >
                            {item.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='mt-4 text-center'>
              <Link
                to='/croydon-home'
                className='btn-3 btn-text py-3 px-5 font-weight-bold title-font text-uppercase'
              >
                Back
              </Link>
            </div>
          </div>
        </section> */}
        <section className='croydon-footer bg-white py-4'>
          <div className='d-flex flex-wrap'>
            <div
              style={{
                height: 100,
              }}
              className='partners-item  m-0 px-3'
            >
              <img
                className='h-100 w-100 d-block'
                src='https://headsabovethecloud.co.uk/images/logo.png'
                alt='..'
              />
            </div>
          </div>
          <div className='mt-5 text-black text-center'>
            <span>© 2023 Croydon Commercial. All rights reserved.</span>
          </div>
        </section>
      </main>
    </main>
  );
};

export default CroydonContact;
