import React, { useState } from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
import { Spinner } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../../images/icons/search.svg';
import { LocationSuggestion } from '../../LocationSuggestion';
import AdvancedSearch from '../home/AdvancedSearch';
import { getParam } from '../../../utils/urlHelper';
import { useSearchState } from './hooks/useSearchState';

export const Searchbar = ({
  loading,
  isCroydonBranded = false,
  isCroydon = false,
}) => {
  const [modal, setModal] = useState(false);
  const [text] = useState(getParam('text', 'single'));
  const toggle = () => setModal(!modal);

  useSearchState();

  return (
    <div
      style={{
        background: `${isCroydonBranded ? '#F39201' : 'transparent'}  `,
        padding: isCroydonBranded ? 20 : 0,
      }}
    >
      <AdvancedSearch isOpen={modal} toggle={toggle} isCroydon />
      <div className='row align-items-center py-3 py-md-0'>
        <div className={`rld-single-select col-12 col-md-7`}>
          <div>
            <LocationSuggestion
              inputClassName={`${isCroydon && 'border-blue'}`}
              propertyValue={'text'}
              defaultValue={text}
              placeholderValue='e.g Croydon, Oakfield Road'
            />
          </div>
        </div>

        <div className='col-2 col-xl-2'>
          <button
            onClick={toggle}
            type='button'
            className='d-flex align-items-center ml-auto justify-content-lg-center h-100 text-dark text-decoration-none bg-transparent mt-3 mt-md-0'
            style={{ border: 'none' }}
          >
            <div className={`mt-2 `}>
              <h5>Filters</h5>
            </div>
          </button>
        </div>
        <div className='col-12 col-md-3'>
          <div className='text-center'>
            <button
              disabled={loading}
              type='submit'
              className={`w-100 btn btn-primary text-white`}
            >
              <div className='d-flex justify-content-center align-items-center h-100'>
                {loading && <Spinner className='mr-3' size='sm' />}

                <SvgBox className='mr-2' size='16px'>
                  <SearchIcon />
                </SvgBox>

                <span
                  className='font-weight-bold text-uppercase font-oswald'
                  style={{ fontSize: 13 }}
                >
                  Search
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
