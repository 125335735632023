import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import Navbar from '../../components/global-components/navbar';
import PropertiesSection from '../../components/properties-components/properties';
import Footer from '../../components/global-components/footer';

const getAllFeaturedProperties = async () => {
  try {
    const res = await axios.get(`/property/featured?limit=${16}`);
    return res.data.properties;
  } catch (err) {
    throw err.response;
  }
};
const FeaturedProperty = () => {
  const { data, isLoading, error } = useQuery(
    'allFeaturedProperties',
    getAllFeaturedProperties,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <div
            class='spinner-border text-dark mt-5'
            style={{ height: '3rem', width: '3rem' }}
            role='status'
          >
            <span class='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div class='spinner-custom mt-5'>
        <div class='text-center mt-5'>
          <h2 class='mt-5'>Something Went Wrong...</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <PropertiesSection properties={data} title='' />
      <Footer />
    </div>
  );
};

export default FeaturedProperty;
