import React from 'react';
// import { Link } from 'react-router-dom';
import CroydonNavbar from '../../components/global-components/CroydonNavbar';
import { useAgencyProfile } from '../../hooks/useAgencyProfile';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router';
import { CroydonPartners } from './components/CroydonPartners';
import { useAgencyListing } from '../../hooks/useAgencyListing';
import { CroydonCard } from './components/CroydonCard';
import { Col } from 'reactstrap';
import { getParam, getSearchParams, setParams } from '../../utils/urlHelper';
import { ScrollToTop } from '../../components/ScrollToTop';

const CroydonListings = () => {
  const [tabs, setTabs] = React.useState(getParam('tab', 'single') ?? 'all');
  const queryData = getSearchParams();

  const { id } = useParams();

  const { isLoading: isLoadingProfile, data } = useAgencyProfile({ id });

  const { isLoading, data: listing } = useAgencyListing({
    id: data?._id,
  });

  if (isLoadingProfile && isLoading) {
    return <Loader />;
  }

  const toggleTab = (index) => {
    setTabs(index);
    setParams('tab', index, 'single', queryData);
  };

  const forSale = listing?.filter((data) => data.category.value === 'sale');
  const serviced = listing?.filter(
    (data) => data.category.value === 'serviced'
  );

  const coWorking = listing?.filter(
    (data) => data.category.value === 'co-working'
  );
  const rent = listing?.filter((data) => data.category.value === 'to rent');

  return (
    <main className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r text-white'>
      <CroydonNavbar data={data} />
      <ScrollToTop />
      <main className='d-flex flex-column flex-fill mt-3 p-r'>
        <section>
          <div className='px-md-4'>
            <div>
              <ul className='d-flex p-0 list-unstyled mb-0 overflow-auto text-nowrap'>
                <li className='mr-2' style={{ width: 190 }}>
                  <a
                    href
                    onClick={() => toggleTab('all')}
                    className={`btn btn-6 rounded-0 text-uppercase w-100 text-left ${
                      tabs === 'all' && 'active'
                    }`}
                  >
                    <h3 className='title-font ls-3 mb-0'>ALL</h3>
                  </a>
                </li>
                <li className='mr-2' style={{ width: 190 }}>
                  <a
                    href
                    onClick={() => toggleTab('for-sale')}
                    className={`btn btn-6 rounded-0 text-uppercase w-100 text-left ${
                      tabs === 'for-sale' && 'active'
                    }`}
                  >
                    <h3 className='title-font ls-3 mb-0'>FOR SALE</h3>
                  </a>
                </li>
                <li className='mr-2' style={{ width: 190 }}>
                  <a
                    href
                    onClick={() => toggleTab('to-let')}
                    className={`btn btn-6 rounded-0 text-uppercase w-100 text-left ${
                      tabs === 'to-let' && 'active'
                    }`}
                  >
                    <h3 className='title-font ls-3 mb-0'>TO LET</h3>
                  </a>
                </li>
                <li className='mr-2' style={{ width: 190 }}>
                  <a
                    href
                    onClick={() => toggleTab('serviced')}
                    className={`btn btn-6 rounded-0 text-uppercase w-100 text-left ${
                      tabs === 'serviced' && 'active'
                    }`}
                  >
                    <h3 className='title-font ls-3 mb-0'>SERVICED</h3>
                  </a>
                </li>
                <li className='mr-2' style={{ width: 190 }}>
                  <a
                    href
                    onClick={() => toggleTab('co-working')}
                    className={`btn btn-6 rounded-0 text-uppercase w-100 text-left ${
                      tabs === 'co-working' && 'active'
                    }`}
                  >
                    <h3 className='title-font ls-3 mb-0'>CO-WORKING</h3>
                  </a>
                </li>
              </ul>
            </div>
            {/* Tabs */}
            <div className='tabs bg-blue pb-4 px-4'>
              <div
                className={`  ${
                  tabs === 'all' ? 'd-block slide-in-bottom' : 'd-none'
                }`}
              >
                {/* Search start */}
                <div className='mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 align-items-baseline mx-0'>
                  <h3 className='title-font ls-3 mb-0 mt-4'>
                    SHOWING {listing?.length}
                  </h3>
                </div>

                <div className='row '>
                  {listing?.map((item) => (
                    <Col key={item._id} md={3}>
                      <CroydonCard item={item} isSearch />
                    </Col>
                  ))}
                </div>
              </div>
              <div
                className={`  ${
                  tabs === 'for-sale' ? 'd-block slide-in-bottom' : 'd-none'
                }`}
              >
                {/* Search start */}
                <div className='mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 align-items-baseline mx-0'>
                  <h3 className='title-font ls-3 mb-0 mt-4'>
                    SHOWING {forSale?.length}
                  </h3>
                </div>

                <div className='row '>
                  {forSale?.map((item) => (
                    <Col key={item._id} md={3}>
                      <CroydonCard item={item} isSearch />
                    </Col>
                  ))}
                </div>
              </div>
              <div
                className={`  ${
                  tabs === 'to-let' ? 'd-block slide-in-bottom' : 'd-none'
                }`}
              >
                {/* Search start */}
                <div className='mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 align-items-baseline mx-0'>
                  <h3 className='title-font ls-3 mb-0 mt-4'>
                    SHOWING {rent?.length}
                  </h3>
                </div>
                {/* Search end */}
                <div className='row '>
                  {rent?.map((item) => (
                    <Col key={item._id} md={3}>
                      <CroydonCard item={item} isSearch />
                    </Col>
                  ))}
                </div>
              </div>
              <div
                className={`  ${
                  tabs === 'serviced' ? 'd-block slide-in-bottom' : 'd-none'
                }`}
              >
                <div className='mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 align-items-baseline mx-0'>
                  <h3 className='title-font ls-3 mb-0 mt-4'>
                    SHOWING {serviced?.length}
                  </h3>
                </div>

                <div className='row '>
                  {serviced?.map((item) => (
                    <Col key={item._id} md={3}>
                      <CroydonCard item={item} isSearch />
                    </Col>
                  ))}
                </div>
              </div>
              <div
                className={`  ${
                  tabs === 'co-working' ? 'd-block slide-in-bottom' : 'd-none'
                }`}
              >
                {/* Search start */}
                <div className='mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 align-items-baseline mx-0'>
                  <h3 className='title-font ls-3 mb-0 mt-4'>
                    SHOWING {coWorking?.length}
                  </h3>
                </div>
                {/* Search end */}

                <div className='row '>
                  {coWorking?.map((item) => (
                    <Col key={item._id} md={3}>
                      <CroydonCard item={item} isSearch />
                    </Col>
                  ))}
                </div>
              </div>
              {/* <div className='mt-4 pb-3'>
                <Link
                  to='/croydon-listings'
                  className='btn-3 btn-text py-3 px-4 font-weight-bold title-font text-uppercase'
                >
                  View all
                </Link>
              </div> */}
            </div>
          </div>
        </section>

        <section className='croydon-footer bg-white py-4'>
          <div className='d-flex flex-wrap'>
            <div
              style={{
                height: 100,
              }}
              className='partners-item  m-0 px-3'
            >
              <img
                className='h-100 w-100 d-block'
                src='https://headsabovethecloud.co.uk/images/logo.png'
                alt='..'
              />
            </div>
          </div>
          <div className='mt-5 text-black text-center'>
            <span>© 2023 Croydon Commercial. All rights reserved.</span>
          </div>
        </section>
      </main>
    </main>
  );
};

export default CroydonListings;
