import React, { useState } from 'react';
import { truncateString } from '../../../utils/truncateString';
import { ReqModal } from './requestModal';

export const DeskRequestItem = (props) => {
  const { request } = props;
  const [showReqModal, setShowReqModal] = useState(false);

  return (
    <>
      <tr
        style={{
          fontSize: 14,
        }}
      >
        <td>{request.name}</td>
        <td>{request.email}</td>
        <td className=' '>{truncateString(request.message, 100)}</td>
        <td className=''>
          <button
            className='btn btn-primary border-0'
            onClick={() => setShowReqModal(true)}
          >
            Reply
          </button>
        </td>
      </tr>
      {showReqModal ? (
        <ReqModal
          showReqModal={showReqModal}
          setShowReqModal={setShowReqModal}
          propertyTitle={request.property.title}
          requestId={request._id}
          requestorName={request.name}
        />
      ) : null}
    </>
  );
};
