import React from 'react';
import ReactPlayer from 'react-player';

export const PropertyVideo = ({ property }) => {
  return (
    <div>
      <div>
        <h5 className='mb-0 title-font font-weight-bold text-uppercase ls-3'>
          Video TOUR
        </h5>

        <div className='mt-4'>
          <ReactPlayer height='450px' width='100%' url={property?.videoUrl} />
        </div>
      </div>
    </div>
  );
};
