import React from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';

const AboutBanner = () => {
  const inlineStyle = {
    backgroundImage: `url(${sectiondata.pageHeaders.pricing})`,
    backgroundColor: '#0000005e',
    backgroundBlendMode: 'overlay',
  };

  return (
    <div
      className='banner-area jaralla row mx-0 py-5 position-relative'
      style={inlineStyle}
    >
      <div className='container'>
        <div className='py-5'>
          <div className='row w-100'>
            <div className='col-lg-3 ' />
            <div className='col text-center'>
              <h2 className='font-weight-bold text-white mb-5'>
                List and sell your properties <br />
                without paying fees
              </h2>
              <Link to='/register' className='custom-btn-primary py-3 px-5'>
                SIGNUP
              </Link>
            </div>
            <div className='col-lg-3 mt-5 mt-lg-0 text-center text-lg-left'>
              <div className='bg-icon pl-3 py-3 pr-1 rounded-lg'>
                <h4 className='text-primary font-weight-bolder'>
                  Free benefits include:
                </h4>
                <ul className='nav flex-column '>
                  <li className='mt-2'>Unlimited listings</li>
                  <li className='mt-2'>
                    Manual and automated listings uploads
                  </li>
                  <li className='mt-2'>Track listings from dashboard</li>
                  <li className='mt-2'>Alerts and notifications</li>
                  <li className='mt-2'>Real time statistics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
