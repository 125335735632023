import React from 'react';
import Navbar from '../../../components/global-components/navbar';
import sectiondata from '../../../data/sections.json';
import { Searchbar } from '../search/Searchbar';
import { FormProvider } from 'react-hook-form';

import { useSearchParams } from '../search/hooks/useSearchParams';
import { Link } from 'react-router-dom';

// icons
import { SvgBox } from '../../../images/icons/SvgBox';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrowRight.svg';

const Banner = () => {
  const { onHandleSubmit, methods } = useSearchParams();

  const data = sectiondata.banner;
  const inlineStyle = {
    //backgroundImage: `url(${sectiondata.pageHeaders.home})`,
    backgroundImage: `url(/assets/home-images/banner-1.jpg)`,
    backgroundBlendMode: 'overlay',
    backgroundColor: `rgba(0,0,0,0.4)`,
  };

  const list = [
    { linkName: 'LOCAL AGENCIES' },
    { linkName: 'EMPLOYERS' },
    { linkName: 'INVESTORS' },
    { linkName: 'DEVELOPERS' },
    { linkName: 'ASSET OWNERS' },
    { linkName: 'LANDLORDS' },
  ];

  // return (
  //   <div className='banner-area jarallax' style={inlineStyle}>
  //     <Navbar />
  //     <div className='container'>
  //       <div className='banner-inner-wrap'>
  //         <div className='row mt-lg-5 mx-auto'>
  //           <div className='col-md-12 col-lg-12 px-0'>
  //             <div className='banner-inner text-center'>
  //               <h5 className='mb-lg-2 text-white text-uppercase'>
  //                 {data.subtitle}
  //               </h5>
  //               <h1 className='display-3 text-white text-capitalize'>
  //                 {data.title1} {data.title2}
  //               </h1>
  //             </div>
  //           </div>
  //           <div className='col-md-12 col-lg-12 px-0'>
  //             <div className='banner-search-wrap'>
  //               <div>
  //                 <div>
  //                   <div className='rld-main-search'>
  //                     <FormProvider {...methods}>
  //                       <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
  //                         <Searchbar />
  //                       </form>
  //                     </FormProvider>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className='banner-area jarallax' style={inlineStyle}>
      <Navbar />
      <div className='container position-relative py-5 mb-5'>
        <span className='pos-arrow' style={{ left: '-12%' }}>
          <SvgBox size='130px' color='#0097aec3'>
            <ArrowIcon />
          </SvgBox>
        </span>
        <span className='pos-arrow' style={{ right: '-12%' }}>
          <SvgBox size='130px' color='#0097aec3' rotate={180}>
            <ArrowIcon />
          </SvgBox>
        </span>
        <div className='banner-inner-wrap'>
          <div className='row mt-lg-5 mx-auto'>
            <div className='col-md-12 col-lg-12 px-0'>
              <div className='banner-inner text-center mb-5'>
                <h5 className='mb-lg-4 text-white text-uppercase'>
                  {/* {data.subtitle} */}
                  Moveappy
                </h5>
                <h1 className='d-none d-lg-block display-3 text-white font-weight-bold font-weight-normal'>
                  {/* {data.title1} {data.title2} */} A marketplace focussed on
                  delivering local growth, investment and jobs
                </h1>
                <h1 className='d-lg-none text-white font-weight-bold font-weight-normal'>
                  {/* {data.title1} {data.title2} */} A marketplace focussed on
                  delivering local growth, investment and jobs
                </h1>
              </div>
            </div>
            <div className='col-md-12 col-lg-12 px-0'>
              <div className='row'>
                <div className='col'>
                  <div className='h-100'>
                    <ul
                      className='nav h-100 flex-row flex-xl-column'
                      style={{ gap: 20 }}
                    >
                      {list.map((item) => (
                        <li>
                          <Link
                            to
                            className='d-none d-lg-block text-uppercase text-white h2 mb-0 font-weight-bold'
                          >
                            {item.linkName}
                          </Link>
                          <Link
                            to
                            className='d-lg-none text-uppercase text-white h4 mb-0 font-weight-bold'
                          >
                            {item.linkName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-md-12 col-xl-9 mb-4 mb-xl-0 order-first order-xl-last'>
                  <div className='banner-search-wrap mt-0'>
                    <div>
                      <div>
                        <div className='rld-main-search p-3'>
                          <FormProvider {...methods}>
                            <form
                              onSubmit={methods.handleSubmit(onHandleSubmit)}
                            >
                              <Searchbar />
                            </form>
                          </FormProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='pt-5 mt-4'>
                    <div className='bg-primary-2-70 text-white px-4'>
                      <div className='row row-cols-1 row-cols-xl-2'>
                        <div className='p-3'>
                          <span className='h5 mb-0 line-height-2'>
                            Moveappy is designed to partner localised property
                            and development opportunities with professionals,
                            occupiers and employers to encourage inward
                            investment and employment.
                          </span>
                        </div>
                        <div className='p-3'>
                          <span className='h6 line-height-2 font-weight-normal mb-0'>
                            With 360 visibility, by area of regeneration, and
                            local agency development partners, Moveappy‘s search
                            and comparison toolset manages interactions and
                            connects businesses and investors directly to key
                            decision makers and project partners.
                          </span>
                          <div className='mt-3 text-right'>
                            <Link
                              to='/contact'
                              className='btn btn-orange text-white rounded-0'
                            >
                              <span className='h6 mb-0 text-uppercase'>
                                Contact us
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
