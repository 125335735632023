import axios from 'axios';
import { useQuery } from 'react-query';

const getAgencyListing = async id => {
  try {
    const res = await axios.get(`/property/owner/${id}`);
    return res.data.properties;
  } catch (err) {
    throw err.response.data;
  }
};

export const useAgencyListing = ({ id }) => {
  const { data, isLoading, error } = useQuery(
    [id, 'agency-listing'],
    () => getAgencyListing(id),
    {
      enabled: id ? true : false,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};
