import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MarkerComponent } from './mapComponents/propDetailNearPlaceMarker';

export const SimpleMapMbl = ({ property }) => {
  // setting markers
  const { lat, lng, title, nearSchools, nearRestaurants, nearHospitals } =
    property;
  let center = { lat, lng };
  let markers = [];
  markers.push({ lat, lng, title, type: 'prop' });
  if (nearSchools.length > 0) {
    nearSchools.map(el =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'school',
      })
    );
  }
  if (nearHospitals.length > 0) {
    nearHospitals.map(el =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'hospital',
      })
    );
  }
  if (nearRestaurants.length > 0) {
    nearRestaurants.map(el =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'hotel',
      })
    );
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDcGpYFY4FAxpuy06S1BLCHualvzCmrHWs' }}
        defaultCenter={center}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
      >
        {markers.map(mrk => (
          <MarkerComponent
            key={mrk.lat}
            lat={mrk.lat}
            lng={mrk.lng}
            text={mrk.title}
            type={mrk.type}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};
