import React from 'react';
import { Link } from 'react-router-dom';
import { truncateString } from '../../../utils/truncateString';

export const CroydonCard = ({ item, isSearch = false, row }) => {
  return (
    <div className={`${isSearch ? null : 'croydon-item-box'} ${row && 'p-3'}`}>
      <div
        className='croydon-card bg-image-cover mb-4 d-flex flex-column'
        style={{
          backgroundImage: `url(${item?.coverImage ?? item.images[0]})`,
        }}
      >
        <div className='croydon-card__overlay mt-auto p-3 bg-black-o-4 d-flex flex-column justify-content-end'>
          <div className='d-flex flex-wrap text-left h-100'>
            <div>
              <div className='text-title-listings text-uppercase text-white title-font font-weight-bold'>
                {truncateString(item.title, 205)}
              </div>
              <div className='font-weight-light text-uppercase text-white font-weight-normal title-font'>
                {truncateString(item.location, 200)}
              </div>
              <div className='text-uppercase text-croydon font-weight-bold'>
                {item?.type?.label}
              </div>
            </div>
            <div className='mt-auto ml-auto pl-2 pt-3'>
              <Link
                to={`/property/${item?.slug}`}
                className='btn-3 h4 mb-0 py-2 px-4 font-weight-normal title-font text-uppercase'
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
