import React from 'react';
import Navbar from '../../components/global-components/navbar';
import { PropertyForm } from '../../components/properties-components/addPropertyForm/PropertyForm';
import Footer from '../../components/global-components/footer';
import { useCreateProperty } from './hooks/useCreateProperty';

export const AddPropertyPage = () => {
  const { mutation } = useCreateProperty();

  return (
    <div className='vh-100 d-flex flex-column'>
      <Navbar />
      <div className='flex-fill'>
        <PropertyForm type='add' mutation={mutation} />
      </div>
      <Footer />
    </div>
  );
};
