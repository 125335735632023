import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Modal } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CroydonPropertySmall = ({
  Main = false,
  images,
  image,
  property,
  coverImage,
  index,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { category, isFeatured } = property;

  return (
    <>
      {Main ? (
        <div
          onClick={toggle}
          className='property-cover bg-image-cover d-flex justify-content-between align-items-end'
          style={{
            backgroundImage: `url(${coverImage ?? images[0]})`,
          }}
        >
          <span className='bg-blue text-uppercase text-white title-font h5 font-weight-bold py-2 px-4 mb-0'>
            {category?.value}
          </span>

          {isFeatured ? (
            <span className='bg-black-1 text-uppercase text-white title-font h5 font-weight-bold py-2 px-3 mb-0'>
              Featured
            </span>
          ) : (
            <span></span>
          )}
        </div>
      ) : (
        <div
          onClick={toggle}
          className='croydon-property-small bg-image-cover cursor-pointer'
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      )}

      <Modal
        className='container-croydon'
        contentClassName='bg-white p-3 d-flex flex-column justify-content-center'
        size='lg'
        centered
        isOpen={modal}
        toggle={toggle}
      >
        <Carousel showStatus={true} selectedItem={index}>
          {images?.map((value) => (
            <div
              className='d-flex justify-content-center align-items-center'
              key={value}
            >
              <img className='object-fit-contain' alt='' src={value} />
            </div>
          ))}
        </Carousel>
      </Modal>
    </>
  );
};

export default CroydonPropertySmall;
