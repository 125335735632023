import React from 'react';
import { Link } from 'react-router-dom';
//import { ExploreCitiesLoader } from './exploreCitiesLoader';
//import { useGetCities } from '../../../hooks/useGetCities';

const ExploreCities = () => {
  const list = [
    { image: '/assets/home-images/image-5.jpg', city: 'Croydon' },
    {
      image:
        'https://images.unsplash.com/photo-1507992781348-310259076fe0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
      city: 'Toronto',
    },
    {
      image: '/abuja.jpeg',
      city: 'Abuja',
    },
    // {
    //   image: '/assets/home-images/image-3.jpg',
    //   city: 'Manchester',
    //   bottom: true,
    // },
    // {
    //   image: '/assets/home-images/image-4.jpg',
    //   city: 'Oxford',
    //   bottom: true,
    // },
    // {
    //   image: '/assets/home-images/image-0.jpg',
    //   city: 'Liverpool',
    //   bottom: true,
    // },
  ];

  return (
    <section className='explore-cities-container border-bottom bg-light py-5'>
      <div className='container'>
        <div className='section-title mb-4 text-center'>
          <div>
            <h1 className='d-none d-md-block'>Local Partners</h1>
            <h1 className='text-left d-md-none'>Local Partners</h1>
          </div>
        </div>
        <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 text-capitalize'>
          {list?.map((item) => (
            <Link to={`/city/${item.city}`} className='p-3'>
              <div className='d-flex flex-column flex-sm-row text-center text-sm-left'>
                <div
                  className='bg-light position-relative mb-3 mb-sm-0'
                  style={{ height: '20rem' }}
                >
                  <img className='w-100 h-100' src={item.image} alt='' />
                  <div
                    className='position-absolute'
                    style={{ top: 0, right: 0 }}
                  >
                    <div className='h2 p-3 mb-0 text-shadow text-white'>
                      {item.city}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}{' '}
        </div>
      </div>
    </section>
  );
};

export default ExploreCities;
