import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import { useContactAgency } from '../../../hooks/useContactAgency';
import { SelectTwoStyle } from '../../../styles/react-select-styles/SelectTwoStyle';

const options = [
  { value: 'Resident', label: 'Resident' },
  { value: 'Local Business', label: 'Local Business' },
  { value: 'Business Owner', label: 'Business Owner' },
  { value: 'Property Developer', label: 'Property Developer' },
  { value: 'Regeneration Professional', label: 'Regeneration Professional' },
  {
    value: 'Local / Central Government',
    label: 'Local / Central Government',
  },
];

export const CroydonContactForm = ({ agencyDetails }) => {
  const { register, handleSubmit, errors, reset, control, setValue } =
    useForm();
  const { _id, email, username } = agencyDetails;

  const { mutation } = useContactAgency();

  const onSubmit = data => {
    data.receiverEmail = email;
    data.receiverUsername = username;
    data.receiverId = _id;

    mutation.mutateAsync(data, {
      onSuccess: () => {
        reset();
        setValue('senderSubject', '');
      },
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12 col-lg-12 mt-4'>
            <input
              className='form-control border-blue rounded-0 py-4 px-3'
              type='text'
              name='senderName'
              placeholder='First Name'
              ref={register}
            />
          </div>

          <div className='col-12 mt-4'>
            <input
              className='form-control border-blue rounded-0 py-4 px-3'
              type='email'
              name='senderEmail'
              placeholder='Email Address'
              ref={register({ required: true })}
            />
            <ErrorMessage
              errors={errors}
              name='senderEmail'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-danger mt-1 mb-0'>{message}</p>
              )}
            />
          </div>
          <div className='col-12 mt-4'>
            <input
              className='form-control border-blue rounded-0 py-4 px-3'
              type='text'
              name='senderPhone'
              placeholder='Phone Number'
              ref={register}
            />
          </div>
          <div className='col-12 mt-4'>
            <Controller
              name='senderSubject'
              control={control}
              as={
                <Select
                  placeholder='Select An Option'
                  options={options}
                  styles={SelectTwoStyle}
                />
              }
            />
          </div>
          <div className='col-12 mt-4'>
            <textarea
              name='message'
              className='form-control border-blue rounded-0 py-3 px-3'
              placeholder='Comments'
              ref={register({ required: true })}
              rows='6'
            />
            <ErrorMessage
              errors={errors}
              name='message'
              message='This field is required.'
              render={({ message }) => (
                <p className='text-danger mt-1 mb-0'>{message}</p>
              )}
            />
          </div>
          <div className='mt-4 px-3'>
            <button
              type='submit'
              disabled={mutation.isLoading}
              className='btn-5 btn-text border py-3 px-4 font-weight-bold title-font text-uppercase'
            >
              {mutation.isLoading && <Spinner size='sm' className='mr-2' />}
              Submit Form
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
