import React from 'react';
import { Table } from 'reactstrap';
import { DeskRequestItem } from './deskRequestItem';
import { MblRequestItem } from './mblRequestItem';

export const RequestTable = (props) => {
  const { requests } = props;
  return (
    <>
      <Table borderless hover striped className=' d-none d-lg-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((value, index) => (
            <DeskRequestItem request={value} index={index} />
          ))}
        </tbody>
      </Table>
      {requests.map((value, ind) => (
        <MblRequestItem request={value} index={ind} />
      ))}
    </>
  );
};
