import React from 'react';
import { Spinner } from 'reactstrap';

export const Loader = () => {
  return (
    <div className='d-flex vh-100 justify-content-center align-items-center'>
      <Spinner size='lg' />
    </div>
  );
};
