import React from 'react';
import { SvgBox } from '../../../images/icons/SvgBox';
import { getDistanceFromLatLonInKm } from '../../../utils/distanceBetweenTwoCoord';
import { ReactComponent as HouseIcon } from '../../../images/icons/house.svg';
import { ReactComponent as HeartIcon } from '../../../images/icons/heartbeat.svg';
import { ReactComponent as CapIcon } from '../../../images/icons/graduate-cap.svg';

export const NearbyPlaces = ({ details }) => {
  const { lat, lng, nearSchools, nearRestaurants, nearHospitals } = details;

  const nearbyArr = [
    {
      title: 'Education',
      icon: <CapIcon />,
      nearby: nearSchools,
    },
    {
      title: 'Health & Medical',
      icon: <HeartIcon />,
      nearby: nearHospitals,
    },
    {
      title: 'Hotels',
      icon: <HouseIcon />,
      nearby: nearRestaurants,
    },
  ];

  return (
    <div>
      <div className='font-container-text'>
        <div>
          {nearbyArr?.map((value) => (
            <div className='mb-4'>
              <div className='d-flex align-items-center mb-4'>
                <span
                  className='icon-box bg-light mr-2'
                  style={{ height: 30, width: 30 }}
                >
                  <SvgBox size='15px'>{value.icon}</SvgBox>
                </span>
                <h6 className='mb-0'>{value.title}</h6>
              </div>
              {value.nearby.map((item) => (
                <div key={item._id} className='row mx-0 mb-1'>
                  <div className='col-md-12 px-0'>
                    <div className='row'>
                      <div className='col'>{item.name}</div>
                      <div className='col-4 ml-auto text-right'>
                        {getDistanceFromLatLonInKm(
                          lat,
                          lng,
                          item.geometry.location.lat,
                          item.geometry.location.lng
                        ).toFixed(2)}
                        km
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
