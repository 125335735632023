import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';

export const PropertyFeatures = ({ features }) => {
  const [modalFeatures, setModalFeatures] = useState(false);

  return (
    <div>
      <div>
        {features?.length > 0 ? (
          <div className='pb-5 mb-5 border-bottom d-none d-md-block'>
            <div>
              <h4 className='mb-4 text-uppercase font-weight-bold'>
                Amenities
              </h4>
              <div className='row row-cols-2 row-cols-md-4 row-cols-lg-3 row-cols-xl-4 mx-0 text-black-50'>
                {features.map(value => (
                  <div className='p-2'>
                    <div className='d-flex align-items-center mb-3'>
                      <img
                        alt=''
                        style={{
                          height: 20,
                          width: 20,
                        }}
                        src={value.icon}
                        className=' mr-2 overflow-auto'
                      />

                      <div className='mb-0'>{value.label}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {/* Mobile View Start */}
        {features.length > 0 ? (
          <div className='pb-5 mb-5 border-bottom d-md-none'>
            <div>
              <h4 className='mb-4'>Features</h4>
              <div className='row row-cols-1 row-cols-md-4 row-cols-lg-3 row-cols-xl-4 mx-0 text-black-50'>
                {features.map((value, index) => (
                  <>
                    {index < 4 ? (
                      <div className='px-2 py-3 border-bottom'>
                        <div className='d-flex align-items-end'>
                          <div className='mb-0'>{value?.label}</div>
                          <div className='ml-auto'>
                            <img
                              alt=''
                              style={{
                                height: 20,
                                width: 20,
                              }}
                              src={value.icon}
                              className='bg-light mr-2 overflow-auto'
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
              <div className='mt-4'>
                <Button
                  block
                  color='outline-dark'
                  onClick={() => setModalFeatures(true)}
                >
                  Show all Features
                </Button>
              </div>
            </div>

            <Modal
              className='m-0 m-sm-auto rounded-0'
              contentClassName='border-0 rounded-0'
              centered
              isOpen={modalFeatures}
              toggle={() => setModalFeatures(false)}
            >
              <div className='d-flex flex-column h-100'>
                {/* Header Start */}
                <div className='d-flex align-items-center py-1 px-3 bg-white border-bottom position-sticky mb-5 fixed-top'>
                  <h5 className='mb-0 text-capitalize'>Features</h5>
                  <button
                    onClick={() => setModalFeatures(false)}
                    type='button'
                    className='btn p-0 ml-auto rounded-0'
                  >
                    <h1 className='mb-0 font-weight-light'>&times;</h1>
                  </button>
                </div>
                {/* Header End */}

                <div className='text-black-50 pt-1 px-3 overflow-auto'>
                  {features.map((value, i) => (
                    <div key={`${value}-${i}`} className='px-2'>
                      <div className='d-flex align-items-center py-3 border-bottom'>
                        <img
                          style={{
                            height: 20,
                            width: 20,
                          }}
                          alt={value.label}
                          src={value.icon}
                          className='bg-light mr-2'
                        />

                        <div className='mb-0'>{value.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </div>
  );
};
