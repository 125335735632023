import React, { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import notifications from '../notifications';

export const ResetPasswordd = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onSubmit = data => {
    setLoading(true);
    axios
      .patch(`/profile/reset/password/${id}`, data)
      .then(res => {
        setLoading(false);
        notifications.success('Success', 'Reset Password Successfully');
        history.push('/login');
      })
      .catch(err => {
        setLoading(false);
        notifications.error('Reset Form Error', 'Try Again Later!');
      });
  };

  return (
    <div className='register-page-area pd-bottom-100'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-4 col-lg-5 col-md-6'>
            <form
              className='contact-form-wrap contact-form-bg'
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4>Reset Password</h4>
              <div className='rld-single-input'>
                <input
                  type='password'
                  placeholder='Enter Password...'
                  name='password'
                  ref={register({
                    required: 'You must specify a password',
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                    pattern: {
                      value: /^(?=.*\d)(?=.*[A-Z]).{8,24}$/,
                      message:
                        'Password must be 8 to 24 characters long with at least one uppercase and one number character',
                    },
                  })}
                />
                {errors.password && (
                  <small className='font-weight-bold text-danger'>
                    {errors.password.message}
                  </small>
                )}
              </div>
              <div className='rld-single-input'>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  name='confirmPassword'
                  ref={register({
                    validate: value =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                />
                {/* {errors.password_repeat && <p>{errors.password_repeat.message}</p>} */}
                {errors.confirmPassword && (
                  <small className='font-weight-bold text-danger'>
                    {errors.confirmPassword.message}
                  </small>
                )}
              </div>
              {/* <div className="rld-single-input">
                <input type="text" placeholder="Re-enter password" />
              </div> */}
              <div className='btn-wrap'>
                <button
                  type='submit'
                  disabled={loading}
                  className={`btn btn-primary ${
                    loading === true ? 'button-disabled' : null
                  }`}
                >
                  {/* {loading === true ? (
                                        <div className="spinner-btn mt-2"></div>
                                    ) : <>Reset Password</>} */}
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
