import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios';
import { ReactQueryDevtools } from 'react-query/devtools';
import './styles/index.css';
import './styles/main.scss';
import './styles/scroll.styles.css';
import 'react-toggle/style.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

//const localUrl = 'http://localhost:9091';
const baseURL = 'https://stockappy-backend.herokuapp.com/';

axios.defaults.baseURL = baseURL;

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
