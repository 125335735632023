import React, { useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { MarkerComponent } from './mapComponents/markerwithCard';

const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#686868',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: '-22',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        saturation: '11',
      },
      {
        lightness: '-51',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        saturation: '3',
      },
      {
        lightness: '-56',
      },
      {
        weight: '2.20',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-52',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        weight: '6.13',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        lightness: '-10',
      },
      {
        gamma: '0.94',
      },
      {
        weight: '1.24',
      },
      {
        saturation: '-100',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        lightness: '-16',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: '-41',
      },
      {
        lightness: '-41',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        weight: '5.46',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '0.72',
      },
      {
        lightness: '-16',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-37',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#b7e4f4',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
const Marker = ({ children }) => children;

export const ClusterMap = ({ markers, isCroydon }) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [cardModal, setCardModal] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [zoom, setZoom] = useState(16);

  const properties = markers;

  const points = properties.map((property) => ({
    type: 'Feature',
    property: property,
    properties: {
      cluster: false,
      crimeId: property?._id,
      category: property?.category?.value,
    },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(property?.lng), parseFloat(property?.lat)],
    },
  }));

  const getMapBounds = (_, maps, markers) => {
    const bounds = new maps.LatLngBounds();
    markers.map((place) =>
      bounds.extend(new maps.LatLng(place.lat, place.lng))
    );
    return bounds;
  };
  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, markers) => {
    // Get bounds by our markers
    const bounds = getMapBounds(map, maps, markers);

    //center the map to the geometric center of all markers
    map.setCenter(bounds.getCenter());

    //remove one zoom level to ensure no marker is on the edge.
    map.setZoom(map.getZoom() - 1);
    // set a minimum zoom
    // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
    if (map.getZoom() > 16) {
      map.setZoom(16);
    }
    // Fit map to bounds
    map.fitBounds(bounds);
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 16 },
  });

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        options={{
          styles: isCroydon ? mapStyles : undefined,
        }}
        bootstrapURLKeys={{ key: 'AIzaSyDcGpYFY4FAxpuy06S1BLCHualvzCmrHWs' }}
        defaultZoom={zoom}
        defaultCenter={{ lat: 54.9722, lng: 2.4609 }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          apiIsLoaded(map, maps, markers);
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.properties.cluster_id}`}
                lat={latitude}
                lng={longitude}
                defaultZoom={zoom}
              >
                <div
                  className='cluster-marker text-dark h5 button-glow font-weight-bold shadow bg-white'
                  style={{
                    width: `${10 + (pointCount / points.length) * 30}px`,
                    height: `${10 + (pointCount / points.length) * 30}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(
                        cluster.properties.cluster_id
                      ),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          } else {
            return (
              <MarkerComponent
                lat={latitude}
                lng={longitude}
                isCroydon={isCroydon}
                property={cluster.property}
                cardModal={cardModal}
                setCardModal={setCardModal}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                index={index}
              />
            );
          }
        })}
      </GoogleMapReact>
    </div>
  );
};
