import React from 'react';
import Navbar from '../components/global-components/navbar';
import Banner from '../components/section-components/pricing-banner';
import PricingSection from '../components/section-components/pricing';
import Footer from '../components/global-components/footer';

const Pricing = () => {
  return (
    <div className='d-flex flex-column vh-100'>
      <Navbar />
      <Banner />
      <section className='bg-light py-5 flex-fill'>
        <div className='py-5'>
          <PricingSection />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Pricing;
