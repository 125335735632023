import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Container,
} from 'reactstrap';
import classnames from 'classnames';
// icons
import { ReactComponent as IconGrid } from '../../images/icons/grid.svg';
import { ReactComponent as IconList } from '../../images/icons/list.svg';
import { SvgBox } from '../../images/icons/SvgBox';
import Card from '../properties-components/card';
import { useAgencyListing } from '../../hooks/useAgencyListing';
import { ContactAgencyModal } from '../contact-agency/ContactAgencyModal';

export const RealtorSectionOne = ({ data }) => {
  const [modal, setModal] = useState(false);

  const { phoneNumber, website, name, imageUrl } = data;

  const toggle = () => setModal(!modal);

  return (
    <section className='mt-5'>
      <ContactAgencyModal agencyDetails={data} isOpen={modal} toggle={toggle} />
      <Container>
        <Row>
          <Col>
            <div className='d-flex justify-content-between'>
              <div>
                <img
                  style={{ objectFit: 'contain', height: 40, width: 40 }}
                  src={imageUrl}
                  alt=''
                />
                <div className='mb-4'>
                  <h4 className='text-secondary text-capitalize mb-2'>
                    {name}
                  </h4>

                  <a rel='noreferrer' target='_blank' href={website}>
                    {website}
                  </a>
                </div>
              </div>

              <div className='d-flex justify-content-center align-content-center'>
                <div>
                  <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-primary text-uppercase '
                  >
                    Contact Us
                  </button>
                </div>

                <div className='px-3'>
                  <a href={`tel:${phoneNumber}`}>
                    <button
                      type='button'
                      className='btn  btn-outline-primary text-uppercase '
                    >
                      Call Agency
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const RealtorSectionTwo = ({ description, id }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [cardView, setCardView] = useState('grid');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { isLoading, data } = useAgencyListing({
    id,
  });

  return (
    <section className='pb-5'>
      <Container>
        <div>
          <Nav tabs className='justify-content-end tabs-buttons'>
            <NavItem className='ml-n2'>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Property Listing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Description
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <div className='my-4'>
                {isLoading ? (
                  <div class='spinner-custom mt-5'>
                    <div class='text-center mt-5'>
                      <div
                        class='spinner-border text-dark mt-5'
                        style={{ height: '3rem', width: '3rem' }}
                        role='status'
                      >
                        <span class='sr-only'>Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='row w-100 justify-content-end mx-0 mb-4'>
                      <div className=' d-flex justify-content-end'>
                        <div className='pl-3'>
                          <button
                            type='button'
                            onClick={() => setCardView('grid')}
                            className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                            style={{ height: 40, width: 40 }}
                          >
                            <SvgBox size='40px'>
                              <IconGrid />
                            </SvgBox>
                          </button>
                        </div>
                        <div className='pl-3'>
                          <button
                            type='button'
                            onClick={() => setCardView('list')}
                            className='btn-light d-flex align-items-center justify-content-center border-0 rounded'
                            style={{ height: 40, width: 40 }}
                          >
                            <SvgBox size='40px'>
                              <IconList />
                            </SvgBox>
                          </button>
                        </div>
                      </div>
                    </div>
                    {data.length > 0 ? (
                      <>
                        {cardView === 'list' ? (
                          <>
                            {data.map((item, i) => (
                              <Card
                                className={`col-12 mobile-card mx-auto p-0`}
                                mobileClassName='mobile-items-show w-100 bg-gray'
                                boxClassName='row mx-0'
                                colOneClassName='col-4 col-md px-0'
                                colTwoClassName='col-8 col-md-7 px-3 px-md-4 d-flex flex-column'
                                imageClassName='card-item-img'
                                userClassName='d-none'
                                item={item}
                                index={i}
                              />
                            ))}
                          </>
                        ) : (
                          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                            {data?.map((item, i) => (
                              <Card className='col' item={item} index={i} />
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className='section-title w-100 text-center py-5 mt-5'>
                          <div
                            className='mx-auto d-flex flex-column'
                            style={{ width: '70vmin' }}
                          >
                            <img
                              className='flex-fill w-100'
                              src={
                                require('../../images/no-properties.png')
                                  .default
                              }
                              alt='no-properties'
                            />
                            <h5 className='title'>Results not found...</h5>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </TabPane>
            <TabPane tabId='2'>
              <div className='mt-4'>
                <p
                  style={{
                    lineHeight: 3,
                  }}
                >
                  {description}
                </p>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </section>
  );
};
