import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import { PropertiesSection } from '../../components/properties-components/propertiesView/allPropertiesByCity';
import Footer from '../../components/global-components/footer';
import { usePropertiesByCity } from '../../hooks/usePropertiesByCity';
import { Error } from '../../utils/errorComponent';
import { Loader } from '../../components/Loader';
import { ScrollToTop } from '../../components/ScrollToTop';

const PropertiesByCity = () => {
  const { city } = useParams();
  const [limit, setLimit] = useState(9);
  const { data, isError, isLoading } = usePropertiesByCity(limit, 0, city);
  const showMoreHandler = () => {
    setLimit(limit + 9);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <section className='d-flex flex-column vh-100'>
      <ScrollToTop />
      <Navbar />
      <div className='flex-fill'>
        <PropertiesSection
          properties={data.properties}
          count={data.count}
          title={city}
          showMoreHandler={showMoreHandler}
          type='byCity'
        />
      </div>
      <Footer />
    </section>
  );
};

export default PropertiesByCity;
