import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MarkerComponent } from './mapComponents/propDetailNearPlaceMarker';

export const SimpleMap = ({ property, isCroydon }) => {
  const { lat, lng, title, nearSchools, nearRestaurants, nearHospitals } =
    property;
  const center = { lat, lng };
  const markers = [];
  markers.push({ lat, lng, title, type: 'prop' });

  if (nearSchools.length > 0) {
    nearSchools.map((el) =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'school',
      })
    );
  }
  if (nearHospitals.length > 0) {
    nearHospitals.map((el) =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'hospital',
      })
    );
  }
  if (nearRestaurants.length > 0) {
    nearRestaurants.map((el) =>
      markers.push({
        lat: el.geometry.location.lat,
        lng: el.geometry.location.lng,
        title: el.name,
        type: 'hotel',
      })
    );
  }

  return (
    <div className='map-container' style={{ height: '440px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDcGpYFY4FAxpuy06S1BLCHualvzCmrHWs' }}
        defaultCenter={center}
        defaultZoom={14}
        options={{
          fullscreenControl: true,
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {markers.map((mrk) => (
          <MarkerComponent
            key={mrk.lat}
            lat={mrk.lat}
            lng={mrk.lng}
            text={mrk.title}
            type={mrk.type}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};
