import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const createPartner = async data => {
  try {
    const res = await axios.post('/partner/add', data);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const useCreatePartner = userId => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createPartner, {
    onSuccess: () => {
      queryClient.invalidateQueries([userId, 'partners']);
    },
  });

  return {
    mutation,
  };
};
