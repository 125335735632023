import React from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Container } from 'reactstrap';

export const Error = ({ message }) => {
  const { goBack } = useHistory();
  return (
    <Container>
      <Col md={8} className='mx-auto'>
        <div
          style={{ height: '85vh' }}
          className=' d-flex justify-content-center align-items-center flex-column '
        >
          <div className='d-none d-lg-flex flex-column align-items-center'>
            <img
              height='100px'
              src='https://bdibucket1.s3.amazonaws.com/Croydon-Logo.png'
              alt=''
            />
            {/* <SvgBox className='text-primary mr-1' size={'25px'}>
              <Logo />
            </SvgBox> */}
            <h4 className={'mb-0 my-5 text-body font-weight-bold'}>
              Croydon Commercial
            </h4>
          </div>
          {/* <img className='shake-top my-2' src={Images.Logo} alt='' /> */}
          <h4 className='text-center font-oswald my-2'>
            {message ??
              `Oops, sorry this page is not available. Either the owner has updated the property or the link is broken.`}
          </h4>
          <div className='d-flex justify-content-center my-4'>
            <Button
              className='btn-3 border-0 rounded-0 font-weight-bold text-uppercase'
              onClick={goBack}
            >
              Go Back
            </Button>
          </div>
        </div>
      </Col>
    </Container>
  );
};
