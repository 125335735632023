import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Navbar from '../components/global-components/navbar';
import Footer from '../components/global-components/footer';
import notifications from '../components/notifications';
import sectiondata from '../data/sections.json';

// icons
import { SvgBox } from '../images/icons/SvgBox';
import { ReactComponent as PhoneIcon } from '../images/icons/phone.svg';
import { ReactComponent as PinIcon } from '../images/icons/pin.svg';
import { ReactComponent as MailIcon } from '../images/icons/mail.svg';

const Contact = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post('/contactus', data)
      .then((res) => {
        setLoading(false);
        notifications.success('Success', 'Message Sent Successfully');
        reset();
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', 'Try Again Later');
      });
  };

  const inlineStyle = {
    backgroundImage: `url(${sectiondata.pageHeaders.pricing})`,
    backgroundColor: '#0000005e',
    backgroundBlendMode: 'overlay',
  };

  return (
    <div className='d-flex flex-column vh-100'>
      <Navbar />
      <>
        {/* Banner Start */}
        <div
          className='banner-area row mx-0 py-5 position-relative flex-fill'
          style={inlineStyle}
        >
          <div className='container'>
            <div className='py-5'>
              <div className='row w-100'>
                <div className='col text-center'>
                  <h2 className='font-weight-bold text-white mb-2'>
                    Contact Us
                  </h2>
                  <p className='text-white mb-0 font-weight-lighter'>
                    Home / Contact us
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* ==== */}
        <div className='container py-5'>
          <div className='section-title text-center py-4'>
            <h2 className='text-capitalize'>
              We're always eager to hear from you!
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          <div className='row justify-content-start justify-content-lg-around'>
            <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
              <div className='single-contact-info row mx-0'>
                <div className='icon-box bg-icon mr-3'>
                  <SvgBox size='20px' className='text-primary'>
                    <PhoneIcon />
                  </SvgBox>
                </div>
                <div className='mb-0 col px-0 mt-2'>
                  <h6 className='mb-2 text-body'>Contact</h6>
                  <p className='my-0 font-weight-light'>
                    Mobile: +44 (0)20 3182 1320
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
              <div className='single-contact-info row mx-0'>
                <div className='icon-box bg-icon mr-3'>
                  <SvgBox size='20px' className='text-primary'>
                    <MailIcon />
                  </SvgBox>
                </div>
                <div className='mb-0 col px-0 mt-2'>
                  <h6 className='mb-2 text-body'>Have a Question?</h6>
                  <p className='my-0 font-weight-light'>Info@moveappy.co.uk</p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 col-xl-4'>
              <div className='single-contact-info row mx-0'>
                <div className='icon-box bg-icon mr-3'>
                  <SvgBox size='20px' className='text-primary'>
                    <PinIcon />
                  </SvgBox>
                </div>
                <div className='mb-0 col px-0 mt-2'>
                  <h6 className='mb-2 text-body'>Address</h6>
                  <p className='my-0 font-weight-light'>
                    12-16 Addiscombe Road <br /> One Croydon SINC <br />
                    CR0 0XT Surrey United Kingdom
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ==== */}
        <div className='contact-area pb-5'>
          <div className='container mb-5'>
            <div className='row'>
              <div className='col-12'>
                <div className='contact-page-map'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.5273933532676!2d-0.09327168465930123!3d51.37498442819778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487600c5b1fee729%3A0x80dc59d45737942!2sNo.%201%20Croydon!5e0!3m2!1sen!2s!4v1621960026187!5m2!1sen!2s'
                    title='tada'
                    className='w-100'
                    loading='lazy'
                    style={{ border: 0 }}
                  />
                </div>
              </div>
              <div className='col-12 py-5'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h4 className='text-center py-5'>Send Us An Email</h4>
                  <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 mx-0'>
                    <div className='rld-single-input mb-2 col'>
                      <input
                        type='text'
                        placeholder='Name'
                        name='name'
                        ref={register({ required: true })}
                      />
                      {errors.name && (
                        <small className='font-weight-bold text-danger'>
                          This field is required
                        </small>
                      )}
                    </div>
                    <div className='rld-single-input mb-2 col'>
                      <input
                        type='email'
                        placeholder='Email Address'
                        name='email'
                        ref={register({ required: true })}
                      />
                      {errors.email && (
                        <small className='font-weight-bold text-danger'>
                          This field is required
                        </small>
                      )}
                    </div>
                    <div className='rld-single-input mb-2 col'>
                      <input
                        type='number'
                        placeholder='Phone'
                        name='phoneNumber'
                        ref={register({ required: true })}
                      />
                      {errors.phoneNumber && (
                        <small className='font-weight-bold text-danger'>
                          This field is required
                        </small>
                      )}
                    </div>
                  </div>
                  <div className='rld-single-input col mt-4'>
                    <textarea
                      rows={10}
                      placeholder='Enter Your Message'
                      defaultValue={''}
                      name='message'
                      ref={register({ required: true })}
                    />
                    {errors.message && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                  </div>
                  <div className='btn-wrap mt-4 pt-2 col-12'>
                    <button
                      type='submit'
                      disabled={loading}
                      className={`btn btn-primary text-uppercase col-12 col-md-6 col-lg-4 col-xl-2 ${
                        loading === true ? 'button-disabled' : null
                      }`}
                    >
                      {loading === true ? (
                        <div
                          class='spinner-border spinner-border-sm text-light mr-2'
                          role='status'
                        >
                          <span class='sr-only'>Loading...</span>
                        </div>
                      ) : null}
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
};

export default Contact;
