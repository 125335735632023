import SearchPropertiesSection from '../../components/properties-components/search/search-properties';
import CroydonNavbar from '../../components/global-components/CroydonNavbar';
import { useAgencyProfile } from '../../hooks/useAgencyProfile';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';

export const CroydonSearch = () => {
  const { id } = useParams();

  const { isLoading: isLoadingProfile, data } = useAgencyProfile({ id });

  if (isLoadingProfile) {
    return <Loader />;
  }

  return (
    <section className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r text-white'>
      <CroydonNavbar data={data} />
      <SearchPropertiesSection isCroydon />
    </section>
  );
};
