import React from 'react';
import Card from '../../properties-components/card';
import { CroydonCard } from '../../../pages/Croydon/components/CroydonCard';
import { FormatPrice } from '../../common/FormatPrice';

export const MarkerComponent = (props) => {
  const {
    property,
    cardModal,
    setCardModal,
    index,
    activeCard,
    setActiveCard,
    isCroydon,
  } = props;

  return (
    <>
      <div
        className={`position-fixed map-overlay-1 ${
          cardModal ? 'd-block' : 'd-none'
        }`}
        onClick={() => setCardModal(false)}
      />
      <div
        className='btn bg-white position-absolute py-1 d-flex justify-content-center align-items-center rounded shadow'
        style={{ width: 'max-content' }}
        onClick={() => {
          setActiveCard(index);
          setCardModal(true);
        }}
      >
        <div className='font-weight-bold font-14'>
          <FormatPrice item={property} />
        </div>

        <div
          className={`position-absolute map-card ${
            cardModal && activeCard === index ? 'd-block' : 'd-none'
          }`}
        >
          {isCroydon ? (
            <div className='col-md-12'>
              <CroydonCard item={property} index={0} isSearch />
            </div>
          ) : (
            <Card className={'col-12'} item={property} index={0} />
          )}
        </div>
      </div>
    </>
  );
};
