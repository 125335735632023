import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';

import { ReactComponent as Logo } from '../../images/icons/logo.svg';
import { ReactComponent as PinIcon } from '../../images/icons/pin.svg';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../images/icons/mail.svg';
import { SvgBox } from '../../images/icons/SvgBox';

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  if (pathname === '/login' || pathname === '/search') {
    return null;
  }

  const contactArr = [
    {
      icon: <PinIcon />,
      text: `12-16 Addiscombe Road  One Croydon SINC
      CR0 0XT Surrey United Kingdom`,
    },
    { icon: <PhoneIcon />, text: '123 456 7890' },
    {
      icon: <EmailIcon />,
      text: `support@moveappy.com`,
    },
  ];

  return (
    <footer
      style={{
        zIndex: -12,
      }}
      className='footer-area py-0 pb-lg-0'
    >
      <div className='container'>
        <div className='footer-top pb-0 position-relative d-none d-md-flex'>
          <div className='row'>
            <div
              style={{
                zIndex: 1,
              }}
              className='position-absolute'
            >
              <SvgBox size='100%' color='#fff2'>
                <Logo />
              </SvgBox>
            </div>
          </div>
        </div>
        <div className='footer-bottom pt-0 pt-md-5'>
          <div className='row d-none d-md-flex'>
            <div className='col-lg-3 col-md-12 col-sm-8 mx-auto mx-lg-0'>
              <div className='widget widget_nav_menu'>
                <h5 className='font-weight-bold text-white text-capitalize mb-5'>
                  Contact Us
                </h5>
                <ul>
                  {contactArr.map((item, i) => (
                    <li className='readeal-top mb-4 text-white' key={i}>
                      <div className='row mx-0'>
                        <div className='col-1 px-0'>
                          <SvgBox size='18px'>{item.icon}</SvgBox>
                        </div>
                        <div className='col'>
                          <h6 className='text-white  font-weight-light mb-0'>
                            {item.text}
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-8 mx-auto mx-lg-0'>
              <div className='widget widget_nav_menu'>
                <h5 className='font-weight-bold text-white text-capitalize mb-4'>
                  {footerdata.popularserces.title}
                </h5>
                <ul>
                  {footerdata.popularserces.links.map((item, i) => (
                    <li className='readeal-top ' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-8 mx-auto mx-lg-0'>
              <div className='widget widget_nav_menu'>
                <h5 className='font-weight-bold text-white text-capitalize mb-4'>
                  {footerdata.Homepress.title}
                </h5>
                <ul>
                  {footerdata.Homepress.links.map((item, i) => (
                    <li className='readeal-top' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-8 mx-auto mx-lg-0'>
              <div className='widget widget_nav_menu'>
                <h5 className='font-weight-bold text-white text-capitalize mb-4'>
                  {footerdata.quicklink.title}
                </h5>
                <ul>
                  {footerdata.quicklink.links.map((item, i) => (
                    <li className='readeal-top' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='row mx-0 py-4 border-dark border-top justify-content-between align-items-center text-center text-md-left'>
          <div
            className='copy-right px-2 pb-0'
            dangerouslySetInnerHTML={{ __html: footerdata.copyrighttext }}
          />
          <div className='d-none d-md-block d-lg-none d-xl-block'>
            <SvgBox className='text-white' size='40px'>
              <Logo />
            </SvgBox>
          </div>
          <div className='order-first order-md-last mb-3 mb-md-0'>
            <ul className='nav justify-content-center'>
              <li className='nav-item px-2'>
                <div className='font-weight-normal text-uppercase font-weight-bold text-white'>
                  Site map
                </div>
              </li>
              <li className='nav-item px-2'>
                <NavLink to='/cookies'>Cookies</NavLink>
              </li>
              <li className='nav-item px-2'>
                <NavLink to='/safety'>Safety & Security</NavLink>
              </li>
              <li className='nav-item px-2'>
                <NavLink to='/terms'>Terms of Use</NavLink>
              </li>
              <li className='nav-item px-2'>
                <NavLink to='/acceptable'>Acceptable Use</NavLink>
              </li>
              <li className='nav-item px-2'>
                <NavLink to='/privacy'>Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
