import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const FeaturedLoader = () => {
  return (
    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
      <div className='single-feature'>
        <div className='thumb'>
          <Skeleton height={150} />
          <div className='details'>
            <p className='feature-logo'>
              <Skeleton circle={true} height={40} width={40} />
            </p>
            <h6 className='price '>
              <Skeleton count={1} />
            </h6>

            <h6 className='text-yellow title readeal-top text-truncate mb-0 mt-1'>
              <Skeleton count={1} />
            </h6>
            <h6 className='font-weight-light mt-1'>
              <Skeleton count={1} />
            </h6>
            <p
              className='text-yellow mb-0  text-capitalize'
              style={{ fontSize: '12px' }}
            >
              <Skeleton count={1} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
