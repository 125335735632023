export const SelectTwoStyle = {
  container: (container) => ({
    ...container,
    borderRadius: 4,
  }),
  control: (control) => ({
    ...control,
    border: '1px solid #009ee3',
    borderRadius: 4,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
};
