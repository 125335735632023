import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import notifications from '../notifications';

export const ConfirmDeleteModal = ({
  setShowReqModal,
  showReqModal,
  itemId,
}) => {
  const queryClient = useQueryClient();

  const handleDeleteAd = async () => {
    try {
      const res = await axios.delete(`service/ads/${itemId}`);
      return res;
    } catch (err) {
      throw err.response.data;
    }
  };

  const mutation = useMutation(handleDeleteAd, {
    onSuccess: () => {
      setShowReqModal(false);
      notifications.success('Success', 'Deleted Successfully');
      queryClient.invalidateQueries('userAds');
    },
    onError: () => {
      notifications.error('Error', 'Try Again Later!');
    },
  });

  return (
    <>
      <Modal
        isOpen={showReqModal}
        toggle={() => setShowReqModal(!showReqModal)}
      >
        <ModalHeader toggle={() => setShowReqModal(!showReqModal)}>
          Response To Request
        </ModalHeader>
        <ModalBody>
          <p className='text-center'>
            Are you sure you want to delete this Ad?
          </p>
        </ModalBody>
        <ModalFooter>
          <button class='btn yellow-btn' onClick={() => setShowReqModal(false)}>
            Cancel
          </button>
          <button
            onClick={() => {
              mutation.mutate({ id: itemId });
            }}
            disabled={mutation.isLoading}
            className={`btn btn-primary ${
              mutation.isLoading === true ? 'button-disabled' : null
            }`}
          >
            {mutation.isLoading === true ? (
              <div
                class='spinner-border spinner-border-sm text-light mr-2'
                role='status'
              >
                <span class='sr-only'>Loading...</span>
              </div>
            ) : null}
            Delete Ad
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
