import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { USER_TYPES } from '../utils/userTypes';

const ProtectedRoutes = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useSelector(state => state.user);

  const history = useHistory();
  const isNotIndex = history.location.pathname !== '/';
  const isIndex = history.location.pathname === '/';
  const myAds = history.location.pathname === '/my-ads';
  const editAds = history.location.pathname.substring(0, 8) === '/edit-ad';
  const postAds = history.location.pathname === '/post-ads';
  const myProp = history.location.pathname === '/my-properties';
  const editProp =
    history.location.pathname.substring(0, 14) === '/edit-property';
  const postProp = history.location.pathname === '/add-property';
  const propReq =
    history.location.pathname.substring(0, 18) === '/property/requests';

  if (isLoading) return 'loading';

  return (
    <>
      {isAuthenticated && isNotIndex && !isLoading ? (
        <>
          {user?.userType === USER_TYPES.partner &&
          (myAds || editAds || postAds) ? (
            <Redirect to='/' />
          ) : user?.userType === USER_TYPES.serviceProvider &&
            (myProp || editProp || postProp || propReq) ? (
            <Redirect to='/' />
          ) : (
            <div>{children}</div>
          )}
        </>
      ) : (
        <>
          {isAuthenticated && isIndex && !isLoading ? (
            <Redirect to='/' />
          ) : (
            <Redirect to='/' />
          )}
        </>
      )}
    </>
  );
};
export default ProtectedRoutes;
