import React from 'react';
import { useForm } from 'react-hook-form';
import { Spinner, Modal, ModalBody } from 'reactstrap';
import { useContactAgency } from '../../hooks/useContactAgency';

export const ContactAgencyModal = ({ agencyDetails, isOpen, toggle }) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const { _id, email, name, username, imageUrl, address } = agencyDetails;

  const { mutation } = useContactAgency();

  const onSubmit = data => {
    data.receiverEmail = email;
    data.receiverUsername = username;
    data.receiverId = _id;

    mutation.mutateAsync(data, {
      onSuccess: () => {
        reset();
        toggle();
      },
    });
  };

  return (
    <div>
      {' '}
      <Modal centered isOpen={isOpen} toggle={toggle}>
        <div className='d-flex align-items-center border-bottom   px-3 mb-4'>
          <div className='mr-3 d-flex'>
            <img
              style={{
                objectFit: 'contain',
                height: 40,
                width: 40,
              }}
              alt=''
              src={
                imageUrl ??
                process.env.PUBLIC_URL + 'assets/img/user-placeholder.svg'
              }
            />
          </div>
          <div className='flex flex-column'>
            <h4 className='mb-0 text-capitalize'>{name}</h4>
            <div className='text-muted'>
              <span>{address}</span>
            </div>
          </div>
          <button onClick={toggle} type='button' className='btn p-0 ml-auto'>
            <h1 className='mb-0 font-weight-light'>&times;</h1>
          </button>
        </div>

        <ModalBody>
          <div className='contact'>
            <form onSubmit={handleSubmit(onSubmit)} className=''>
              <div className='rld-single-input'>
                <input
                  type='text'
                  name='senderName'
                  placeholder='Full Name'
                  ref={register({ required: true })}
                />
                {errors.senderName && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input mt-4'>
                <input
                  type='email'
                  name='senderEmail'
                  placeholder='Email'
                  ref={register({ required: true })}
                />
                {errors.senderEmail && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <div className='rld-single-input my-4'>
                <textarea
                  type='text'
                  rows={6}
                  placeholder='Write Your Message...'
                  name='message'
                  ref={register({ required: true })}
                />
                {errors.message && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </div>
              <button
                type='submit'
                disabled={mutation.isLoading}
                className={`btn btn-primary`}
              >
                {mutation.isLoading && <Spinner size='sm' className='mr-2' />}
                Send
              </button>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
