import React, { useState } from 'react';

// icons
import { ReactComponent as MailIcon } from '../../../images/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../images/icons/phone.svg';
import { Button } from 'reactstrap';
import { SvgBox } from '../../../images/icons/SvgBox';
import { ContactAgencyForm } from './ContactAgencyForm';
import { FormatPrice } from '../../common/FormatPrice';

const PropertyBottomStatus = ({ data }) => {
  const [modalContact, setModalContact] = useState(false);

  const toggleModalContact = () => {
    setModalContact(!modalContact);
  };

  return (
    <div>
      <ContactAgencyForm
        isOpen={modalContact}
        toggle={toggleModalContact}
        details={data}
      />
      <div className='fixed-bottom bg-white py-3 border-top shadow row mx-0 d-flex align-items-center d-lg-none'>
        <div className='col pr-2'>
          <div>
            <div className='mb-0' style={{ fontSize: 20, fontWeight: 900 }}>
              <FormatPrice item={data} />
            </div>
            <div
              className='mb-0 text-black-50'
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              {data?.location}
            </div>
          </div>
        </div>
        <div className='pl-2'>
          <div className='d-flex align-items-center'>
            <Button
              onClick={toggleModalContact}
              color='primary'
              className='icon-box d-flex text-uppercase px-0 rounded-circle mr-3'
              style={{ height: 30, width: 30 }}
            >
              <SvgBox size='15px'>
                <MailIcon />
              </SvgBox>
            </Button>
            <a
              href={`tel:${
                data?.userId ? data?.userId?.phoneNumber : data?.landLordPhone
              }`}
            >
              <Button
                color='outline-primary'
                className='icon-box d-flex text-uppercase px-0 rounded-circle mr-3'
                style={{ height: 30, width: 30 }}
              >
                <SvgBox size='15px'>
                  <PhoneIcon />
                </SvgBox>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyBottomStatus;
