import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { UserAccountDropdown } from './UserAccountDropdown';

const CroydonNavbar = ({ data }) => {
  const [menu, setMenu] = React.useState(false);

  const { isAuthenticated } = useSelector((state) => state.user);

  const links = [
    { name: 'home', link: `/partner/${data?.slug}` },
    { name: 'listings', link: `/partner/${data?.slug}/listings` },
    { name: 'contact', link: `/partner/${data?.slug}/contact` },
  ];

  return (
    <header className='pos-r py-3 bg-white'>
      <div className='container-1'>
        <div className='d-flex flex-wrap'>
          <div>
            <Link to={`/partner/${data?.slug}`}>
              <img
                style={{
                  height: 60,
                }}
                className='object-fit-contain'
                src={'https://headsabovethecloud.co.uk/images/logo.png'}
                alt={data?.name}
              />
            </Link>
          </div>

          <div className='align-self-center d-flex ml-auto d-sm-none'>
            <button
              type='button'
              className='btn btn-menu'
              onClick={() => setMenu(!menu)}
            >
              <span className={`bar ${menu && 'bar-1'}`} />
              <span className={`bar ${menu && 'bar-2'}`} />
              <span className={`bar ${menu && 'bar-3'}`} />
            </button>
            {isAuthenticated && (
              <div className='d-flex justify-content-center align-items-center'>
                <UserAccountDropdown />
              </div>
            )}
          </div>
          <div className='align-self-center ml-auto d-none d-sm-block'>
            <ul className='nav text-uppercase align-items-baseline'>
              {links.map((item) => (
                <li>
                  <NavLink className='nav-link' exact to={item.link}>
                    {item.name}
                  </NavLink>
                </li>
              ))}
              {isAuthenticated ? (
                <li className='mt-4 mt-lg-0 px-0 bg-transparent nav-right-part nav-right-part-desktop'>
                  <UserAccountDropdown />
                </li>
              ) : (
                <li>
                  <NavLink className='nav-link ml-3' exact to='/login'>
                    Log in
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          {/* SideBar */}
          <div
            className={`d-flex d-sm-none ${
              menu ? 'sidebar-show' : 'sidebar-hide'
            }`}
          >
            <ul className='nav flex-column text-uppercase sidebar-show__box'>
              {links.map((item) => (
                <li className='mb-3'>
                  <NavLink className='nav-link' exact to={item.link}>
                    {item.name}
                  </NavLink>
                </li>
              ))}
              {!isAuthenticated ? (
                <li>
                  <NavLink className='nav-link' exact to='/login'>
                    Log in
                  </NavLink>
                </li>
              ) : null}
            </ul>
            <div
              onClick={() => setMenu(false)}
              className={`flex-fill overlay`}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default CroydonNavbar;
