import React from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';

const Pricing = ({ index }) => {
  let pricingPlans = [];
  if (index === 0 || index === 1 || index === 2) {
    let data = sectiondata.pricing.items[index];
    pricingPlans.push(data);
  } else pricingPlans = sectiondata.pricing.items;

  return (
    <div className='user-list-area'>
      <div className='container'>
        {!index && !index?.toString() ? (
          <div className='text-center pt-3 pb-5'>
            <h2 className='title'>Choose a Package</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        ) : null}

        <div className='row justify-content-center'>
          {pricingPlans.map((item, i) => (
            <div
              key={i}
              className={` mb-5 mb-lg-0 ${
                index === 0 || index === 1 || index === 2
                  ? 'col-md-12'
                  : 'col-lg-4 col-sm-6'
              }`}
            >
              <div className='single-pricing bg-white text-center shadow h-100'>
                <div className='pricing-card h-100 d-flex flex-column'>
                  <div className='pricing-card__header'>
                    <h5 className='title'>{item.name}</h5>
                    <h4 className='title-price mb-0'>{item.price}</h4>
                  </div>

                  <ul className='nav flex-column flex-nowrap mt-4'>
                    {item.feature.map((featuresitem, i) => (
                      <li key={i} className={`my-2 text-body`}>
                        {featuresitem}
                      </li>
                    ))}
                  </ul>
                  <div className='pt-4 pb-5 mx-auto mt-auto w-50'>
                    {!index && !index?.toString() ? (
                      <Link
                        className='btn-block d-flex align-items-center justify-content-center custom-btn-primary text-uppercase'
                        to={`/register?type=${item.type}`}
                      >
                        Get Started
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
