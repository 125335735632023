import { useQuery } from 'react-query';
import axios from 'axios';

export const usePropertiesByCity = (limit, skip, city) => {
  const fetchPropertiesByCity = async (limit = 0, cityy) => {
    const caps = cityy.charAt(0).toUpperCase() + cityy.slice(1);
    try {
      const res = await axios.get(
        `property/city/${caps}?skip=${skip}&limit=${limit}`
      );
      return res.data;
    } catch (err) {
      throw err.response;
    }
  };

  const { isLoading, isError, error, data } = useQuery(
    [`properties-by-city-${city}`, limit],
    () => fetchPropertiesByCity(limit, city),
    { keepPreviousData: true }
  );

  return { data, isError, isLoading, error };
};
