import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export const userAuthSlice = createSlice({
  name: 'User',
  initialState: {
    isAuthenticated: false,
    isLoading: true,
    user: null,
    token: '',
  },
  reducers: {
    loginUser: (state, action) => {
      const { token, user } = action.payload;
      state.user = user;
      state.token = token;
      state.isAuthenticated = true;
      state.isLoading = false;
      localStorage.setItem(
        'auth-info',
        JSON.stringify({
          token,
          user,
        })
      );
      setAuthorizationToken(token);
    },
    isLoggedIn: state => {
      const payload = localStorage.getItem('auth-info');

      if (payload !== null) {
        const { token, user } = JSON.parse(payload);
        setAuthorizationToken(token);
        state.user = user;
        state.token = token;
        state.isAuthenticated = true;
        state.isLoading = false;
      } else {
        state.isLoading = false;
      }
    },
    logout: state => {
      localStorage.clear();
      state.isAuthenticated = false;
      delete axios.defaults.headers.common['Authorization'];
      state.user = null;
      state.token = '';
    },
  },
});

export const { loginUser, isLoggedIn, logout } = userAuthSlice.actions;

export const User = state => state.user;

export default userAuthSlice.reducer;
