import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { SvgBox } from '../../images/icons/SvgBox';

class WhyChooseUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';
    let data = sectiondata.whychooseus;

    return (
      <div className='why-choose-us-area bg-gray py-5 d-none d-md-block'>
        <div className='container py-5'>
          <div className='section-title text-center'>
            <h2 className='title text-capitalize'>{data.title}</h2>
            <p>We provide full service at every step</p>
          </div>
          <div className='row row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center'>
            {data.items.map((item, i) => (
              <div key={i} className={'col d-flex flex-column' + item.class}>
                <div className='single-intro bg-white p-4 text-center flex-fill'>
                  <div className='thumb '>
                    <SvgBox size='50px'>
                      <img src={publicUrl + item.icon} alt={imagealt} />
                    </SvgBox>
                  </div>
                  <div className='details'>
                    <h4 className='title'>
                      <span href={item.url}>{item.title}</span>
                    </h4>
                    <p style={{ hyphens: 'none' }}>{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default WhyChooseUs;
