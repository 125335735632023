import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from '../../../images/icons/phone.svg';
import { ConfirmDeleteModal } from '../userPropAndRequests/confirmDeleteModal';
import { numFormatter } from '../../../utils/numFormatter';
import { SvgBox } from '../../../images/icons/SvgBox';

export const AgencyDetails = ({ details }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { register, handleSubmit, errors, reset } = useForm();

  const [modalContact, setModalContact] = useState(false);
  const [showReqModal, setShowReqModal] = useState(false);

  const publicUrl = process.env.PUBLIC_URL + '/';
  const [loading, setLoading] = useState(false);

  const {
    _id,
    slug,
    category,
    title,
    price,
    priceWeek,
    priceType,
    userId,
    location,
    landLordName,
    landLordEmail,
    landLordPhone,
  } = details;

  const toggleModalContact = () => {
    setModalContact(!modalContact);
  };

  const onSubmit = (data) => {
    setLoading(true);
    data.agencyName = userId !== null ? userId.name : landLordName;
    data.agencyEmail = userId !== null ? userId.email : landLordEmail;
    data.propertyTitle = title;
    axios
      .patch(`/property/${_id}/request`, data)
      .then((res) => {
        setLoading(false);

        reset();
        setModalContact(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className='pos-box-fix-2'>
      {showReqModal ? (
        <ConfirmDeleteModal
          showReqModal={showReqModal}
          setShowReqModal={setShowReqModal}
          itemId={_id}
          slug={slug}
        />
      ) : null}
      <div className='p-4 shadow  rounded '>
        <div className='d-flex align-items-center'>
          <div
            className='mr-3 d-flex align-items-center justify-content-center'
            style={{ height: 50, width: 50 }}
          >
            <img
              style={{ objectFit: 'contain' }}
              alt=''
              src={
                userId !== null
                  ? userId.imageUrl
                  : publicUrl + 'assets/img/user-placeholder.svg'
              }
              width='90%'
              height='90%'
            />
          </div>
          <div>
            <h5 className='mb-1 font-weight-bold'>
              {userId?.name ?? landLordName}
            </h5>
            <h6 className='mb-1 font-weight-light text-black-50 small'>
              {userId?.phoneNumber ?? landLordPhone}
            </h6>
            {userId !== null ? (
              <Link to={`/agency/${userId.slug}`}>
                <h5 className='mb-1'>View Listings</h5>
              </Link>
            ) : null}
          </div>
        </div>
        <div className='mt-4 mb-3'>
          <button
            onClick={toggleModalContact}
            type='button'
            className='btn btn-block btn-light font-weight-bold text-uppercase'
          >
            Contact Property
          </button>
        </div>
        <div className='mb-3'>
          <a href={`tel:${userId?.phoneNumber ?? landLordPhone}`}>
            <Button block className='py-3 text-uppercase font-weight-bold'>
              Call Agency
            </Button>
          </a>
        </div>
        {isAuthenticated && user?._id === userId?._id ? (
          <div>
            <Link
              to={`/property/edit/${slug}`}
              className='btn btn-primary btn-block py-3 mb-3 text-uppercase'
            >
              Edit Your Property
            </Link>

            <div>
              <Button
                block
                className='py-3 text-uppercase'
                onClick={() => setShowReqModal(true)}
                color='outline-danger'
              >
                Delete Property
              </Button>
            </div>
          </div>
        ) : null}
        <Modal
          className='m-0 m-sm-auto slide-in-bottom'
          centered
          contentClassName='slide-in-bottom'
          isOpen={modalContact}
          toggle={toggleModalContact}
        >
          <div>
            <div className='d-flex align-items-center border-bottom py-2 px-3 mb-4'>
              <h4 className='mb-0 text-capitalize'>Contact Property</h4>
              <button
                onClick={toggleModalContact}
                type='button'
                className='btn p-0 ml-auto'
              >
                <h1 className='mb-0 font-weight-light'>&times;</h1>
              </button>
            </div>
            {/* Header End */}
            <div className='mt-3 px-3'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='rld-single-input mb-4'>
                  <input
                    type='text'
                    name='name'
                    ref={register({ required: true })}
                    placeholder='Name'
                  />
                  {errors.name && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div className='rld-single-input mb-4'>
                  <input
                    type='number'
                    name='phoneNumber'
                    ref={register({ required: true })}
                    placeholder='Phone'
                  />
                  {errors.phoneNumber && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div className='rld-single-input mb-4'>
                  <input
                    type='email'
                    name='email'
                    ref={register({ required: true })}
                    placeholder='Email'
                  />
                  {errors.email && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div className='rld-single-input mb-3'>
                  <textarea
                    rows='4'
                    name='message'
                    ref={register({ required: true })}
                    placeholder='Message'
                  />
                  {errors.message && (
                    <small className='font-weight-bold text-danger'>
                      This field is required
                    </small>
                  )}
                </div>
                <div className='mb-3'>
                  <Button
                    color='primary'
                    type='submit'
                    block
                    disabled={loading}
                    className='py-3 text-uppercase'
                  >
                    {loading && <Spinner />}
                    CONTACT PROPERTY
                  </Button>
                </div>
              </form>
              <div className='fixed-bottom bg-white py-3 border-top shadow row mx-0 d-flex align-items-center d-lg-none'>
                <div className='col pr-2'>
                  <div>
                    <div
                      className='mb-0'
                      style={{ fontSize: 20, fontWeight: 900 }}
                    >
                      {category?.value === 'to rent' ? (
                        <>
                          <div className='small text-black-50'>
                            £{numFormatter(priceWeek)}/per week
                          </div>
                          <span className=''>
                            £{numFormatter(price)}/per month
                          </span>
                        </>
                      ) : (
                        <>
                          {priceType?.value === 'poa' ? (
                            <>POA</>
                          ) : priceType?.value === 'oieo' ? (
                            <>OIEO £ {price && numFormatter(price)}</>
                          ) : (
                            <>£ {price && numFormatter(price)}</>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className='mb-0 text-black-50'
                      style={{ fontSize: 14, fontWeight: 300 }}
                    >
                      {location}
                    </div>
                  </div>
                </div>
                <div className='pl-2'>
                  <a
                    className='d-flex align-items-center'
                    href={`tel:${
                      userId !== null ? userId?.phoneNumber : landLordPhone
                    }`}
                  >
                    <Button
                      color='outline-primary'
                      className='icon-box d-flex text-uppercase px-0 rounded-circle mr-3'
                      style={{ height: 30, width: 30 }}
                    >
                      <SvgBox size='15px'>
                        <PhoneIcon />
                      </SvgBox>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
