import React from 'react';

export const ClientSideServiceCard = ({ service }) => {
  console.log(service);
  return (
    <a
      rel='noreferrer'
      href={service?.url}
      target='_blank'
      className='rounded-lg shadow-sm d-block w-100 position-relative'
      style={{ height: 190 }}
    >
      <img
        className='h-100 w-100 rounded-lg'
        src={service.imageUrl}
        alt='pic'
      />
      <div
        className='h-100 w-100 pos-input'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className='d-flex flex-column h-100 p-3 text-white'>
          <div style={{ fontSize: 12 }}>
            <span className='pr-2'>
              <span className='badge badge-primary text-uppercase py-1 px-2'>
                Featured
              </span>
            </span>
            <span className='pr-2'>
              <span className='badge badge-dark text-uppercase py-1 px-2'>
                For Sale
              </span>
            </span>
          </div>
          <div className='mt-auto text-capitalize'>
            <div className='font-weight-bolder'>{service.title}</div>
            <div className='small'>{service.label}</div>
            <div className='small text-truncate'>{service.description}</div>
          </div>
        </div>
      </div>
    </a>
    //     :
    //     <Link
    //       className='rounded-lg shadow-sm d-block w-100 position-relative'
    //       style={{ height: 190 }}>
    //       <Card className='flex-row' style={{ height: 200 }}>
    //         <div className='h-100 w-100'>
    //           <CardImg
    //             className='w-100 h-100'
    //             src={service.imageUrl}
    //             alt='Card image cap'
    //           />
    //         </div>
    //         <CardBody className='h-100 scroll-box'>
    //           <CardTitle tag='h5'>
    //             {service.title}
    //           </CardTitle>
    //           <CardSubtitle tag='h6' className='mb-2 text-muted'>
    //             {service.label}
    //           </CardSubtitle>
    //           <CardText className='text-truncate'>
    //             {service.description}
    //           </CardText>
    //         </CardBody>
    //       </Card>
    //     </Link>
    //   }
    // </>
  );
};
