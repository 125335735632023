import React from 'react';
import CroydonNavbar from '../../components/global-components/CroydonNavbar';
import CroydonPropertySmall from './components/CroydonPropertySmall';
import { CroydonDescription } from './croydon-property-page/CroydonDescription';
import { CroydonFeatures } from './croydon-property-page/CroydonFeatures';
import { SimpleMap } from '../../components/map/propDetailMap';
import { NearbyPlaces } from '../../components/properties-components/property-details/NearbyPlaces';
import { CroydonFloorPlanes } from './croydon-property-page/CroydonFloorPlanes';
import { AgencyDetails } from '../../components/properties-components/property-details/AgencyDetails';
import { PropertyThreeSixty } from '../../components/properties-components/property-details/PropertyThreeSixty';
import { PropertyCgi } from '../../components/properties-components/property-details/PropertyCgi';
import { PropertyVideo } from '../../components/properties-components/property-details/PropertyVideo';
import { Error } from '../../utils/errorComponent';

const CroydonProperty = ({ property, isCroydon }) => {
  const { images, coverImage, isActive } = property;

  if (!isActive) {
    return (
      <main className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r'>
        <CroydonNavbar data={property.userId} />
        <Error />
      </main>
    );
  }

  return (
    <main className='container-xxl container-croydon d-flex flex-column min-vh-100 pos-r'>
      <CroydonNavbar data={property.userId} />
      <main className='d-flex flex-column flex-fill  p-r'>
        <div>
          <div className='row mb-3'>
            <div className='col'>
              <div>
                <CroydonPropertySmall
                  Main={true}
                  coverImage={coverImage}
                  images={images}
                  index={images?.length > 1 ? images?.indexOf(coverImage) : 0}
                  property={property}
                />
              </div>
            </div>
            <div className='col-12 col-lg-5 p-0 col-xl-6  mt-4 mt-lg-0 '>
              <div className='position-sticky' style={{ top: 10 }}>
                {images.length > 1 ? (
                  <div className='row mx-0'>
                    {images.slice(0, 4)?.map((item, index) => (
                      <div key={item} className='col-6 mb-4 pb-2'>
                        <CroydonPropertySmall
                          images={images}
                          image={item}
                          index={index}
                          property={property}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='bg-light pb-5 mb-4'>
            <div className='row'>
              <div className='col'>
                <div>
                  <CroydonDescription property={property} />
                </div>
              </div>
              <div className='col-12 col-lg-5 p-0 col-xl-6  mt-4 mt-lg-0 '>
                <div>
                  <CroydonFeatures property={property} />
                  <div>
                    <AgencyDetails
                      details={property}
                      isCroydon={isCroydon}
                      property={property}
                    />
                  </div>
                  {/* <div className='p-3 mt-5'>
                    <div
                      className='mx-3 ml-lg-0 mr-lg-4 bg-grey d-flex align-items-center justify-content-center'
                      style={{ height: 350 }}
                    >
                      <img
                        className='h-100 w-100  shadow'
                        src='/assets/croydon-images/ad.png'
                        alt=''
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {property?.floorImages?.length ? (
            <CroydonFloorPlanes property={property} />
          ) : null}

          <div className='mt-4 mb-4 px-3 px-lg-4'>
            <div className='row'>
              <div className='col'>
                <div>
                  {property.videoUrl ? (
                    <PropertyVideo property={property} />
                  ) : null}
                </div>
              </div>
              <div className='col-12 col-lg-6 mt-4 mt-lg-0'>
                {property.threeSixtyView || property.cgiLink ? (
                  <div>
                    <h5 className='mb-0 title-font font-weight-bold text-uppercase ls-3'>
                      360° VIRTUAL TOUR
                    </h5>
                    <div className='row'>
                      {property?.threeSixtyView ? (
                        <div className='col-12 col-md-6 mt-4'>
                          <PropertyThreeSixty details={property} />
                        </div>
                      ) : null}

                      {property?.cgiLink ? (
                        <div className='col'>
                          <div className='mt-4'>
                            <PropertyCgi details={property} />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='mb-5 bg-black-1 px-3 px-lg-4 py-5'>
            <div className='row pb-5'>
              <div className='col'>
                <div className='h3 title-font ls-3 mb-0 text-uppercase text-croydon mb-4'>
                  LOCATION
                </div>
                <div className='mt-4'>
                  <div>
                    <SimpleMap property={property} isCroydon={isCroydon} />
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 col-xl-6 mt-5 mt-lg-0'>
                <div className='h3 title-font ls-3 mb-0 text-uppercase text-croydon mb-4'>
                  WHAT’S NEARBY?
                </div>
                <div className='mt-4 px-0 col-12 col-md-12'>
                  <NearbyPlaces details={property} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <section className='croydon-footer bg-white py-4'>
        <div className='d-flex flex-wrap'>
          <div
            style={{
              height: 100,
            }}
            className='partners-item  m-0 px-3'
          >
            <img
              className='h-100 w-100 d-block'
              src='https://headsabovethecloud.co.uk/images/logo.png'
              alt='..'
            />
          </div>
        </div>
        <div className='mt-5 text-black text-center'>
          <span>© 2023 Croydon Commercial. All rights reserved.</span>
        </div>
      </section>
    </main>
  );
};

export default CroydonProperty;
